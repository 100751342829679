import { useMount } from 'react-use'
import { getUserState, type getUserStateErr, type UserState } from 'src/api'
import useSWR, { useSWRConfig, type SWRResponse } from 'swr'
import { useAuthenticatedHeaders } from './auth/app'

const key = 'useUserState'
let lastFetched: Date | null = null

export function useUserState(): SWRResponse<UserState, getUserStateErr> {
  const headers = useAuthenticatedHeaders()

  return useSWR(
    `${key}-${headers['Accept-Language']}-${headers['Profile-ID']}`,
    async () => {
      lastFetched = new Date()

      return await getUserState({
        headers,
      })
    },
    {
      suspense: false,
    }
  )
}

function useUserStateMutate(): () => Promise<void> {
  const headers = useAuthenticatedHeaders()
  const { mutate } = useSWRConfig()

  return async function mutateUserState() {
    await mutate(`${key}-${headers['Accept-Language']}-${headers['Profile-ID']}`)
  }
}

export function useUserStateMutateOnMount(): void {
  const mutateUserState = useUserStateMutate()

  useMount(() => {
    if (lastFetched == null) return

    const now = new Date()
    const diff = now.getTime() - lastFetched.getTime()

    // 5 minutes
    if (diff < 1000 * 60 * 5) return

    void mutateUserState()
  })
}

// effector
//   .throttle({
//     source: effector.merge([
//       effector.interval({
//         timeout: 1000 * 60 * 5,
//         start: Auth.AUTHENTICATED,
//         stop: effector.merge([Auth.UNAUTHENTICATED, MaintenanceMode.MAINTENANCE_MODE]),
//       }).tick,
//       UPDATE,
//       Api.postStudentSurveyFinish.doneData,
//       Api.patchMessagesMarkUnread.doneData,
//       Api.patchMessagesMarkRead.doneData,
//       Api.patchNotificationsMarkAllAsRead.doneData,
//       Api.patchNotificationsMarkAsRead.doneData,
//       Api.getNotifications.doneData,
//       Auth.AUTHENTICATED,
//     ]),
//     timeout: 200,
//   })
