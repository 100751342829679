export function EnvelopeIcon({ color = 'gray' }: { color?: string }): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 18 18">
      <g id="mail_icon" transform="translate(-40 -16)">
        <rect
          id="Rectangle_5"
          data-name="Rectangle 5"
          width="18"
          height="18"
          transform="translate(40 16)"
          opacity="0"
        />
        <rect
          id="Rectangle_10"
          data-name="Rectangle 10"
          width="16"
          height="16"
          transform="translate(41 17)"
          opacity="0"
        />
        <g
          id="Rectangle_4311"
          data-name="Rectangle 4311"
          transform="translate(41 18)"
          fill="none"
          stroke={color}
          strokeWidth="1"
          className="stroke-primaryTextColor"
        >
          <rect width="16" height="14" rx="2" stroke="none" />
          <rect x="0.5" y="0.5" width="15" height="13" rx="1.5" fill="none" />
        </g>
        <path
          id="Path_25085"
          data-name="Path 25085"
          d="M56.7,22.76,49.08,27.644,41.369,22.76"
          transform="translate(-0.037 -2.179)"
          fill="none"
          stroke={color}
          strokeWidth="1"
          className="stroke-primaryTextColor"
        />
      </g>
    </svg>
  )
}
