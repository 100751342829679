import * as ProDuotoneSvgIcons from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SubPageLayoutFactory from '../../../../components/SubPageLayoutFactory'

const Layout = SubPageLayoutFactory({
  Icon: (props) => <FontAwesomeIcon {...props} icon={ProDuotoneSvgIcons.faChartPie} />,
  title: ['სტატისტიკა', 'Statistics'],
  pages: [
    {
      title: ['დეშბორდი', 'Dashboard'],
      link: 'administrator/statistics',
    },
    {
      title: ['არჩევები', 'Choices'],
      link: 'administrator/statistics/choices',
    },
  ],
})

export default Layout
