import * as d from 'decoders'
import { storageValue } from 'src/helpers/storage'

export type RefreshTokenLockValue = null | {
  readonly expiresAt: number
}

export const refreshTokenLockStorage = storageValue<RefreshTokenLockValue>(
  'AuthState:refreshTokenLock',
  (value) => JSON.stringify(value),
  (serializedValue) => {
    const decoder = d.nullable(
      d.object({
        expiresAt: d.number,
      })
    )

    return decoder.verify(JSON.parse(serializedValue))
  }
)

export type RefreshTokenLockStorage = typeof refreshTokenLockStorage

let expiresAt: number | null = null
let lockTimer: ReturnType<typeof setInterval> | null = null

const LOCK_EXPIRES_IN = 300

function updateLock(): void {
  expiresAt = Date.now() + LOCK_EXPIRES_IN

  refreshTokenLockStorage.setValue({
    expiresAt,
  })
}

async function lock(): Promise<void> {
  console.debug('lock')
  updateLock()
  let resolved = false

  return await new Promise((resolve) => {
    if (lockTimer != null) clearInterval(lockTimer)

    lockTimer = setInterval(() => {
      const state = refreshTokenLockStorage.getValue()

      if (state?.expiresAt === expiresAt) {
        console.info(`if (state?.expiresAt === expiresAt) { updateLock()`)
        updateLock()

        if (!resolved) {
          resolved = true

          resolve(void null)
        }
      } else {
        console.info(`if (state?.expiresAt === expiresAt) { unlock()`)

        unlock()
      }
    }, LOCK_EXPIRES_IN * 0.33)
  })
}

let ensureLockTimer: ReturnType<typeof setInterval> | null = null

function cancelEnsureLock(): void {
  if (ensureLockTimer != null) {
    clearInterval(ensureLockTimer)
  }
}

export async function ensureLock(): Promise<void> {
  console.info('ensureLock')

  if (!isLocked()) {
    console.info('ensureLock(!isLocked()): await lock()')
    await lock()

    console.info('ensureLock(!isLocked()): return')
    return
  }

  console.info('await new Promise((resolve) => {')

  await new Promise((resolve) => {
    console.info('    ensureLockTimer = setInterval(() => {')

    ensureLockTimer = setInterval(() => {
      console.info('    ensureLockTimer = setInterval(() => {')
      if (!isLocked()) {
        console.info('    ensureLockTimer = setInterval(() => { if (!isLocked()) {')
        cancelEnsureLock()
        resolve(null)
      }
    }, LOCK_EXPIRES_IN * 0.5)
  })

  console.info('ensureLock: await lock()')
  await lock()
  console.info('ensureLock: done')
}

function isLocked(): boolean {
  const state = refreshTokenLockStorage.getValue()

  return state != null && state.expiresAt > Date.now()
}

function iHaveALock(): boolean {
  const state = refreshTokenLockStorage.getValue()

  return lockTimer != null && expiresAt === state?.expiresAt
}

export function unlock(): void {
  console.debug('unlock')

  cancelEnsureLock()

  if (iHaveALock() || !isLocked()) {
    refreshTokenLockStorage.setValue(null)
  }

  if (lockTimer != null) {
    clearInterval(lockTimer)

    lockTimer = null
    expiresAt = null
  }
}
