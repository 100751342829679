import * as Common from 'src/api/common'
import type * as Types from 'src/api/types'
import { refreshAuthObject } from './refreshAuthObject'
import { refreshTokenStorage } from './refreshTokenStorage'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function refreshTokenOnErrorBase<P extends Params, R, F, E extends (params: P) => Promise<R>>(
  paramsFn: (params: P, authObject: Types.AuthObject) => P,
  endpoint: E
): (params: P) => Promise<R> {
  return async (params: P): Promise<R> => {
    const refreshTokenStorageValue1 = refreshTokenStorage.getValue()

    try {
      return await endpoint(params)
    } catch (error: any) {
      if ('type' in error && error.type === 'AuthError') {
        console.info('refreshTokenOnErrorBase', error)
        if (refreshTokenStorageValue1 != null) {
          const authtoken = Common.getAuthorization(refreshTokenStorageValue1.authObject)

          if (params.headers.Authorization === authtoken) {
            console.info('if (params.headers.Authorization === authtoken) {')
            const authObject = await refreshAuthObject(refreshTokenStorageValue1.authObject.refreshToken)

            console.info(
              'const authObject = await refreshAuthObject(refreshTokenStorageValue1.authObject.refreshToken)',
              authObject
            )

            return await endpoint(paramsFn(params, authObject))
          }

          const refreshTokenStorageValue2 = refreshTokenStorage.getValue()
          console.info('refreshTokenStorageValue2', refreshTokenStorageValue2)

          if (refreshTokenStorageValue2 != null) {
            const authtoken2 = Common.getAuthorization(refreshTokenStorageValue2.authObject)

            if (params.headers.Authorization === authtoken2) {
              console.info('if (params.headers.Authorization === authtoken2) {')

              const authObject = await refreshAuthObject(refreshTokenStorageValue2.authObject.refreshToken)

              console.info(
                'const authObject = await refreshAuthObject(refreshTokenStorageValue2.authObject.refreshToken)',
                authObject
              )

              return await endpoint(paramsFn(params, authObject))
            } else {
              console.info('refreshTokenStorageValue2.authObject', refreshTokenStorageValue2.authObject)

              return await endpoint(paramsFn(params, refreshTokenStorageValue2.authObject))
            }
          }
        }
      } else {
        console.error('refreshTokenOnErrorBase', error)
      }

      throw error
    }
  }
}

interface Params {
  readonly headers: {
    readonly Authorization: string
  }
}

export function refreshTokenOnError<P extends Params, R, F, E extends (params: P) => Promise<R>>(
  endpoint: E
): (params: P) => Promise<R> {
  return refreshTokenOnErrorBase<P, R, F, E>((params, authObject) => {
    return {
      ...params,
      headers: {
        ...params.headers,
        Authorization: Common.getAuthorization(authObject),
      },
    }
  }, endpoint)
}
