import classnames from 'classnames'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { useLocale } from 'src/hooks/locale/locale'

interface Props {
  readonly header: string
  readonly image: string
  readonly subHeader?: string
  readonly marginTop?: string
  readonly link?: string
  readonly linkText?: string
  readonly errors?: readonly string[]
}

export default function NoContent(props: Props): React.ReactElement {
  const locale = useLocale()
  return (
    <div className="flex w-full flex-1 items-center justify-center">
      <div
        className={classnames('mb-5 flex w-full flex-1 flex-col items-center', {
          'mt-5': props.marginTop === '5',
        })}
      >
        <img
          src={props.image}
          style={{ borderRadius: '50%' }}
          className="mb-2 bg-card"
          height="80"
          width="80"
          data-testid="noContentImage"
        />
        <p className="mb-2 text-title font-bold text-primaryTextColor" data-testid="noContentHeader">
          {props.header}
        </p>
        {props.subHeader != null && (
          <small
            className="text-center text-caption text-lightPrimaryIconText"
            style={{ maxWidth: '400px' }}
            data-testid="noContentSubHeader"
          >
            {props.subHeader}
          </small>
        )}
        {props.errors?.map((error, index) => (
          <small
            key={index}
            className="text-center text-caption text-lightPrimaryIconText"
            style={{ maxWidth: '400px' }}
            data-testid="noContentError"
          >
            {error}
          </small>
        ))}
        {props.link != null && props.linkText != null && (
          <Link
            className="mt-4 flex h-[48px] cursor-pointer items-center rounded-[4px] bg-primaryBlue px-[20px] text-white hover:opacity-80"
            to={`/${locale}/${props.link}`}
          >
            {props.linkText}
          </Link>
        )}
      </div>
    </div>
  )
}
