import * as FreeSolidSvgIcons from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { FormikContext } from 'formik'
import React, { useContext } from 'react'

interface Props {
  children: React.ReactNode | string | React.ReactNode[]
  variant: string
  onClick?: React.MouseEventHandler | undefined
}

export const Button = React.forwardRef<HTMLButtonElement, JSX.IntrinsicElements['button'] & Props>(function Button(
  { variant, children, ...props },
  ref
) {
  const formik = useContext(FormikContext)

  return (
    <button
      {...props}
      ref={ref}
      className={classNames(
        'h-[48px] cursor-pointer rounded-[4px] bg-seconderyRed px-[20px] text-white hover:opacity-80 xxs:px-[10px]',
        {
          'bg-seconderyRed': variant === 'red',
          '!bg-primaryBlue': variant === 'blue',
          '!bg-primaryYellow !text-black': variant === 'yellow',
          'border border-y-lightPrimaryIconText !bg-primaryGray !text-black': variant === 'outline',
          'cursor-not-allowed opacity-40 hover:!opacity-40': props.disabled,
        },
        props.className
      )}
    >
      {children}
      {formik === undefined ? null : formik.isSubmitting ? (
        <FontAwesomeIcon icon={FreeSolidSvgIcons.faSpinner} spin className="ml-2" />
      ) : null}
    </button>
  )
})
