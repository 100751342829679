import * as ProDuotoneSvgIcons from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SubPageLayoutFactory from 'src/views/components/SubPageLayoutFactory'

const Layout = SubPageLayoutFactory({
  Icon: (props) => <FontAwesomeIcon {...props} icon={ProDuotoneSvgIcons.faUsers} />,
  title: ['მომხმარებლები', 'Users'],
  pages: [
    {
      title: ['მომხმარებლები', 'Users'],
      link: 'administrator/users/all',
    },
    {
      title: ['ადმინისტრატორები', 'Admins'],
      link: 'administrator/users/administrators',
    },
    {
      title: ['ლექტორები', 'Lecturers'],
      link: 'administrator/users/lecturers',
    },
    {
      title: ['სტუდენტები', 'Students'],
      link: 'administrator/users/students',
    },
  ],
})

export default Layout
