export function Checkmark({ color }: { color?: string }): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g id="checkmark_icon" transform="translate(0.094)">
        <rect
          id="Rectangle_161"
          data-name="Rectangle 161"
          width="18"
          height="18"
          transform="translate(-0.094)"
          fill="gray"
          opacity="0"
        />
        <rect
          id="Rectangle_304"
          data-name="Rectangle 304"
          width="16"
          height="16"
          transform="translate(0.906 1)"
          fill="gray"
          opacity="0"
        />
        <path
          id="Path_25013"
          data-name="Path 25013"
          d="M301.537,570.226l6.255,5.729,8.62-11.773"
          transform="translate(-299.589 -561.682)"
          fill="none"
          stroke={color ?? 'gray'}
          strokeWidth="1"
        />
      </g>
    </svg>
  )
}
