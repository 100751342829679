export function Close2Icon(): JSX.Element {
  return (
    <svg
      id="Close_Icon"
      data-name="Close Icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <rect id="Rectangle_1" data-name="Rectangle 1" width="24" height="24" fill="#fff" opacity="0" />
      <rect
        id="Rectangle_3"
        data-name="Rectangle 3"
        width="22"
        height="1"
        transform="translate(4.575 3.868) rotate(45)"
        fill="gray"
      />
      <rect
        id="Rectangle_4"
        data-name="Rectangle 4"
        width="22"
        height="1"
        transform="translate(20.132 4.575) rotate(135)"
        fill="gray"
      />
    </svg>
  )
}
