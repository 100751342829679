// Auto Generated, Don't Modify Manually
import * as decoders from 'decoders'
import * as Sentry from '@sentry/react'
import * as base from './base'
import { ArrayObjectDecoder } from './base'
import { DataDecoder } from './base'
import { ErrorMessage } from './base'
import { baseURL } from './base'
import { identity } from '../helpers/fns'
import { queryToString } from '../helpers/fns'

import { IDString } from './types'
import { LanguageString } from './types'
import { MessageObject } from './types'
import { AuthObject } from './types'
import { ResumeTab } from './types'
import { DownloadMediaFile } from './types'
import { GeneralSettings } from './types'
import { NotificationsData } from './types'
import { NotificationSettings } from './types'
import { ExportFile } from './types'
import { AuthUser } from './types'
import { Semester } from './types'
import { Regime } from './types'
import { MessageUnreadCount } from './types'
import { FinalScore } from './types'
import { Course } from './types'
import { CoursePaperCriteria } from './types'
import { SavePaperCriteria } from './types'
import { Paper } from './types'
import { Group } from './types'
import { GroupSchedule } from './types'
import { Choice } from './types'
import { LibraryBook } from './types'
import { LibraryBooksTaken } from './types'
import { BillingSchedule } from './types'
import { BillingDeposit } from './types'
import { BillingBalance } from './types'
import { Pagination } from './types'
import { AuthLog } from './types'
import { User } from './types'
import { AdministratorUser } from './types'
import { UserState } from './types'
import { Message } from './types'
import { ScheduleDay } from './types'
import { Card } from './types'
import { CalendarItem } from './types'
import { Statement } from './types'
import { StatementTemplate } from './types'
import { StatementTemplateView } from './types'
import { StatementTemplateRequest } from './types'
import { Rating } from './types'
import { CourseSyllabus } from './types'
import { CourseFile } from './types'
import { CoursePost } from './types'
import { NewsUnreadCount } from './types'
import { News } from './types'
import { PortfolioSectionCompetency } from './types'
import { Section } from './types'
import { StudentPortfolioUserWork } from './types'
import { LecturerPortfolioUserCase } from './types'
import { Notification } from './types'
import { Faq } from './types'
import { Program } from './types'
import { Faculty } from './types'
import { StudentProgramState } from './types'
import { LecturerUser } from './types'
import { Recommendation } from './types'
import { RecommendationLecturerSide } from './types'
import { RecommendationStudentInfo } from './types'
import { CoursePostComment } from './types'
import { Resume } from './types'
import { ResumePersonal } from './types'
import { ResumeWork } from './types'
import { ResumeTraining } from './types'
import { ResumeSkill } from './types'
import { ResumeEducation } from './types'
import { ResumeAdditional } from './types'
import { University } from './types'
import { ResumeLanguage } from './types'
import { ResumePublicationType } from './types'
import { ResumeGrantType } from './types'
import { ResumeCity } from './types'
import { RegistrationStatus } from './types'
import { MasterRegistrationStep1 } from './types'
import { MasterRegistrationStep2 } from './types'
import { RegistrationStep3 } from './types'
import { LecturerRegistrationStep1 } from './types'
import { LecturerRegistrationStep2 } from './types'
import { ExamCourse } from './types'
import { UpdateResumeWorkRequest } from './types'
import { UpdateResumeTrainingRequest } from './types'
import { UpdateResumeSkillLanguageRequest } from './types'
import { UpdateResumeSkillCommunicationRequest } from './types'
import { UpdateResumeSkillComputerRequest } from './types'
import { UpdateResumeSkillOrganisationalRequest } from './types'
import { UpdateResumeSkillOtherRequest } from './types'
import { UpdateResumeEducationRequest } from './types'
import { UpdateResumeAdditionalPublicationRequest } from './types'
import { UpdateResumeAdditionalFellowshipRequest } from './types'
import { UpdateResumeAdditionalSubjectRequest } from './types'
import { UpdateResumeAdditionalVideoLectureRequest } from './types'
import { AvailableChoiceSummary } from './types'
import { AvailableCourseTab } from './types'
import { AvailableCourseSummary } from './types'
import { MetaData } from './types'
import { Country } from './types'
import { Address } from './types'
import { FeedbackSubject } from './types'
import { SurveyProgress } from './types'
import { Survey } from './types'
import { SurveyQuestion } from './types'
import { QuestionAnswerRequest } from './types'
import { Feedback } from './types'
import { Registration } from './types'

import { MessageObjectDecoder } from './types'
import { AuthObjectDecoder } from './types'
import { DownloadMediaFileDecoder } from './types'
import { GeneralSettingsDecoder } from './types'
import { NotificationSettingsDecoder } from './types'
import { ExportFileDecoder } from './types'
import { AuthUserDecoder } from './types'
import { SemesterDecoder } from './types'
import { RegimeDecoder } from './types'
import { MessageUnreadCountDecoder } from './types'
import { FinalScoreDecoder } from './types'
import { CourseDecoder } from './types'
import { CoursePaperCriteriaDecoder } from './types'
import { PaperDecoder } from './types'
import { GroupDecoder } from './types'
import { GroupScheduleDecoder } from './types'
import { ChoiceDecoder } from './types'
import { LibraryBookDecoder } from './types'
import { LibraryBooksTakenDecoder } from './types'
import { BillingScheduleDecoder } from './types'
import { BillingDepositDecoder } from './types'
import { BillingBalanceDecoder } from './types'
import { PaginationDecoder } from './types'
import { AuthLogDecoder } from './types'
import { UserDecoder } from './types'
import { AdministratorUserDecoder } from './types'
import { UserStateDecoder } from './types'
import { MessageDecoder } from './types'
import { ScheduleDayDecoder } from './types'
import { CardDecoder } from './types'
import { CalendarItemDecoder } from './types'
import { StatementDecoder } from './types'
import { StatementTemplateDecoder } from './types'
import { StatementTemplateViewDecoder } from './types'
import { RatingDecoder } from './types'
import { CourseSyllabusDecoder } from './types'
import { CourseFileDecoder } from './types'
import { CoursePostDecoder } from './types'
import { NewsUnreadCountDecoder } from './types'
import { NewsDecoder } from './types'
import { PortfolioSectionCompetencyDecoder } from './types'
import { SectionDecoder } from './types'
import { StudentPortfolioUserWorkDecoder } from './types'
import { LecturerPortfolioUserCaseDecoder } from './types'
import { NotificationDecoder } from './types'
import { FaqDecoder } from './types'
import { ProgramDecoder } from './types'
import { FacultyDecoder } from './types'
import { StudentProgramStateDecoder } from './types'
import { LecturerUserDecoder } from './types'
import { RecommendationDecoder } from './types'
import { RecommendationLecturerSideDecoder } from './types'
import { RecommendationStudentInfoDecoder } from './types'
import { CoursePostCommentDecoder } from './types'
import { ResumeDecoder } from './types'
import { ResumePersonalDecoder } from './types'
import { ResumeWorkDecoder } from './types'
import { ResumeTrainingDecoder } from './types'
import { ResumeSkillDecoder } from './types'
import { ResumeEducationDecoder } from './types'
import { ResumeAdditionalDecoder } from './types'
import { UniversityDecoder } from './types'
import { ResumeLanguageDecoder } from './types'
import { ResumePublicationTypeDecoder } from './types'
import { ResumeGrantTypeDecoder } from './types'
import { ResumeCityDecoder } from './types'
import { RegistrationStatusDecoder } from './types'
import { ExamCourseDecoder } from './types'
import { AvailableChoiceSummaryDecoder } from './types'
import { AvailableCourseTabDecoder } from './types'
import { AvailableCourseSummaryDecoder } from './types'
import { MetaDataDecoder } from './types'
import { CountryDecoder } from './types'
import { AddressDecoder } from './types'
import { FeedbackSubjectDecoder } from './types'
import { SurveyProgressDecoder } from './types'
import { SurveyDecoder } from './types'
import { SurveyQuestionDecoder } from './types'
import { FeedbackDecoder } from './types'
import { RegistrationDecoder } from './types'

export interface loginParams {
  readonly "body": {
    readonly "clientId": "1"
    readonly "grantType": "internal"
    readonly "login": string
    readonly "password": string
  }
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
}

export type loginOk = AuthObject

export type loginErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function login (params: loginParams): Promise<loginOk> {
  const url = "/auth/token"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "clientId": params.body["clientId"],
      "grantType": params.body["grantType"],
      "login": params.body["login"],
      "password": params.body["password"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX AuthObject
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(AuthObjectDecoder).decode(data)
    if (result.ok) {
      return identity<AuthObject>(result.value)
    } else {
      console.error(
        'POST /auth/token 2XX AuthObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /auth/token 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /auth/token 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /auth/token 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /auth/token 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("login"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /auth/token XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface loginWithGoogleIdTokenParams {
  readonly "body": {
    readonly "clientId": "1"
    readonly "grantType": "google_id_token"
    readonly "token": string
  }
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
}

export type loginWithGoogleIdTokenOk = AuthObject

export type loginWithGoogleIdTokenErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function loginWithGoogleIdToken (params: loginWithGoogleIdTokenParams): Promise<loginWithGoogleIdTokenOk> {
  const url = "/auth/token"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "clientId": params.body["clientId"],
      "grantType": params.body["grantType"],
      "token": params.body["token"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX AuthObject
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(AuthObjectDecoder).decode(data)
    if (result.ok) {
      return identity<AuthObject>(result.value)
    } else {
      console.error(
        'POST /auth/token 2XX AuthObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /auth/token 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /auth/token 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /auth/token 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /auth/token 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("loginWithGoogleIdToken"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /auth/token XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface loginWithGoogleAccessTokenParams {
  readonly "body": {
    readonly "clientId": "1"
    readonly "grantType": "google_access_token"
    readonly "token": string
  }
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
}

export type loginWithGoogleAccessTokenOk = AuthObject

export type loginWithGoogleAccessTokenErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function loginWithGoogleAccessToken (params: loginWithGoogleAccessTokenParams): Promise<loginWithGoogleAccessTokenOk> {
  const url = "/auth/token"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "clientId": params.body["clientId"],
      "grantType": params.body["grantType"],
      "token": params.body["token"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX AuthObject
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(AuthObjectDecoder).decode(data)
    if (result.ok) {
      return identity<AuthObject>(result.value)
    } else {
      console.error(
        'POST /auth/token 2XX AuthObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /auth/token 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /auth/token 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /auth/token 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /auth/token 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("loginWithGoogleAccessToken"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /auth/token XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface deleteAuthTokenParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Accept-Language": LanguageString
  }
}

export type deleteAuthTokenOk = null

export type deleteAuthTokenErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function deleteAuthToken (params: deleteAuthTokenParams): Promise<deleteAuthTokenOk> {
  const url = "/auth/token"
  const response = await fetch(baseURL + url, {
    method: "DELETE",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'DELETE /auth/token 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'DELETE /auth/token 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'DELETE /auth/token 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'DELETE /auth/token 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'DELETE /auth/token 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("deleteAuthToken"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'DELETE /auth/token XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postAuthTokenRefreshParams {
  readonly "body": {
    readonly "clientId": "1"
    readonly "grantType": "internal_refresh_token"
    readonly "refreshToken": string
  }
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
}

export type postAuthTokenRefreshOk = AuthObject

export type postAuthTokenRefreshErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postAuthTokenRefresh (params: postAuthTokenRefreshParams): Promise<postAuthTokenRefreshOk> {
  const url = "/auth/token/refresh"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "clientId": params.body["clientId"],
      "grantType": params.body["grantType"],
      "refreshToken": params.body["refreshToken"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX AuthObject
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(AuthObjectDecoder).decode(data)
    if (result.ok) {
      return identity<AuthObject>(result.value)
    } else {
      console.error(
        'POST /auth/token/refresh 2XX AuthObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /auth/token/refresh 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /auth/token/refresh 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /auth/token/refresh 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /auth/token/refresh 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postAuthTokenRefresh"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /auth/token/refresh XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getAuthUserParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Accept-Language": LanguageString
  }
}

export type getAuthUserOk = AuthUser

export type getAuthUserErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getAuthUser (params: getAuthUserParams): Promise<getAuthUserOk> {
  const url = "/auth/user"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX AuthUser
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(AuthUserDecoder).decode(data)
    if (result.ok) {
      return identity<AuthUser>(result.value)
    } else {
      console.error(
        'GET /auth/user 2XX AuthUser\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /auth/user 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /auth/user 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /auth/user 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /auth/user 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getAuthUser"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /auth/user XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getSemestersActiveParams {
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
}

export type getSemestersActiveOk = Semester

export type getSemestersActiveErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getSemestersActive (params: getSemestersActiveParams): Promise<getSemestersActiveOk> {
  const url = "/semesters/active"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Semester
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(SemesterDecoder).decode(data)
    if (result.ok) {
      return identity<Semester>(result.value)
    } else {
      console.error(
        'GET /semesters/active 2XX Semester\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /semesters/active 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /semesters/active 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /semesters/active 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /semesters/active 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getSemestersActive"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /semesters/active XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postPushSubscriptionsParams {
  readonly "body": {
    readonly "token": string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type postPushSubscriptionsOk = null

export type postPushSubscriptionsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postPushSubscriptions (params: postPushSubscriptionsParams): Promise<postPushSubscriptionsOk> {
  const url = "/push-subscriptions"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "token": params.body["token"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /push-subscriptions 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /push-subscriptions 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /push-subscriptions 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /push-subscriptions 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /push-subscriptions 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postPushSubscriptions"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /push-subscriptions XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface deletePushSubscriptionsParams {
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "token": string
  }
}

export type deletePushSubscriptionsOk = null

export type deletePushSubscriptionsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function deletePushSubscriptions (params: deletePushSubscriptionsParams): Promise<deletePushSubscriptionsOk> {
  let url = "/push-subscriptions/"
  url += params.args["token"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "DELETE",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'DELETE /push-subscriptions/ 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'DELETE /push-subscriptions/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'DELETE /push-subscriptions/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'DELETE /push-subscriptions/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'DELETE /push-subscriptions/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("deletePushSubscriptions"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'DELETE /push-subscriptions/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postPaperCriteriasParams {
  readonly "body": {
    readonly "intermediateScoreLimit": number
    readonly "groups": readonly string[]
    readonly "criterias": readonly SavePaperCriteria[]
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "courseId": string
  }
}

export type postPaperCriteriasOk = MessageObject

export type postPaperCriteriasErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postPaperCriterias (params: postPaperCriteriasParams): Promise<postPaperCriteriasOk> {
  let url = "/lecturer/courses/"
  url += params.args["courseId"]
  url += "/teaching-groups/papers"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "intermediateScoreLimit": params.body["intermediateScoreLimit"],
      "groups": params.body["groups"],
      "criterias": params.body["criterias"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX MessageObject
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(MessageObjectDecoder).decode(data)
    if (result.ok) {
      return identity<MessageObject>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 2XX MessageObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postPaperCriterias"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerGroupsExportParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "courseId": string
  }
}

export type getLecturerGroupsExportOk = ExportFile

export type getLecturerGroupsExportErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerGroupsExport (params: getLecturerGroupsExportParams): Promise<getLecturerGroupsExportOk> {
  let url = "/lecturer/courses/"
  url += params.args["courseId"]
  url += "/teaching-groups/export"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX ExportFile
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ExportFileDecoder).decode(data)
    if (result.ok) {
      return identity<ExportFile>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 2XX ExportFile\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerGroupsExport"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface updatePaperCriteriasParams {
  readonly "body": {
    readonly "intermediateScoreLimit": number
    readonly "criterias": ReadonlyArray<{
      readonly "criteriaId": string
      readonly "min": number
      readonly "max": number
    }>
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "courseId": string
    readonly "groupId": string
    readonly "paperId": string
  }
}

export type updatePaperCriteriasOk = null

export type updatePaperCriteriasErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function updatePaperCriterias (params: updatePaperCriteriasParams): Promise<updatePaperCriteriasOk> {
  let url = "/lecturer/courses/"
  url += params.args["courseId"]
  url += "/teaching-groups/"
  url += params.args["groupId"]
  url += "/papers/"
  url += params.args["paperId"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "PATCH",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "intermediateScoreLimit": params.body["intermediateScoreLimit"],
      "criterias": params.body["criterias"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("updatePaperCriterias"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface LecturerEvaluatePortfolioCaseParams {
  readonly "body": {
    readonly "feedback": string
    readonly "scores": ReadonlyArray<{
      readonly "competencyId": number
      readonly "criteriaId": number
      readonly "score": number
    }>
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "sectionId": string
    readonly "workId": string
  }
}

export type LecturerEvaluatePortfolioCaseOk = null

export type LecturerEvaluatePortfolioCaseErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function LecturerEvaluatePortfolioCase (params: LecturerEvaluatePortfolioCaseParams): Promise<LecturerEvaluatePortfolioCaseOk> {
  let url = "/lecturer/portfolio/sections/"
  url += params.args["sectionId"]
  url += "/works/"
  url += params.args["workId"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "PATCH",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "feedback": params.body["feedback"],
      "scores": params.body["scores"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/portfolio/sections/ 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/portfolio/sections/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/portfolio/sections/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/portfolio/sections/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/portfolio/sections/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("LecturerEvaluatePortfolioCase"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/portfolio/sections/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getPaperCriteriasParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getPaperCriteriasOk = readonly CoursePaperCriteria[]

export type getPaperCriteriasErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getPaperCriterias (params: getPaperCriteriasParams): Promise<getPaperCriteriasOk> {
  const url = "/lecturer/paper-criterias"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly CoursePaperCriteria[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(CoursePaperCriteriaDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly CoursePaperCriteria[]>(result.value)
    } else {
      console.error(
        'GET /lecturer/paper-criterias 2XX readonly CoursePaperCriteria[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/paper-criterias 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/paper-criterias 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/paper-criterias 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/paper-criterias 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getPaperCriterias"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/paper-criterias XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerTeachingGroupsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getLecturerTeachingGroupsOk = readonly Group[]

export type getLecturerTeachingGroupsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerTeachingGroups (params: getLecturerTeachingGroupsParams): Promise<getLecturerTeachingGroupsOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/teaching-groups"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Group[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(GroupDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Group[]>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 2XX readonly Group[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerTeachingGroups"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerGroupsPaperParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "id": string
    readonly "groupId": string
  }
}

export type getLecturerGroupsPaperOk = Paper

export type getLecturerGroupsPaperErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerGroupsPaper (params: getLecturerGroupsPaperParams): Promise<getLecturerGroupsPaperOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/teaching-groups/"
  url += params.args["groupId"]
  url += "/paper"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Paper
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(PaperDecoder).decode(data)
    if (result.ok) {
      return identity<Paper>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 2XX Paper\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerGroupsPaper"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerStudentsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "filters": {
      readonly "search": null | string
    }
    readonly "semId": string
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getLecturerStudentsOk = readonly LecturerUser[]

export type getLecturerStudentsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerStudents (params: getLecturerStudentsParams): Promise<getLecturerStudentsOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/teaching-groups/students"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly LecturerUser[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(LecturerUserDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly LecturerUser[]>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 2XX readonly LecturerUser[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerStudents"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentsScoreParams {
  readonly "body": {
    readonly "criteriaId": string
    readonly "score": number
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "courseId": string
    readonly "groupId": string
    readonly "profileId": string
  }
}

export type postStudentsScoreOk = FinalScore

export type postStudentsScoreErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentsScore (params: postStudentsScoreParams): Promise<postStudentsScoreOk> {
  let url = "/lecturer/courses/"
  url += params.args["courseId"]
  url += "/teaching-groups/"
  url += params.args["groupId"]
  url += "/students/"
  url += params.args["profileId"]
  url += "/scores"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "criteriaId": params.body["criteriaId"],
      "score": params.body["score"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX FinalScore
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(FinalScoreDecoder).decode(data)
    if (result.ok) {
      return identity<FinalScore>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 2XX FinalScore\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentsScore"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerTeachingGroupParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "courseId": string
    readonly "groupId": string
  }
}

export type getLecturerTeachingGroupOk = Group

export type getLecturerTeachingGroupErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerTeachingGroup (params: getLecturerTeachingGroupParams): Promise<getLecturerTeachingGroupOk> {
  let url = "/lecturer/courses/"
  url += params.args["courseId"]
  url += "/teaching-groups/"
  url += params.args["groupId"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Group
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(GroupDecoder).decode(data)
    if (result.ok) {
      return identity<Group>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 2XX Group\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerTeachingGroup"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerTeachesSemestersParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getLecturerTeachesSemestersOk = readonly Semester[]

export type getLecturerTeachesSemestersErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerTeachesSemesters (params: getLecturerTeachesSemestersParams): Promise<getLecturerTeachesSemestersOk> {
  const url = "/lecturer/courses/teaches/semesters"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Semester[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(SemesterDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Semester[]>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/teaches/semesters 2XX readonly Semester[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/teaches/semesters 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/teaches/semesters 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/teaches/semesters 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/teaches/semesters 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerTeachesSemesters"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/teaches/semesters XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerCourseTeachesSemestersParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getLecturerCourseTeachesSemestersOk = readonly Semester[]

export type getLecturerCourseTeachesSemestersErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerCourseTeachesSemesters (params: getLecturerCourseTeachesSemestersParams): Promise<getLecturerCourseTeachesSemestersOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/teaches/semesters"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Semester[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(SemesterDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Semester[]>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 2XX readonly Semester[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerCourseTeachesSemesters"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerManageSemestersParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getLecturerManageSemestersOk = readonly Semester[]

export type getLecturerManageSemestersErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerManageSemesters (params: getLecturerManageSemestersParams): Promise<getLecturerManageSemestersOk> {
  const url = "/lecturer/courses/manage/semesters"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Semester[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(SemesterDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Semester[]>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/manage/semesters 2XX readonly Semester[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/manage/semesters 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/manage/semesters 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/manage/semesters 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/manage/semesters 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerManageSemesters"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/manage/semesters XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerCourseParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getLecturerCourseOk = Course

export type getLecturerCourseErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerCourse (params: getLecturerCourseParams): Promise<getLecturerCourseOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += ""
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Course
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(CourseDecoder).decode(data)
    if (result.ok) {
      return identity<Course>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 2XX Course\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerCourse"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerCourseSyllabusParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getLecturerCourseSyllabusOk = CourseSyllabus

export type getLecturerCourseSyllabusErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerCourseSyllabus (params: getLecturerCourseSyllabusParams): Promise<getLecturerCourseSyllabusOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/syllabus"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX CourseSyllabus
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(CourseSyllabusDecoder).decode(data)
    if (result.ok) {
      return identity<CourseSyllabus>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 2XX CourseSyllabus\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerCourseSyllabus"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface patchLecturerCourseSyllabusParams {
  readonly "body": {
    readonly "duration": string
    readonly "lecturers": string
    readonly "hours": string
    readonly "prerequisites": string
    readonly "methods": string
    readonly "mission": string
    readonly "topics": string
    readonly "outcomes": string
    readonly "evaluation": string
    readonly "resources": string
    readonly "otherResources": string
    readonly "schedule": string
    readonly "knowledge": boolean
    readonly "applying": boolean
    readonly "judgments": boolean
    readonly "communications": boolean
    readonly "learning": boolean
    readonly "values": boolean
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type patchLecturerCourseSyllabusOk = null

export type patchLecturerCourseSyllabusErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function patchLecturerCourseSyllabus (params: patchLecturerCourseSyllabusParams): Promise<patchLecturerCourseSyllabusOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/syllabus"
  const response = await fetch(baseURL + url, {
    method: "PATCH",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "duration": params.body["duration"],
      "lecturers": params.body["lecturers"],
      "hours": params.body["hours"],
      "prerequisites": params.body["prerequisites"],
      "methods": params.body["methods"],
      "mission": params.body["mission"],
      "topics": params.body["topics"],
      "outcomes": params.body["outcomes"],
      "evaluation": params.body["evaluation"],
      "resources": params.body["resources"],
      "otherResources": params.body["otherResources"],
      "schedule": params.body["schedule"],
      "knowledge": params.body["knowledge"],
      "applying": params.body["applying"],
      "judgments": params.body["judgments"],
      "communications": params.body["communications"],
      "learning": params.body["learning"],
      "values": params.body["values"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("patchLecturerCourseSyllabus"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getRegimesActiveParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getRegimesActiveOk = Regime

export type getRegimesActiveErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getRegimesActive (params: getRegimesActiveParams): Promise<getRegimesActiveOk> {
  const url = "/regimes/active"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Regime
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(RegimeDecoder).decode(data)
    if (result.ok) {
      return identity<Regime>(result.value)
    } else {
      console.error(
        'GET /regimes/active 2XX Regime\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /regimes/active 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /regimes/active 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /regimes/active 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /regimes/active 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getRegimesActive"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /regimes/active XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentChoicesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentChoicesOk = readonly Choice[]

export type getStudentChoicesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentChoices (params: getStudentChoicesParams): Promise<getStudentChoicesOk> {
  const url = "/student/choices"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Choice[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(ChoiceDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Choice[]>(result.value)
    } else {
      console.error(
        'GET /student/choices 2XX readonly Choice[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/choices 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/choices 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/choices 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/choices 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentChoices"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/choices XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentChoicesProgramsAssignParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "uid": string
    readonly "program_id": string
  }
}

export type postStudentChoicesProgramsAssignOk = MessageObject

export type postStudentChoicesProgramsAssignErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentChoicesProgramsAssign (params: postStudentChoicesProgramsAssignParams): Promise<postStudentChoicesProgramsAssignOk> {
  let url = "/student/choices/"
  url += params.args["uid"]
  url += "/programs/"
  url += params.args["program_id"]
  url += "/assign"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX MessageObject
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(MessageObjectDecoder).decode(data)
    if (result.ok) {
      return identity<MessageObject>(result.value)
    } else {
      console.error(
        'POST /student/choices/ 2XX MessageObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/choices/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/choices/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/choices/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/choices/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentChoicesProgramsAssign"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/choices/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentChoicesFreeCreditsAssignParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "uid": string
  }
}

export type postStudentChoicesFreeCreditsAssignOk = MessageObject

export type postStudentChoicesFreeCreditsAssignErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentChoicesFreeCreditsAssign (params: postStudentChoicesFreeCreditsAssignParams): Promise<postStudentChoicesFreeCreditsAssignOk> {
  let url = "/student/choices/"
  url += params.args["uid"]
  url += "/free-credits/assign"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX MessageObject
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(MessageObjectDecoder).decode(data)
    if (result.ok) {
      return identity<MessageObject>(result.value)
    } else {
      console.error(
        'POST /student/choices/ 2XX MessageObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/choices/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/choices/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/choices/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/choices/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentChoicesFreeCreditsAssign"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/choices/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentChoicesRemoveAssignmentParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "uid": string
  }
}

export type postStudentChoicesRemoveAssignmentOk = MessageObject

export type postStudentChoicesRemoveAssignmentErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentChoicesRemoveAssignment (params: postStudentChoicesRemoveAssignmentParams): Promise<postStudentChoicesRemoveAssignmentOk> {
  let url = "/student/choices/"
  url += params.args["uid"]
  url += "/remove-assignment"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX MessageObject
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(MessageObjectDecoder).decode(data)
    if (result.ok) {
      return identity<MessageObject>(result.value)
    } else {
      console.error(
        'POST /student/choices/ 2XX MessageObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/choices/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/choices/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/choices/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/choices/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentChoicesRemoveAssignment"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/choices/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentChoicesParams {
  readonly "body": {
    readonly "courseId": IDString
    readonly "groupId": IDString
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type postStudentChoicesOk = MessageObject

export type postStudentChoicesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentChoices (params: postStudentChoicesParams): Promise<postStudentChoicesOk> {
  const url = "/student/choices"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "courseId": params.body["courseId"],
      "groupId": params.body["groupId"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX MessageObject
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(MessageObjectDecoder).decode(data)
    if (result.ok) {
      return identity<MessageObject>(result.value)
    } else {
      console.error(
        'POST /student/choices 2XX MessageObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/choices 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/choices 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/choices 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/choices 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentChoices"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/choices XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface deleteStudentChoicesParams {
  readonly "body": {
    readonly "courseId": IDString
    readonly "groupId": IDString
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type deleteStudentChoicesOk = MessageObject

export type deleteStudentChoicesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function deleteStudentChoices (params: deleteStudentChoicesParams): Promise<deleteStudentChoicesOk> {
  const url = "/student/choices"
  const response = await fetch(baseURL + url, {
    method: "DELETE",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "courseId": params.body["courseId"],
      "groupId": params.body["groupId"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX MessageObject
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(MessageObjectDecoder).decode(data)
    if (result.ok) {
      return identity<MessageObject>(result.value)
    } else {
      console.error(
        'DELETE /student/choices 2XX MessageObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'DELETE /student/choices 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'DELETE /student/choices 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'DELETE /student/choices 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'DELETE /student/choices 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("deleteStudentChoices"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'DELETE /student/choices XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentChoicesRechooseParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentChoicesRechooseOk = Choice

export type getStudentChoicesRechooseErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentChoicesRechoose (params: getStudentChoicesRechooseParams): Promise<getStudentChoicesRechooseOk> {
  const url = "/student/choices/rechoose"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Choice
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ChoiceDecoder).decode(data)
    if (result.ok) {
      return identity<Choice>(result.value)
    } else {
      console.error(
        'GET /student/choices/rechoose 2XX Choice\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/choices/rechoose 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/choices/rechoose 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/choices/rechoose 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/choices/rechoose 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentChoicesRechoose"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/choices/rechoose XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentChoicesRechooseParams {
  readonly "body": {
    readonly "courseId": IDString
    readonly "groupId": IDString
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type postStudentChoicesRechooseOk = MessageObject

export type postStudentChoicesRechooseErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentChoicesRechoose (params: postStudentChoicesRechooseParams): Promise<postStudentChoicesRechooseOk> {
  const url = "/student/choices/rechoose"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "courseId": params.body["courseId"],
      "groupId": params.body["groupId"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX MessageObject
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(MessageObjectDecoder).decode(data)
    if (result.ok) {
      return identity<MessageObject>(result.value)
    } else {
      console.error(
        'POST /student/choices/rechoose 2XX MessageObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/choices/rechoose 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/choices/rechoose 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/choices/rechoose 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/choices/rechoose 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentChoicesRechoose"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/choices/rechoose XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface deleteStudentChoicesRechooseParams {
  readonly "body": {
    readonly "courseId": IDString
    readonly "groupId": IDString
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type deleteStudentChoicesRechooseOk = MessageObject

export type deleteStudentChoicesRechooseErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function deleteStudentChoicesRechoose (params: deleteStudentChoicesRechooseParams): Promise<deleteStudentChoicesRechooseOk> {
  const url = "/student/choices/rechoose"
  const response = await fetch(baseURL + url, {
    method: "DELETE",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "courseId": params.body["courseId"],
      "groupId": params.body["groupId"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX MessageObject
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(MessageObjectDecoder).decode(data)
    if (result.ok) {
      return identity<MessageObject>(result.value)
    } else {
      console.error(
        'DELETE /student/choices/rechoose 2XX MessageObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'DELETE /student/choices/rechoose 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'DELETE /student/choices/rechoose 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'DELETE /student/choices/rechoose 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'DELETE /student/choices/rechoose 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("deleteStudentChoicesRechoose"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'DELETE /student/choices/rechoose XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentSchedulesCurrentParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentSchedulesCurrentOk = readonly ScheduleDay[]

export type getStudentSchedulesCurrentErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentSchedulesCurrent (params: getStudentSchedulesCurrentParams): Promise<getStudentSchedulesCurrentOk> {
  const url = "/student/schedules/current"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly ScheduleDay[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(ScheduleDayDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly ScheduleDay[]>(result.value)
    } else {
      console.error(
        'GET /student/schedules/current 2XX readonly ScheduleDay[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/schedules/current 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/schedules/current 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/schedules/current 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/schedules/current 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentSchedulesCurrent"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/schedules/current XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentCardParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentCardOk = Card

export type getStudentCardErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentCard (params: getStudentCardParams): Promise<getStudentCardOk> {
  const url = "/student/card"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Card
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(CardDecoder).decode(data)
    if (result.ok) {
      return identity<Card>(result.value)
    } else {
      console.error(
        'GET /student/card 2XX Card\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/card 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/card 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/card 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/card 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentCard"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/card XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentCardExportParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentCardExportOk = ExportFile

export type getStudentCardExportErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentCardExport (params: getStudentCardExportParams): Promise<getStudentCardExportOk> {
  const url = "/student/card/export"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX ExportFile
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ExportFileDecoder).decode(data)
    if (result.ok) {
      return identity<ExportFile>(result.value)
    } else {
      console.error(
        'GET /student/card/export 2XX ExportFile\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/card/export 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/card/export 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/card/export 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/card/export 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentCardExport"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/card/export XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentCalendarParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentCalendarOk = readonly CalendarItem[]

export type getStudentCalendarErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentCalendar (params: getStudentCalendarParams): Promise<getStudentCalendarOk> {
  const url = "/student/calendar"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly CalendarItem[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(CalendarItemDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly CalendarItem[]>(result.value)
    } else {
      console.error(
        'GET /student/calendar 2XX readonly CalendarItem[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/calendar 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/calendar 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/calendar 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/calendar 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentCalendar"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/calendar XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentCourseParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getStudentCourseOk = Course

export type getStudentCourseErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentCourse (params: getStudentCourseParams): Promise<getStudentCourseOk> {
  let url = "/student/courses/"
  url += params.args["id"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Course
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(CourseDecoder).decode(data)
    if (result.ok) {
      return identity<Course>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 2XX Course\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentCourse"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentCourseSyllabusParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getStudentCourseSyllabusOk = CourseSyllabus

export type getStudentCourseSyllabusErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentCourseSyllabus (params: getStudentCourseSyllabusParams): Promise<getStudentCourseSyllabusOk> {
  let url = "/student/courses/"
  url += params.args["id"]
  url += "/syllabus"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX CourseSyllabus
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(CourseSyllabusDecoder).decode(data)
    if (result.ok) {
      return identity<CourseSyllabus>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 2XX CourseSyllabus\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentCourseSyllabus"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentCourseGroupsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getStudentCourseGroupsOk = readonly Group[]

export type getStudentCourseGroupsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentCourseGroups (params: getStudentCourseGroupsParams): Promise<getStudentCourseGroupsOk> {
  let url = "/student/courses/"
  url += params.args["id"]
  url += "/groups"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Group[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(GroupDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Group[]>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 2XX readonly Group[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentCourseGroups"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentCourseClassmatesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getStudentCourseClassmatesOk = readonly User[]

export type getStudentCourseClassmatesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentCourseClassmates (params: getStudentCourseClassmatesParams): Promise<getStudentCourseClassmatesOk> {
  let url = "/student/courses/"
  url += params.args["id"]
  url += "/classmates"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly User[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(UserDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly User[]>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 2XX readonly User[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentCourseClassmates"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentCourseGroupScheduleParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "page": number
    readonly "perPage": number
  }
  readonly "args": {
    readonly "id": string
    readonly "groupId": string
  }
}

export interface getStudentCourseGroupScheduleOk {
  readonly "data": readonly GroupSchedule[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getStudentCourseGroupScheduleErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentCourseGroupSchedule (params: getStudentCourseGroupScheduleParams): Promise<getStudentCourseGroupScheduleOk> {
  let url = "/student/courses/"
  url += params.args["id"]
  url += "/groups/"
  url += params.args["groupId"]
  url += "/schedule"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly GroupSchedule[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(GroupScheduleDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly GroupSchedule[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 2XX {\n' +
        '  readonly "data": readonly GroupSchedule[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentCourseGroupSchedule"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentCourseGroupWeekScheduleParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
    readonly "groupId": string
  }
}

export type getStudentCourseGroupWeekScheduleOk = readonly GroupSchedule[]

export type getStudentCourseGroupWeekScheduleErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentCourseGroupWeekSchedule (params: getStudentCourseGroupWeekScheduleParams): Promise<getStudentCourseGroupWeekScheduleOk> {
  let url = "/student/courses/"
  url += params.args["id"]
  url += "/groups/"
  url += params.args["groupId"]
  url += "/week-schedule"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly GroupSchedule[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(GroupScheduleDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly GroupSchedule[]>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 2XX readonly GroupSchedule[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentCourseGroupWeekSchedule"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentCourseScoresParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getStudentCourseScoresOk = Choice

export type getStudentCourseScoresErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentCourseScores (params: getStudentCourseScoresParams): Promise<getStudentCourseScoresOk> {
  let url = "/student/courses/"
  url += params.args["id"]
  url += "/scores"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Choice
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ChoiceDecoder).decode(data)
    if (result.ok) {
      return identity<Choice>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 2XX Choice\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentCourseScores"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentCoursesMaterialsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "filters": {
      readonly "search": null | string
    }
    readonly "page": number
    readonly "perPage": number
  }
  readonly "args": {
    readonly "id": string
  }
}

export interface getStudentCoursesMaterialsOk {
  readonly "data": readonly CourseFile[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getStudentCoursesMaterialsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentCoursesMaterials (params: getStudentCoursesMaterialsParams): Promise<getStudentCoursesMaterialsOk> {
  let url = "/student/courses/"
  url += params.args["id"]
  url += "/materials"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly CourseFile[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(CourseFileDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly CourseFile[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 2XX {\n' +
        '  readonly "data": readonly CourseFile[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentCoursesMaterials"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentCoursePostsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getStudentCoursePostsOk = readonly CoursePost[]

export type getStudentCoursePostsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentCoursePosts (params: getStudentCoursePostsParams): Promise<getStudentCoursePostsOk> {
  let url = "/student/courses/"
  url += params.args["id"]
  url += "/posts"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly CoursePost[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(CoursePostDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly CoursePost[]>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 2XX readonly CoursePost[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentCoursePosts"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentCoursePostCommentsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
    readonly "postId": string
  }
}

export type getStudentCoursePostCommentsOk = readonly CoursePostComment[]

export type getStudentCoursePostCommentsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentCoursePostComments (params: getStudentCoursePostCommentsParams): Promise<getStudentCoursePostCommentsOk> {
  let url = "/student/courses/"
  url += params.args["id"]
  url += "/posts/"
  url += params.args["postId"]
  url += "/comments"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly CoursePostComment[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(CoursePostCommentDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly CoursePostComment[]>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 2XX readonly CoursePostComment[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentCoursePostComments"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentCoursesPostsCommentsParams {
  readonly "body": {
    readonly "replyCommentId"?: string
    readonly "body": string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
    readonly "postId": string
  }
}

export type postStudentCoursesPostsCommentsOk = CoursePostComment

export type postStudentCoursesPostsCommentsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentCoursesPostsComments (params: postStudentCoursesPostsCommentsParams): Promise<postStudentCoursesPostsCommentsOk> {
  let url = "/student/courses/"
  url += params.args["id"]
  url += "/posts/"
  url += params.args["postId"]
  url += "/comments/"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "replyCommentId": params.body["replyCommentId"],
      "body": params.body["body"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX CoursePostComment
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(CoursePostCommentDecoder).decode(data)
    if (result.ok) {
      return identity<CoursePostComment>(result.value)
    } else {
      console.error(
        'POST /student/courses/ 2XX CoursePostComment\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentCoursesPostsComments"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentStatementsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "filters": {
      readonly "search": null | string
      readonly "semester": null | string
    }
    readonly "page": number
    readonly "perPage": number
  }
}

export interface getStudentStatementsOk {
  readonly "data": readonly Statement[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getStudentStatementsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentStatements (params: getStudentStatementsParams): Promise<getStudentStatementsOk> {
  const url = "/student/statements"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly Statement[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(StatementDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly Statement[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /student/statements 2XX {\n' +
        '  readonly "data": readonly Statement[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/statements 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/statements 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/statements 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/statements 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentStatements"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/statements XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentStatementParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
    readonly "semId": string
  }
}

export type getStudentStatementOk = Statement

export type getStudentStatementErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentStatement (params: getStudentStatementParams): Promise<getStudentStatementOk> {
  let url = "/student/statements/"
  url += params.args["id"]
  url += "/"
  url += params.args["semId"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Statement
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(StatementDecoder).decode(data)
    if (result.ok) {
      return identity<Statement>(result.value)
    } else {
      console.error(
        'GET /student/statements/ 2XX Statement\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/statements/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/statements/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/statements/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/statements/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentStatement"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/statements/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getFaqQuestionsParams {
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "search": null | string
    readonly "page": number
    readonly "perPage": number
  }
}

export interface getFaqQuestionsOk {
  readonly "data": readonly Faq[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getFaqQuestionsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getFaqQuestions (params: getFaqQuestionsParams): Promise<getFaqQuestionsOk> {
  const url = "/faq/questions"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly Faq[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(FaqDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly Faq[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /faq/questions 2XX {\n' +
        '  readonly "data": readonly Faq[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /faq/questions 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /faq/questions 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /faq/questions 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /faq/questions 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getFaqQuestions"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /faq/questions XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getFaqQuestionParams {
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export interface getFaqQuestionOk {
  readonly "data": Faq
}

export type getFaqQuestionErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getFaqQuestion (params: getFaqQuestionParams): Promise<getFaqQuestionOk> {
  let url = "/faq/questions/"
  url += params.args["id"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": Faq
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": FaqDecoder,
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": Faq
      }>(result.value)
    } else {
      console.error(
        'GET /faq/questions/ 2XX {\n' +
        '  readonly "data": Faq\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /faq/questions/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /faq/questions/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /faq/questions/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /faq/questions/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getFaqQuestion"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /faq/questions/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postLecturerCalendarParams {
  readonly "body": {
    readonly "name": string
    readonly "nameEn": string
    readonly "date": string
    readonly "courseId": IDString
    readonly "groupId": IDString
    readonly "criteriaId"?: IDString
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type postLecturerCalendarOk = null

export type postLecturerCalendarErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postLecturerCalendar (params: postLecturerCalendarParams): Promise<postLecturerCalendarOk> {
  const url = "/lecturer/calendar"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "name": params.body["name"],
      "nameEn": params.body["nameEn"],
      "date": params.body["date"],
      "courseId": params.body["courseId"],
      "groupId": params.body["groupId"],
      "criteriaId": params.body["criteriaId"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /lecturer/calendar 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /lecturer/calendar 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /lecturer/calendar 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /lecturer/calendar 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /lecturer/calendar 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postLecturerCalendar"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /lecturer/calendar XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerCalendarParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getLecturerCalendarOk = readonly CalendarItem[]

export type getLecturerCalendarErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerCalendar (params: getLecturerCalendarParams): Promise<getLecturerCalendarOk> {
  const url = "/lecturer/calendar"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly CalendarItem[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(CalendarItemDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly CalendarItem[]>(result.value)
    } else {
      console.error(
        'GET /lecturer/calendar 2XX readonly CalendarItem[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/calendar 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/calendar 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/calendar 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/calendar 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerCalendar"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/calendar XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface deleteLecturerCalendarParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type deleteLecturerCalendarOk = MessageObject

export type deleteLecturerCalendarErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function deleteLecturerCalendar (params: deleteLecturerCalendarParams): Promise<deleteLecturerCalendarOk> {
  let url = "/lecturer/calendar/"
  url += params.args["id"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "DELETE",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX MessageObject
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(MessageObjectDecoder).decode(data)
    if (result.ok) {
      return identity<MessageObject>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/calendar/ 2XX MessageObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/calendar/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/calendar/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/calendar/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/calendar/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("deleteLecturerCalendar"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/calendar/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getCoursesLecturersParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getCoursesLecturersOk = readonly User[]

export type getCoursesLecturersErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getCoursesLecturers (params: getCoursesLecturersParams): Promise<getCoursesLecturersOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/my-groups-lecturers"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly User[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(UserDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly User[]>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 2XX readonly User[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getCoursesLecturers"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerCoursesMaterialsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "filters": {
      readonly "author": null | string
      readonly "search": null | string
    }
    readonly "page": null | string
    readonly "semId": string
  }
  readonly "args": {
    readonly "id": string
  }
}

export interface getLecturerCoursesMaterialsOk {
  readonly "data": readonly CourseFile[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getLecturerCoursesMaterialsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerCoursesMaterials (params: getLecturerCoursesMaterialsParams): Promise<getLecturerCoursesMaterialsOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/materials"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly CourseFile[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(CourseFileDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly CourseFile[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 2XX {\n' +
        '  readonly "data": readonly CourseFile[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerCoursesMaterials"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postLecturerCoursesMaterialsParams {
  readonly "body": {
    readonly "semId": string
    readonly "title": string
    readonly "url": null | string
    readonly "fileUid": null | string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type postLecturerCoursesMaterialsOk = null

export type postLecturerCoursesMaterialsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postLecturerCoursesMaterials (params: postLecturerCoursesMaterialsParams): Promise<postLecturerCoursesMaterialsOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/materials"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "semId": params.body["semId"],
      "title": params.body["title"],
      "url": params.body["url"],
      "fileUid": params.body["fileUid"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postLecturerCoursesMaterials"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface patchLecturerCoursesMaterialsParams {
  readonly "body": {
    readonly "semId": string
    readonly "title": string
    readonly "url": null | string
    readonly "fileUid": null | string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
    readonly "fileId": string
  }
}

export type patchLecturerCoursesMaterialsOk = null

export type patchLecturerCoursesMaterialsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function patchLecturerCoursesMaterials (params: patchLecturerCoursesMaterialsParams): Promise<patchLecturerCoursesMaterialsOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/materials/"
  url += params.args["fileId"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "PATCH",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "semId": params.body["semId"],
      "title": params.body["title"],
      "url": params.body["url"],
      "fileUid": params.body["fileUid"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("patchLecturerCoursesMaterials"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface deleteLecturerCoursesMaterialsParams {
  readonly "body": {
    readonly "semId": string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
    readonly "fileId": string
  }
}

export type deleteLecturerCoursesMaterialsOk = MessageObject

export type deleteLecturerCoursesMaterialsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function deleteLecturerCoursesMaterials (params: deleteLecturerCoursesMaterialsParams): Promise<deleteLecturerCoursesMaterialsOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/materials/"
  url += params.args["fileId"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "DELETE",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "semId": params.body["semId"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX MessageObject
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(MessageObjectDecoder).decode(data)
    if (result.ok) {
      return identity<MessageObject>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ 2XX MessageObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("deleteLecturerCoursesMaterials"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerCoursesMaterialParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "id": string
    readonly "fileId": string
  }
}

export type getLecturerCoursesMaterialOk = CourseFile

export type getLecturerCoursesMaterialErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerCoursesMaterial (params: getLecturerCoursesMaterialParams): Promise<getLecturerCoursesMaterialOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/materials/"
  url += params.args["fileId"]
  url += ""
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX CourseFile
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(CourseFileDecoder).decode(data)
    if (result.ok) {
      return identity<CourseFile>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 2XX CourseFile\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerCoursesMaterial"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerCoursesStudentsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "courseId": string
    readonly "groupId": string
  }
}

export type getLecturerCoursesStudentsOk = readonly LecturerUser[]

export type getLecturerCoursesStudentsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerCoursesStudents (params: getLecturerCoursesStudentsParams): Promise<getLecturerCoursesStudentsOk> {
  let url = "/lecturer/courses/"
  url += params.args["courseId"]
  url += "/teaching-groups/"
  url += params.args["groupId"]
  url += "/students"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly LecturerUser[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(LecturerUserDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly LecturerUser[]>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 2XX readonly LecturerUser[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerCoursesStudents"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerSchedulesCurrentParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getLecturerSchedulesCurrentOk = readonly ScheduleDay[]

export type getLecturerSchedulesCurrentErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerSchedulesCurrent (params: getLecturerSchedulesCurrentParams): Promise<getLecturerSchedulesCurrentOk> {
  const url = "/lecturer/schedules/current"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly ScheduleDay[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(ScheduleDayDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly ScheduleDay[]>(result.value)
    } else {
      console.error(
        'GET /lecturer/schedules/current 2XX readonly ScheduleDay[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/schedules/current 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/schedules/current 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/schedules/current 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/schedules/current 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerSchedulesCurrent"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/schedules/current XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerCoursesTeachesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
}

export type getLecturerCoursesTeachesOk = readonly Course[]

export type getLecturerCoursesTeachesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerCoursesTeaches (params: getLecturerCoursesTeachesParams): Promise<getLecturerCoursesTeachesOk> {
  const url = "/lecturer/courses/teaches"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Course[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(CourseDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Course[]>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/teaches 2XX readonly Course[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/teaches 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/teaches 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/teaches 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/teaches 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerCoursesTeaches"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/teaches XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerCoursesManageParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
}

export type getLecturerCoursesManageOk = readonly Course[]

export type getLecturerCoursesManageErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerCoursesManage (params: getLecturerCoursesManageParams): Promise<getLecturerCoursesManageOk> {
  const url = "/lecturer/courses/manage"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Course[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(CourseDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Course[]>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/manage 2XX readonly Course[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/manage 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/manage 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/manage 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/manage 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerCoursesManage"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/manage XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerFeedbacksSemestersParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getLecturerFeedbacksSemestersOk = readonly Semester[]

export type getLecturerFeedbacksSemestersErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerFeedbacksSemesters (params: getLecturerFeedbacksSemestersParams): Promise<getLecturerFeedbacksSemestersOk> {
  const url = "/lecturer/feedbacks/semesters"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Semester[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(SemesterDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Semester[]>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/semesters 2XX readonly Semester[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/semesters 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/semesters 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/semesters 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/semesters 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerFeedbacksSemesters"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/semesters XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerFeedbacksSubjectsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getLecturerFeedbacksSubjectsOk = readonly FeedbackSubject[]

export type getLecturerFeedbacksSubjectsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerFeedbacksSubjects (params: getLecturerFeedbacksSubjectsParams): Promise<getLecturerFeedbacksSubjectsOk> {
  const url = "/lecturer/feedbacks/subjects"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly FeedbackSubject[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(FeedbackSubjectDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly FeedbackSubject[]>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/subjects 2XX readonly FeedbackSubject[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/subjects 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/subjects 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/subjects 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/subjects 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerFeedbacksSubjects"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/subjects XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postLecturerFeedbacksParams {
  readonly "body": {
    readonly "subjectId": IDString
    readonly "text": IDString
    readonly "files": readonly string[]
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type postLecturerFeedbacksOk = null

export type postLecturerFeedbacksErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postLecturerFeedbacks (params: postLecturerFeedbacksParams): Promise<postLecturerFeedbacksOk> {
  const url = "/lecturer/feedbacks"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "subjectId": params.body["subjectId"],
      "text": params.body["text"],
      "files": params.body["files"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /lecturer/feedbacks 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /lecturer/feedbacks 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /lecturer/feedbacks 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /lecturer/feedbacks 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /lecturer/feedbacks 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postLecturerFeedbacks"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /lecturer/feedbacks XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerFeedbackParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "id": IDString
  }
}

export type getLecturerFeedbackOk = Feedback

export type getLecturerFeedbackErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerFeedback (params: getLecturerFeedbackParams): Promise<getLecturerFeedbackOk> {
  let url = "/lecturer/feedbacks/"
  url += params.args["id"]
  url += ""
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Feedback
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(FeedbackDecoder).decode(data)
    if (result.ok) {
      return identity<Feedback>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/ 2XX Feedback\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerFeedback"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerFeedbacksParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "page": number
    readonly "semId": string
  }
}

export interface getLecturerFeedbacksOk {
  readonly "data": readonly Feedback[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getLecturerFeedbacksErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerFeedbacks (params: getLecturerFeedbacksParams): Promise<getLecturerFeedbacksOk> {
  const url = "/lecturer/feedbacks"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly Feedback[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(FeedbackDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly Feedback[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks 2XX {\n' +
        '  readonly "data": readonly Feedback[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerFeedbacks"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/feedbacks XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentStatementsTemplatesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentStatementsTemplatesOk = readonly StatementTemplate[]

export type getStudentStatementsTemplatesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentStatementsTemplates (params: getStudentStatementsTemplatesParams): Promise<getStudentStatementsTemplatesOk> {
  const url = "/student/statements/templates"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly StatementTemplate[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(StatementTemplateDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly StatementTemplate[]>(result.value)
    } else {
      console.error(
        'GET /student/statements/templates 2XX readonly StatementTemplate[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/statements/templates 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/statements/templates 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/statements/templates 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/statements/templates 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentStatementsTemplates"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/statements/templates XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentStatementTemplateParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": IDString
  }
}

export type getStudentStatementTemplateOk = StatementTemplateView

export type getStudentStatementTemplateErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentStatementTemplate (params: getStudentStatementTemplateParams): Promise<getStudentStatementTemplateOk> {
  let url = "/student/statements/templates/"
  url += params.args["id"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX StatementTemplateView
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(StatementTemplateViewDecoder).decode(data)
    if (result.ok) {
      return identity<StatementTemplateView>(result.value)
    } else {
      console.error(
        'GET /student/statements/templates/ 2XX StatementTemplateView\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/statements/templates/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/statements/templates/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/statements/templates/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/statements/templates/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentStatementTemplate"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/statements/templates/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentStatementsSemestersParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentStatementsSemestersOk = readonly Semester[]

export type getStudentStatementsSemestersErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentStatementsSemesters (params: getStudentStatementsSemestersParams): Promise<getStudentStatementsSemestersOk> {
  const url = "/student/statements/semesters"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Semester[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(SemesterDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Semester[]>(result.value)
    } else {
      console.error(
        'GET /student/statements/semesters 2XX readonly Semester[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/statements/semesters 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/statements/semesters 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/statements/semesters 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/statements/semesters 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentStatementsSemesters"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/statements/semesters XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentFeedbacksSemestersParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentFeedbacksSemestersOk = readonly Semester[]

export type getStudentFeedbacksSemestersErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentFeedbacksSemesters (params: getStudentFeedbacksSemestersParams): Promise<getStudentFeedbacksSemestersOk> {
  const url = "/student/feedbacks/semesters"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Semester[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(SemesterDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Semester[]>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/semesters 2XX readonly Semester[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/semesters 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/semesters 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/semesters 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/semesters 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentFeedbacksSemesters"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/semesters XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentStatementsFacultiesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentStatementsFacultiesOk = readonly Faculty[]

export type getStudentStatementsFacultiesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentStatementsFaculties (params: getStudentStatementsFacultiesParams): Promise<getStudentStatementsFacultiesOk> {
  const url = "/student/statements/faculties"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Faculty[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(FacultyDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Faculty[]>(result.value)
    } else {
      console.error(
        'GET /student/statements/faculties 2XX readonly Faculty[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/statements/faculties 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/statements/faculties 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/statements/faculties 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/statements/faculties 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentStatementsFaculties"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/statements/faculties XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentStatementsFacultiesProgramsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": IDString
  }
}

export type getStudentStatementsFacultiesProgramsOk = readonly Program[]

export type getStudentStatementsFacultiesProgramsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentStatementsFacultiesPrograms (params: getStudentStatementsFacultiesProgramsParams): Promise<getStudentStatementsFacultiesProgramsOk> {
  let url = "/student/statements/faculties/"
  url += params.args["id"]
  url += "/programs"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Program[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(ProgramDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Program[]>(result.value)
    } else {
      console.error(
        'GET /student/statements/faculties/ 2XX readonly Program[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/statements/faculties/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/statements/faculties/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/statements/faculties/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/statements/faculties/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentStatementsFacultiesPrograms"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/statements/faculties/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentStatementsParams {
  readonly "body": {
    readonly "template": StatementTemplateRequest
    readonly "files": readonly string[]
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type postStudentStatementsOk = null

export type postStudentStatementsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentStatements (params: postStudentStatementsParams): Promise<postStudentStatementsOk> {
  const url = "/student/statements"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "template": params.body["template"],
      "files": params.body["files"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /student/statements 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/statements 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/statements 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/statements 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/statements 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentStatements"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/statements XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentCoursesCatalogParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "filters[search]": string
    readonly "page": number
    readonly "perPage": number
  }
}

export interface getStudentCoursesCatalogOk {
  readonly "data": readonly Course[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getStudentCoursesCatalogErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentCoursesCatalog (params: getStudentCoursesCatalogParams): Promise<getStudentCoursesCatalogOk> {
  const url = "/student/courses/catalog"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly Course[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(CourseDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly Course[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /student/courses/catalog 2XX {\n' +
        '  readonly "data": readonly Course[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/courses/catalog 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/courses/catalog 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/courses/catalog 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/courses/catalog 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentCoursesCatalog"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/courses/catalog XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLibraryBooksParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "page": number
    readonly "perPage": number
  }
}

export interface getLibraryBooksOk {
  readonly "data": readonly LibraryBook[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getLibraryBooksErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLibraryBooks (params: getLibraryBooksParams): Promise<getLibraryBooksOk> {
  const url = "/library/books"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly LibraryBook[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(LibraryBookDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly LibraryBook[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /library/books 2XX {\n' +
        '  readonly "data": readonly LibraryBook[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /library/books 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /library/books 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /library/books 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /library/books 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLibraryBooks"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /library/books XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLibraryBooksTakenParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getLibraryBooksTakenOk = LibraryBooksTaken

export type getLibraryBooksTakenErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLibraryBooksTaken (params: getLibraryBooksTakenParams): Promise<getLibraryBooksTakenOk> {
  const url = "/library/books-taken"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX LibraryBooksTaken
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(LibraryBooksTakenDecoder).decode(data)
    if (result.ok) {
      return identity<LibraryBooksTaken>(result.value)
    } else {
      console.error(
        'GET /library/books-taken 2XX LibraryBooksTaken\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /library/books-taken 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /library/books-taken 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /library/books-taken 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /library/books-taken 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLibraryBooksTaken"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /library/books-taken XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentBillingBalanceParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentBillingBalanceOk = BillingBalance

export type getStudentBillingBalanceErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentBillingBalance (params: getStudentBillingBalanceParams): Promise<getStudentBillingBalanceOk> {
  const url = "/student/billing/balance"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX BillingBalance
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(BillingBalanceDecoder).decode(data)
    if (result.ok) {
      return identity<BillingBalance>(result.value)
    } else {
      console.error(
        'GET /student/billing/balance 2XX BillingBalance\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/billing/balance 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/billing/balance 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/billing/balance 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/billing/balance 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentBillingBalance"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/billing/balance XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentBillingDepositsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentBillingDepositsOk = readonly BillingDeposit[]

export type getStudentBillingDepositsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentBillingDeposits (params: getStudentBillingDepositsParams): Promise<getStudentBillingDepositsOk> {
  const url = "/student/billing/deposits"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly BillingDeposit[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(BillingDepositDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly BillingDeposit[]>(result.value)
    } else {
      console.error(
        'GET /student/billing/deposits 2XX readonly BillingDeposit[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/billing/deposits 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/billing/deposits 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/billing/deposits 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/billing/deposits 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentBillingDeposits"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/billing/deposits XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentBillingScheduleParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentBillingScheduleOk = readonly BillingSchedule[]

export type getStudentBillingScheduleErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentBillingSchedule (params: getStudentBillingScheduleParams): Promise<getStudentBillingScheduleOk> {
  const url = "/student/billing/schedule"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly BillingSchedule[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(BillingScheduleDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly BillingSchedule[]>(result.value)
    } else {
      console.error(
        'GET /student/billing/schedule 2XX readonly BillingSchedule[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/billing/schedule 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/billing/schedule 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/billing/schedule 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/billing/schedule 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentBillingSchedule"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/billing/schedule XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getUserSettingsGeneralParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getUserSettingsGeneralOk = GeneralSettings

export type getUserSettingsGeneralErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getUserSettingsGeneral (params: getUserSettingsGeneralParams): Promise<getUserSettingsGeneralOk> {
  const url = "/user/settings/general"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX GeneralSettings
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(GeneralSettingsDecoder).decode(data)
    if (result.ok) {
      return identity<GeneralSettings>(result.value)
    } else {
      console.error(
        'GET /user/settings/general 2XX GeneralSettings\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /user/settings/general 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /user/settings/general 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /user/settings/general 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /user/settings/general 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getUserSettingsGeneral"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /user/settings/general XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface putUserSettingsGeneralParams {
  readonly "body": {
    readonly "locale": LanguageString
    readonly "showMail": boolean
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type putUserSettingsGeneralOk = null

export type putUserSettingsGeneralErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function putUserSettingsGeneral (params: putUserSettingsGeneralParams): Promise<putUserSettingsGeneralOk> {
  const url = "/user/settings/general"
  const response = await fetch(baseURL + url, {
    method: "PUT",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "locale": params.body["locale"],
      "showMail": params.body["showMail"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'PUT /user/settings/general 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'PUT /user/settings/general 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'PUT /user/settings/general 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'PUT /user/settings/general 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'PUT /user/settings/general 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("putUserSettingsGeneral"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'PUT /user/settings/general XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getUserSettingsNotificationsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getUserSettingsNotificationsOk = NotificationSettings

export type getUserSettingsNotificationsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getUserSettingsNotifications (params: getUserSettingsNotificationsParams): Promise<getUserSettingsNotificationsOk> {
  const url = "/user/settings/notifications"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX NotificationSettings
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(NotificationSettingsDecoder).decode(data)
    if (result.ok) {
      return identity<NotificationSettings>(result.value)
    } else {
      console.error(
        'GET /user/settings/notifications 2XX NotificationSettings\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /user/settings/notifications 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /user/settings/notifications 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /user/settings/notifications 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /user/settings/notifications 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getUserSettingsNotifications"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /user/settings/notifications XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface putUserSettingsNotificationsParams {
  readonly "body": {
    readonly "notifyOnMessage": NotificationsData
    readonly "notifyOnLectureStarts": NotificationsData
    readonly "notifyOnScoreSave": NotificationsData
    readonly "notifyOnNewsAdd": NotificationsData
    readonly "notifyOnMaterialAdd": NotificationsData
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type putUserSettingsNotificationsOk = null

export type putUserSettingsNotificationsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function putUserSettingsNotifications (params: putUserSettingsNotificationsParams): Promise<putUserSettingsNotificationsOk> {
  const url = "/user/settings/notifications"
  const response = await fetch(baseURL + url, {
    method: "PUT",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "notifyOnMessage": params.body["notifyOnMessage"],
      "notifyOnLectureStarts": params.body["notifyOnLectureStarts"],
      "notifyOnScoreSave": params.body["notifyOnScoreSave"],
      "notifyOnNewsAdd": params.body["notifyOnNewsAdd"],
      "notifyOnMaterialAdd": params.body["notifyOnMaterialAdd"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'PUT /user/settings/notifications 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'PUT /user/settings/notifications 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'PUT /user/settings/notifications 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'PUT /user/settings/notifications 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'PUT /user/settings/notifications 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("putUserSettingsNotifications"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'PUT /user/settings/notifications XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getUserAuthLogParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "page": number
    readonly "perPage": number
  }
}

export interface getUserAuthLogOk {
  readonly "data": readonly AuthLog[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getUserAuthLogErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getUserAuthLog (params: getUserAuthLogParams): Promise<getUserAuthLogOk> {
  const url = "/user/auth-log"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly AuthLog[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(AuthLogDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly AuthLog[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /user/auth-log 2XX {\n' +
        '  readonly "data": readonly AuthLog[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /user/auth-log 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /user/auth-log 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /user/auth-log 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /user/auth-log 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getUserAuthLog"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /user/auth-log XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getUserSemestersAvailableParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getUserSemestersAvailableOk = readonly Semester[]

export type getUserSemestersAvailableErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getUserSemestersAvailable (params: getUserSemestersAvailableParams): Promise<getUserSemestersAvailableOk> {
  const url = "/user/semesters/available"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Semester[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(SemesterDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Semester[]>(result.value)
    } else {
      console.error(
        'GET /user/semesters/available 2XX readonly Semester[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /user/semesters/available 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /user/semesters/available 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /user/semesters/available 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /user/semesters/available 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getUserSemestersAvailable"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /user/semesters/available XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getUserStateParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getUserStateOk = UserState

export type getUserStateErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getUserState (params: getUserStateParams): Promise<getUserStateOk> {
  const url = "/user/state"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX UserState
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(UserStateDecoder).decode(data)
    if (result.ok) {
      return identity<UserState>(result.value)
    } else {
      console.error(
        'GET /user/state 2XX UserState\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /user/state 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /user/state 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /user/state 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /user/state 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getUserState"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /user/state XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface patchUserPasswordParams {
  readonly "body": {
    readonly "currentPassword": string
    readonly "password": string
    readonly "passwordConfirmation": string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type patchUserPasswordOk = null

export type patchUserPasswordErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function patchUserPassword (params: patchUserPasswordParams): Promise<patchUserPasswordOk> {
  const url = "/user/password"
  const response = await fetch(baseURL + url, {
    method: "PATCH",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "currentPassword": params.body["currentPassword"],
      "password": params.body["password"],
      "passwordConfirmation": params.body["passwordConfirmation"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'PATCH /user/password 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'PATCH /user/password 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'PATCH /user/password 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'PATCH /user/password 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'PATCH /user/password 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("patchUserPassword"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'PATCH /user/password XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface patchUserContactParams {
  readonly "body": {
    readonly "mobileNumber": string
    readonly "mobileNumber2": string
    readonly "homeNumber": string
    readonly "actualAddress": string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type patchUserContactOk = null

export type patchUserContactErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function patchUserContact (params: patchUserContactParams): Promise<patchUserContactOk> {
  const url = "/user/contact"
  const response = await fetch(baseURL + url, {
    method: "PATCH",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "mobileNumber": params.body["mobileNumber"],
      "mobileNumber2": params.body["mobileNumber2"],
      "homeNumber": params.body["homeNumber"],
      "actualAddress": params.body["actualAddress"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'PATCH /user/contact 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'PATCH /user/contact 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'PATCH /user/contact 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'PATCH /user/contact 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'PATCH /user/contact 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("patchUserContact"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'PATCH /user/contact XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getUserParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "uid": string
  }
}

export type getUserOk = User

export type getUserErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getUser (params: getUserParams): Promise<getUserOk> {
  let url = "/users/"
  url += params.args["uid"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX User
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(UserDecoder).decode(data)
    if (result.ok) {
      return identity<User>(result.value)
    } else {
      console.error(
        'GET /users/ 2XX User\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /users/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /users/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /users/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /users/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getUser"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /users/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getMessagesInboxParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "page": number
    readonly "perPage": number
    readonly "filters": {
      readonly "search": null | string
    }
    readonly "semId": IDString
  }
}

export interface getMessagesInboxOk {
  readonly "data": readonly Message[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getMessagesInboxErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getMessagesInbox (params: getMessagesInboxParams): Promise<getMessagesInboxOk> {
  const url = "/messages/inbox"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly Message[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(MessageDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly Message[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /messages/inbox 2XX {\n' +
        '  readonly "data": readonly Message[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /messages/inbox 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /messages/inbox 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /messages/inbox 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /messages/inbox 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getMessagesInbox"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /messages/inbox XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getMessagesOutboxParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "page": number
    readonly "perPage": number
    readonly "filters": {
      readonly "search": null | string
    }
    readonly "semId": IDString
  }
}

export interface getMessagesOutboxOk {
  readonly "data": readonly Message[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getMessagesOutboxErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getMessagesOutbox (params: getMessagesOutboxParams): Promise<getMessagesOutboxOk> {
  const url = "/messages/outbox"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly Message[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(MessageDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly Message[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /messages/outbox 2XX {\n' +
        '  readonly "data": readonly Message[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /messages/outbox 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /messages/outbox 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /messages/outbox 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /messages/outbox 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getMessagesOutbox"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /messages/outbox XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getMessageParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "messageId": string
  }
}

export type getMessageOk = Message

export type getMessageErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getMessage (params: getMessageParams): Promise<getMessageOk> {
  let url = "/messages/"
  url += params.args["messageId"]
  url += ""
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Message
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(MessageDecoder).decode(data)
    if (result.ok) {
      return identity<Message>(result.value)
    } else {
      console.error(
        'GET /messages/ 2XX Message\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /messages/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /messages/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /messages/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /messages/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getMessage"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /messages/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentByMessageParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "messageId": string
  }
}

export type getStudentByMessageOk = LecturerUser

export type getStudentByMessageErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentByMessage (params: getStudentByMessageParams): Promise<getStudentByMessageOk> {
  let url = "/lecturer/students/"
  url += params.args["messageId"]
  url += ""
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX LecturerUser
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(LecturerUserDecoder).decode(data)
    if (result.ok) {
      return identity<LecturerUser>(result.value)
    } else {
      console.error(
        'GET /lecturer/students/ 2XX LecturerUser\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/students/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/students/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/students/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/students/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentByMessage"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/students/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getRecommendationParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "senderProfileId": string
  }
}

export type getRecommendationOk = RecommendationLecturerSide

export type getRecommendationErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getRecommendation (params: getRecommendationParams): Promise<getRecommendationOk> {
  let url = "/lecturer/recommendations/"
  url += params.args["senderProfileId"]
  url += ""
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX RecommendationLecturerSide
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(RecommendationLecturerSideDecoder).decode(data)
    if (result.ok) {
      return identity<RecommendationLecturerSide>(result.value)
    } else {
      console.error(
        'GET /lecturer/recommendations/ 2XX RecommendationLecturerSide\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/recommendations/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/recommendations/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/recommendations/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/recommendations/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getRecommendation"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/recommendations/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getRecommendationStudentInfoParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "senderProfileId": string
  }
}

export type getRecommendationStudentInfoOk = RecommendationStudentInfo

export type getRecommendationStudentInfoErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getRecommendationStudentInfo (params: getRecommendationStudentInfoParams): Promise<getRecommendationStudentInfoOk> {
  let url = "/lecturer/recommendations/"
  url += params.args["senderProfileId"]
  url += "/student"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX RecommendationStudentInfo
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(RecommendationStudentInfoDecoder).decode(data)
    if (result.ok) {
      return identity<RecommendationStudentInfo>(result.value)
    } else {
      console.error(
        'GET /lecturer/recommendations/ 2XX RecommendationStudentInfo\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/recommendations/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/recommendations/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/recommendations/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/recommendations/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getRecommendationStudentInfo"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/recommendations/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getMessagesUnreadCountParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getMessagesUnreadCountOk = MessageUnreadCount

export type getMessagesUnreadCountErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getMessagesUnreadCount (params: getMessagesUnreadCountParams): Promise<getMessagesUnreadCountOk> {
  const url = "/messages/unread-count"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX MessageUnreadCount
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(MessageUnreadCountDecoder).decode(data)
    if (result.ok) {
      return identity<MessageUnreadCount>(result.value)
    } else {
      console.error(
        'GET /messages/unread-count 2XX MessageUnreadCount\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /messages/unread-count 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /messages/unread-count 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /messages/unread-count 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /messages/unread-count 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getMessagesUnreadCount"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /messages/unread-count XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getMessagesExportParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "id": IDString
  }
}

export type getMessagesExportOk = ExportFile

export type getMessagesExportErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getMessagesExport (params: getMessagesExportParams): Promise<getMessagesExportOk> {
  let url = "/messages/"
  url += params.args["id"]
  url += "/export"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX ExportFile
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ExportFileDecoder).decode(data)
    if (result.ok) {
      return identity<ExportFile>(result.value)
    } else {
      console.error(
        'GET /messages/ 2XX ExportFile\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /messages/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /messages/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /messages/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /messages/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getMessagesExport"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /messages/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postMessagesToStudentsParams {
  readonly "body": {
    readonly "students": readonly string[]
    readonly "body": string
    readonly "subject": string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "courseId": IDString
  }
}

export type postMessagesToStudentsOk = null

export type postMessagesToStudentsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postMessagesToStudents (params: postMessagesToStudentsParams): Promise<postMessagesToStudentsOk> {
  let url = "/lecturer/courses/"
  url += params.args["courseId"]
  url += "/teaching-groups/message/students"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "students": params.body["students"],
      "body": params.body["body"],
      "subject": params.body["subject"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postMessagesToStudents"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postMessagesToGroupsParams {
  readonly "body": {
    readonly "groups": readonly string[]
    readonly "body": string
    readonly "subject": string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "courseId": IDString
  }
}

export type postMessagesToGroupsOk = null

export type postMessagesToGroupsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postMessagesToGroups (params: postMessagesToGroupsParams): Promise<postMessagesToGroupsOk> {
  let url = "/lecturer/courses/"
  url += params.args["courseId"]
  url += "/teaching-groups/message/groups"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "groups": params.body["groups"],
      "body": params.body["body"],
      "subject": params.body["subject"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postMessagesToGroups"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postMessagesParams {
  readonly "body": {
    readonly "subject": string
    readonly "body": string
    readonly "replyMsgId"?: IDString
    readonly "replyMsgSemId"?: IDString
    readonly "recipients": readonly string[]
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type postMessagesOk = null

export type postMessagesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postMessages (params: postMessagesParams): Promise<postMessagesOk> {
  const url = "/messages"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "subject": params.body["subject"],
      "body": params.body["body"],
      "replyMsgId": params.body["replyMsgId"],
      "replyMsgSemId": params.body["replyMsgSemId"],
      "recipients": params.body["recipients"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /messages 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /messages 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /messages 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /messages 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /messages 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postMessages"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /messages XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postLecturerRecommendationsParams {
  readonly "body": {
    readonly "text": string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "senderProfileId": string
  }
}

export type postLecturerRecommendationsOk = null

export type postLecturerRecommendationsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postLecturerRecommendations (params: postLecturerRecommendationsParams): Promise<postLecturerRecommendationsOk> {
  let url = "/lecturer/recommendations/"
  url += params.args["senderProfileId"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "text": params.body["text"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /lecturer/recommendations/ 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /lecturer/recommendations/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /lecturer/recommendations/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /lecturer/recommendations/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /lecturer/recommendations/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postLecturerRecommendations"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /lecturer/recommendations/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getAdministratorUsersParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "filters": {
      readonly "search": null | string
    }
    readonly "page": string
    readonly "perPage": string
  }
}

export type getAdministratorUsersOk = {
  readonly "data": readonly AdministratorUser[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
} | null

export type getAdministratorUsersErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getAdministratorUsers (params: getAdministratorUsersParams): Promise<getAdministratorUsersOk> {
  const url = "/administrator/users"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly AdministratorUser[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(AdministratorUserDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly AdministratorUser[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /administrator/users 2XX {\n' +
        '  readonly "data": readonly AdministratorUser[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'GET /administrator/users 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /administrator/users 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /administrator/users 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /administrator/users 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /administrator/users 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getAdministratorUsers"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /administrator/users XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface patchMessagesMarkReadParams {
  readonly "body": {
    readonly "semId": IDString
    readonly "messageIds": string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type patchMessagesMarkReadOk = null

export type patchMessagesMarkReadErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function patchMessagesMarkRead (params: patchMessagesMarkReadParams): Promise<patchMessagesMarkReadOk> {
  const url = "/messages/mark-read"
  const response = await fetch(baseURL + url, {
    method: "PATCH",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "semId": params.body["semId"],
      "messageIds": params.body["messageIds"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'PATCH /messages/mark-read 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'PATCH /messages/mark-read 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'PATCH /messages/mark-read 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'PATCH /messages/mark-read 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'PATCH /messages/mark-read 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("patchMessagesMarkRead"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'PATCH /messages/mark-read XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface patchMessagesMarkUnreadParams {
  readonly "body": {
    readonly "semId": IDString
    readonly "messageIds": string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type patchMessagesMarkUnreadOk = null

export type patchMessagesMarkUnreadErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function patchMessagesMarkUnread (params: patchMessagesMarkUnreadParams): Promise<patchMessagesMarkUnreadOk> {
  const url = "/messages/mark-unread"
  const response = await fetch(baseURL + url, {
    method: "PATCH",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "semId": params.body["semId"],
      "messageIds": params.body["messageIds"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'PATCH /messages/mark-unread 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'PATCH /messages/mark-unread 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'PATCH /messages/mark-unread 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'PATCH /messages/mark-unread 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'PATCH /messages/mark-unread 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("patchMessagesMarkUnread"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'PATCH /messages/mark-unread XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface deleteMessagesParams {
  readonly "body": {
    readonly "semId": IDString
    readonly "messageIds": string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type deleteMessagesOk = null

export type deleteMessagesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function deleteMessages (params: deleteMessagesParams): Promise<deleteMessagesOk> {
  const url = "/messages"
  const response = await fetch(baseURL + url, {
    method: "DELETE",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "semId": params.body["semId"],
      "messageIds": params.body["messageIds"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'DELETE /messages 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'DELETE /messages 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'DELETE /messages 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'DELETE /messages 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'DELETE /messages 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("deleteMessages"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'DELETE /messages XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentRatingParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentRatingOk = Rating

export type getStudentRatingErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentRating (params: getStudentRatingParams): Promise<getStudentRatingOk> {
  const url = "/student/rating"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Rating
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(RatingDecoder).decode(data)
    if (result.ok) {
      return identity<Rating>(result.value)
    } else {
      console.error(
        'GET /student/rating 2XX Rating\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/rating 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/rating 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/rating 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/rating 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentRating"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/rating XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getNewsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "page": number
    readonly "perPage": number
  }
}

export interface getNewsOk {
  readonly "data": readonly News[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getNewsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getNews (params: getNewsParams): Promise<getNewsOk> {
  const url = "/news"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly News[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(NewsDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly News[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /news 2XX {\n' +
        '  readonly "data": readonly News[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /news 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /news 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /news 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /news 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getNews"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /news XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentPortfolioSectionsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export interface getStudentPortfolioSectionsOk {
  readonly "data": readonly Section[]
}

export type getStudentPortfolioSectionsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentPortfolioSections (params: getStudentPortfolioSectionsParams): Promise<getStudentPortfolioSectionsOk> {
  const url = "/student/portfolio/sections"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly Section[]
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(SectionDecoder),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly Section[]
      }>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections 2XX {\n' +
        '  readonly "data": readonly Section[]\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentPortfolioSections"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerPortfolioSectionsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export interface getLecturerPortfolioSectionsOk {
  readonly "data": readonly Section[]
}

export type getLecturerPortfolioSectionsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerPortfolioSections (params: getLecturerPortfolioSectionsParams): Promise<getLecturerPortfolioSectionsOk> {
  const url = "/lecturer/portfolio/sections"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly Section[]
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(SectionDecoder),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly Section[]
      }>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections 2XX {\n' +
        '  readonly "data": readonly Section[]\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerPortfolioSections"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentPortfolioSectionParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getStudentPortfolioSectionOk = Section

export type getStudentPortfolioSectionErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentPortfolioSection (params: getStudentPortfolioSectionParams): Promise<getStudentPortfolioSectionOk> {
  let url = "/student/portfolio/sections/"
  url += params.args["id"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Section
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(SectionDecoder).decode(data)
    if (result.ok) {
      return identity<Section>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 2XX Section\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentPortfolioSection"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerPortfolioSectionParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getLecturerPortfolioSectionOk = Section

export type getLecturerPortfolioSectionErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerPortfolioSection (params: getLecturerPortfolioSectionParams): Promise<getLecturerPortfolioSectionOk> {
  let url = "/lecturer/portfolio/sections/"
  url += params.args["id"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Section
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(SectionDecoder).decode(data)
    if (result.ok) {
      return identity<Section>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 2XX Section\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerPortfolioSection"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentPortfolioSectionWorksParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export interface getStudentPortfolioSectionWorksOk {
  readonly "data": readonly StudentPortfolioUserWork[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getStudentPortfolioSectionWorksErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentPortfolioSectionWorks (params: getStudentPortfolioSectionWorksParams): Promise<getStudentPortfolioSectionWorksOk> {
  let url = "/student/portfolio/sections/"
  url += params.args["id"]
  url += "/works"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly StudentPortfolioUserWork[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(StudentPortfolioUserWorkDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly StudentPortfolioUserWork[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 2XX {\n' +
        '  readonly "data": readonly StudentPortfolioUserWork[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentPortfolioSectionWorks"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerPortfolioSectionCasesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export interface getLecturerPortfolioSectionCasesOk {
  readonly "data": readonly LecturerPortfolioUserCase[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getLecturerPortfolioSectionCasesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerPortfolioSectionCases (params: getLecturerPortfolioSectionCasesParams): Promise<getLecturerPortfolioSectionCasesOk> {
  let url = "/lecturer/portfolio/sections/"
  url += params.args["id"]
  url += "/works"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly LecturerPortfolioUserCase[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(LecturerPortfolioUserCaseDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly LecturerPortfolioUserCase[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 2XX {\n' +
        '  readonly "data": readonly LecturerPortfolioUserCase[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerPortfolioSectionCases"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentPortfolioSectionWorkParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "SectionId": string
    readonly "id": string
  }
}

export type getStudentPortfolioSectionWorkOk = LecturerPortfolioUserCase

export type getStudentPortfolioSectionWorkErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentPortfolioSectionWork (params: getStudentPortfolioSectionWorkParams): Promise<getStudentPortfolioSectionWorkOk> {
  let url = "/student/portfolio/sections/"
  url += params.args["SectionId"]
  url += "/works/"
  url += params.args["id"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX LecturerPortfolioUserCase
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(LecturerPortfolioUserCaseDecoder).decode(data)
    if (result.ok) {
      return identity<LecturerPortfolioUserCase>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 2XX LecturerPortfolioUserCase\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentPortfolioSectionWork"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerPortfolioSectionCaseParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "SectionId": string
    readonly "id": string
  }
}

export type getLecturerPortfolioSectionCaseOk = LecturerPortfolioUserCase

export type getLecturerPortfolioSectionCaseErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerPortfolioSectionCase (params: getLecturerPortfolioSectionCaseParams): Promise<getLecturerPortfolioSectionCaseOk> {
  let url = "/lecturer/portfolio/sections/"
  url += params.args["SectionId"]
  url += "/works/"
  url += params.args["id"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX LecturerPortfolioUserCase
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(LecturerPortfolioUserCaseDecoder).decode(data)
    if (result.ok) {
      return identity<LecturerPortfolioUserCase>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 2XX LecturerPortfolioUserCase\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerPortfolioSectionCase"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentPortfolioMentrosParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getStudentPortfolioMentrosOk = readonly User[]

export type getStudentPortfolioMentrosErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentPortfolioMentros (params: getStudentPortfolioMentrosParams): Promise<getStudentPortfolioMentrosOk> {
  let url = "/student/portfolio/sections/"
  url += params.args["id"]
  url += "/lecturers"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly User[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(UserDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly User[]>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 2XX readonly User[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentPortfolioMentros"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentPortfolioCompetenciesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export interface getStudentPortfolioCompetenciesOk {
  readonly "data": readonly PortfolioSectionCompetency[]
}

export type getStudentPortfolioCompetenciesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentPortfolioCompetencies (params: getStudentPortfolioCompetenciesParams): Promise<getStudentPortfolioCompetenciesOk> {
  let url = "/student/portfolio/sections/"
  url += params.args["id"]
  url += "/competencies"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly PortfolioSectionCompetency[]
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(PortfolioSectionCompetencyDecoder),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly PortfolioSectionCompetency[]
      }>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 2XX {\n' +
        '  readonly "data": readonly PortfolioSectionCompetency[]\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentPortfolioCompetencies"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/portfolio/sections/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerPortfolioSectionCompetenciesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export interface getLecturerPortfolioSectionCompetenciesOk {
  readonly "data": readonly PortfolioSectionCompetency[]
}

export type getLecturerPortfolioSectionCompetenciesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerPortfolioSectionCompetencies (params: getLecturerPortfolioSectionCompetenciesParams): Promise<getLecturerPortfolioSectionCompetenciesOk> {
  let url = "/lecturer/portfolio/sections/"
  url += params.args["id"]
  url += "/competencies"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly PortfolioSectionCompetency[]
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(PortfolioSectionCompetencyDecoder),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly PortfolioSectionCompetency[]
      }>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 2XX {\n' +
        '  readonly "data": readonly PortfolioSectionCompetency[]\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerPortfolioSectionCompetencies"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/portfolio/sections/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentPortfolioSectionsWorksParams {
  readonly "body": {
    readonly "mentorId": IDString
    readonly "text": IDString
    readonly "competencies": readonly number[]
    readonly "files": readonly string[]
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type postStudentPortfolioSectionsWorksOk = null

export type postStudentPortfolioSectionsWorksErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentPortfolioSectionsWorks (params: postStudentPortfolioSectionsWorksParams): Promise<postStudentPortfolioSectionsWorksOk> {
  let url = "/student/portfolio/sections/"
  url += params.args["id"]
  url += "/works"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "mentorId": params.body["mentorId"],
      "text": params.body["text"],
      "competencies": params.body["competencies"],
      "files": params.body["files"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /student/portfolio/sections/ 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/portfolio/sections/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/portfolio/sections/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/portfolio/sections/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/portfolio/sections/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentPortfolioSectionsWorks"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/portfolio/sections/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentProgramsStateParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentProgramsStateOk = StudentProgramState

export type getStudentProgramsStateErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentProgramsState (params: getStudentProgramsStateParams): Promise<getStudentProgramsStateOk> {
  const url = "/student/programs/state"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX StudentProgramState
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(StudentProgramStateDecoder).decode(data)
    if (result.ok) {
      return identity<StudentProgramState>(result.value)
    } else {
      console.error(
        'GET /student/programs/state 2XX StudentProgramState\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/programs/state 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/programs/state 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/programs/state 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/programs/state 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentProgramsState"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/programs/state XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getSingleNewsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "newsId": string
  }
}

export type getSingleNewsOk = News

export type getSingleNewsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getSingleNews (params: getSingleNewsParams): Promise<getSingleNewsOk> {
  let url = "/news/"
  url += params.args["newsId"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX News
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(NewsDecoder).decode(data)
    if (result.ok) {
      return identity<News>(result.value)
    } else {
      console.error(
        'GET /news/ 2XX News\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /news/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /news/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /news/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /news/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getSingleNews"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /news/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getNewsUnreadCountParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getNewsUnreadCountOk = NewsUnreadCount

export type getNewsUnreadCountErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getNewsUnreadCount (params: getNewsUnreadCountParams): Promise<getNewsUnreadCountOk> {
  const url = "/news/unread-count"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX NewsUnreadCount
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(NewsUnreadCountDecoder).decode(data)
    if (result.ok) {
      return identity<NewsUnreadCount>(result.value)
    } else {
      console.error(
        'GET /news/unread-count 2XX NewsUnreadCount\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /news/unread-count 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /news/unread-count 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /news/unread-count 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /news/unread-count 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getNewsUnreadCount"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /news/unread-count XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getMediaFilesDownloadParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getMediaFilesDownloadOk = DownloadMediaFile

export type getMediaFilesDownloadErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getMediaFilesDownload (params: getMediaFilesDownloadParams): Promise<getMediaFilesDownloadOk> {
  let url = "/media-files/"
  url += params.args["id"]
  url += "/download"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX DownloadMediaFile
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(DownloadMediaFileDecoder).decode(data)
    if (result.ok) {
      return identity<DownloadMediaFile>(result.value)
    } else {
      console.error(
        'GET /media-files/ 2XX DownloadMediaFile\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /media-files/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /media-files/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /media-files/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /media-files/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getMediaFilesDownload"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /media-files/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getRegistrationFilesDownloadParams {
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getRegistrationFilesDownloadOk = DownloadMediaFile

export type getRegistrationFilesDownloadErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getRegistrationFilesDownload (params: getRegistrationFilesDownloadParams): Promise<getRegistrationFilesDownloadOk> {
  let url = "/registration/download/"
  url += params.args["id"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX DownloadMediaFile
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(DownloadMediaFileDecoder).decode(data)
    if (result.ok) {
      return identity<DownloadMediaFile>(result.value)
    } else {
      console.error(
        'GET /registration/download/ 2XX DownloadMediaFile\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /registration/download/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /registration/download/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /registration/download/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /registration/download/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getRegistrationFilesDownload"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /registration/download/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentFacultiesMinorsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentFacultiesMinorsOk = readonly Faculty[]

export type getStudentFacultiesMinorsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentFacultiesMinors (params: getStudentFacultiesMinorsParams): Promise<getStudentFacultiesMinorsOk> {
  const url = "/student/faculties/minors"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Faculty[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(FacultyDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Faculty[]>(result.value)
    } else {
      console.error(
        'GET /student/faculties/minors 2XX readonly Faculty[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/faculties/minors 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/faculties/minors 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/faculties/minors 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/faculties/minors 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentFacultiesMinors"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/faculties/minors XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentProgramsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "filters[type]": string
  }
}

export type getStudentProgramsOk = readonly Program[]

export type getStudentProgramsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentPrograms (params: getStudentProgramsParams): Promise<getStudentProgramsOk> {
  const url = "/student/programs"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Program[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(ProgramDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Program[]>(result.value)
    } else {
      console.error(
        'GET /student/programs 2XX readonly Program[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/programs 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/programs 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/programs 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/programs 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentPrograms"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/programs XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentProgramParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getStudentProgramOk = Program

export type getStudentProgramErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentProgram (params: getStudentProgramParams): Promise<getStudentProgramOk> {
  let url = "/student/programs/"
  url += params.args["id"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Program
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ProgramDecoder).decode(data)
    if (result.ok) {
      return identity<Program>(result.value)
    } else {
      console.error(
        'GET /student/programs/ 2XX Program\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/programs/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/programs/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/programs/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/programs/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentProgram"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/programs/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentFacultiesMinorProgramsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getStudentFacultiesMinorProgramsOk = readonly Program[]

export type getStudentFacultiesMinorProgramsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentFacultiesMinorPrograms (params: getStudentFacultiesMinorProgramsParams): Promise<getStudentFacultiesMinorProgramsOk> {
  let url = "/student/faculties/"
  url += params.args["id"]
  url += "/minor-programs"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Program[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(ProgramDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Program[]>(result.value)
    } else {
      console.error(
        'GET /student/faculties/ 2XX readonly Program[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/faculties/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/faculties/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/faculties/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/faculties/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentFacultiesMinorPrograms"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/faculties/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface patchStudentProgramsStateParams {
  readonly "body": {
    readonly "customProgramId": IDString
    readonly "minorFacultyId": IDString
    readonly "minorProgramId": IDString
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type patchStudentProgramsStateOk = null

export type patchStudentProgramsStateErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function patchStudentProgramsState (params: patchStudentProgramsStateParams): Promise<patchStudentProgramsStateOk> {
  const url = "/student/programs/state"
  const response = await fetch(baseURL + url, {
    method: "PATCH",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "customProgramId": params.body["customProgramId"],
      "minorFacultyId": params.body["minorFacultyId"],
      "minorProgramId": params.body["minorProgramId"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'PATCH /student/programs/state 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'PATCH /student/programs/state 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'PATCH /student/programs/state 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'PATCH /student/programs/state 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'PATCH /student/programs/state 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("patchStudentProgramsState"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'PATCH /student/programs/state XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentRecommendationsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "page": number
    readonly "perPage": number
  }
}

export interface getStudentRecommendationsOk {
  readonly "data": readonly Recommendation[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getStudentRecommendationsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentRecommendations (params: getStudentRecommendationsParams): Promise<getStudentRecommendationsOk> {
  const url = "/student/recommendations"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly Recommendation[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(RecommendationDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly Recommendation[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /student/recommendations 2XX {\n' +
        '  readonly "data": readonly Recommendation[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/recommendations 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/recommendations 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/recommendations 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/recommendations 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentRecommendations"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/recommendations XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getNotificationsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "page": number
    readonly "perPage": number
  }
}

export interface getNotificationsOk {
  readonly "data": readonly Notification[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getNotificationsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getNotifications (params: getNotificationsParams): Promise<getNotificationsOk> {
  const url = "/notifications"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly Notification[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(NotificationDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly Notification[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /notifications 2XX {\n' +
        '  readonly "data": readonly Notification[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /notifications 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /notifications 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /notifications 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /notifications 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getNotifications"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /notifications XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface patchNotificationsMarkAllAsReadParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type patchNotificationsMarkAllAsReadOk = null

export type patchNotificationsMarkAllAsReadErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function patchNotificationsMarkAllAsRead (params: patchNotificationsMarkAllAsReadParams): Promise<patchNotificationsMarkAllAsReadOk> {
  const url = "/notifications/mark-all-as-read"
  const response = await fetch(baseURL + url, {
    method: "PATCH",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'PATCH /notifications/mark-all-as-read 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'PATCH /notifications/mark-all-as-read 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'PATCH /notifications/mark-all-as-read 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'PATCH /notifications/mark-all-as-read 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'PATCH /notifications/mark-all-as-read 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("patchNotificationsMarkAllAsRead"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'PATCH /notifications/mark-all-as-read XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface patchNotificationsMarkAsReadParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "uuid": string
  }
}

export type patchNotificationsMarkAsReadOk = null

export type patchNotificationsMarkAsReadErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function patchNotificationsMarkAsRead (params: patchNotificationsMarkAsReadParams): Promise<patchNotificationsMarkAsReadOk> {
  let url = "/notifications/"
  url += params.args["uuid"]
  url += "/mark-as-read"
  const response = await fetch(baseURL + url, {
    method: "PATCH",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'PATCH /notifications/ 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'PATCH /notifications/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'PATCH /notifications/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'PATCH /notifications/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'PATCH /notifications/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("patchNotificationsMarkAsRead"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'PATCH /notifications/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentRecommendationParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getStudentRecommendationOk = Recommendation

export type getStudentRecommendationErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentRecommendation (params: getStudentRecommendationParams): Promise<getStudentRecommendationOk> {
  let url = "/student/recommendations/"
  url += params.args["id"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Recommendation
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(RecommendationDecoder).decode(data)
    if (result.ok) {
      return identity<Recommendation>(result.value)
    } else {
      console.error(
        'GET /student/recommendations/ 2XX Recommendation\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/recommendations/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/recommendations/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/recommendations/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/recommendations/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentRecommendation"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/recommendations/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentRecommendationsRequestParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "user_id": IDString
  }
}

export type postStudentRecommendationsRequestOk = null

export type postStudentRecommendationsRequestErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentRecommendationsRequest (params: postStudentRecommendationsRequestParams): Promise<postStudentRecommendationsRequestOk> {
  let url = "/student/recommendations/"
  url += params.args["user_id"]
  url += "/request"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /student/recommendations/ 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/recommendations/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/recommendations/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/recommendations/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/recommendations/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentRecommendationsRequest"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/recommendations/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentRecommendationsExportParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": IDString
  }
}

export type getStudentRecommendationsExportOk = ExportFile

export type getStudentRecommendationsExportErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentRecommendationsExport (params: getStudentRecommendationsExportParams): Promise<getStudentRecommendationsExportOk> {
  let url = "/student/recommendations/"
  url += params.args["id"]
  url += "/export"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX ExportFile
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ExportFileDecoder).decode(data)
    if (result.ok) {
      return identity<ExportFile>(result.value)
    } else {
      console.error(
        'GET /student/recommendations/ 2XX ExportFile\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/recommendations/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/recommendations/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/recommendations/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/recommendations/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentRecommendationsExport"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/recommendations/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentResumeParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentResumeOk = Resume

export type getStudentResumeErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentResume (params: getStudentResumeParams): Promise<getStudentResumeOk> {
  const url = "/student/resume"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Resume
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ResumeDecoder).decode(data)
    if (result.ok) {
      return identity<Resume>(result.value)
    } else {
      console.error(
        'GET /student/resume 2XX Resume\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/resume 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/resume 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/resume 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/resume 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentResume"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/resume XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentResumePersonalParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentResumePersonalOk = ResumePersonal

export type getStudentResumePersonalErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentResumePersonal (params: getStudentResumePersonalParams): Promise<getStudentResumePersonalOk> {
  const url = "/student/resume/personal"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX ResumePersonal
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ResumePersonalDecoder).decode(data)
    if (result.ok) {
      return identity<ResumePersonal>(result.value)
    } else {
      console.error(
        'GET /student/resume/personal 2XX ResumePersonal\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/resume/personal 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/resume/personal 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/resume/personal 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/resume/personal 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentResumePersonal"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/resume/personal XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentResumeWorksParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentResumeWorksOk = readonly ResumeWork[]

export type getStudentResumeWorksErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentResumeWorks (params: getStudentResumeWorksParams): Promise<getStudentResumeWorksOk> {
  const url = "/student/resume/works"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly ResumeWork[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(ResumeWorkDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly ResumeWork[]>(result.value)
    } else {
      console.error(
        'GET /student/resume/works 2XX readonly ResumeWork[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/resume/works 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/resume/works 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/resume/works 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/resume/works 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentResumeWorks"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/resume/works XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentResumeTrainingsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentResumeTrainingsOk = readonly ResumeTraining[]

export type getStudentResumeTrainingsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentResumeTrainings (params: getStudentResumeTrainingsParams): Promise<getStudentResumeTrainingsOk> {
  const url = "/student/resume/trainings"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly ResumeTraining[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(ResumeTrainingDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly ResumeTraining[]>(result.value)
    } else {
      console.error(
        'GET /student/resume/trainings 2XX readonly ResumeTraining[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/resume/trainings 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/resume/trainings 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/resume/trainings 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/resume/trainings 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentResumeTrainings"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/resume/trainings XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentResumeSkillsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentResumeSkillsOk = ResumeSkill

export type getStudentResumeSkillsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentResumeSkills (params: getStudentResumeSkillsParams): Promise<getStudentResumeSkillsOk> {
  const url = "/student/resume/skills"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX ResumeSkill
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ResumeSkillDecoder).decode(data)
    if (result.ok) {
      return identity<ResumeSkill>(result.value)
    } else {
      console.error(
        'GET /student/resume/skills 2XX ResumeSkill\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/resume/skills 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/resume/skills 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/resume/skills 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/resume/skills 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentResumeSkills"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/resume/skills XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentResumeEducationsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentResumeEducationsOk = readonly ResumeEducation[]

export type getStudentResumeEducationsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentResumeEducations (params: getStudentResumeEducationsParams): Promise<getStudentResumeEducationsOk> {
  const url = "/student/resume/educations"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly ResumeEducation[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(ResumeEducationDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly ResumeEducation[]>(result.value)
    } else {
      console.error(
        'GET /student/resume/educations 2XX readonly ResumeEducation[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/resume/educations 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/resume/educations 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/resume/educations 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/resume/educations 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentResumeEducations"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/resume/educations XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentResumeAdditionalParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentResumeAdditionalOk = ResumeAdditional

export type getStudentResumeAdditionalErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentResumeAdditional (params: getStudentResumeAdditionalParams): Promise<getStudentResumeAdditionalOk> {
  const url = "/student/resume/additional"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX ResumeAdditional
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ResumeAdditionalDecoder).decode(data)
    if (result.ok) {
      return identity<ResumeAdditional>(result.value)
    } else {
      console.error(
        'GET /student/resume/additional 2XX ResumeAdditional\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/resume/additional 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/resume/additional 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/resume/additional 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/resume/additional 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentResumeAdditional"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/resume/additional XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentResumeUniversitiesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentResumeUniversitiesOk = readonly University[]

export type getStudentResumeUniversitiesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentResumeUniversities (params: getStudentResumeUniversitiesParams): Promise<getStudentResumeUniversitiesOk> {
  const url = "/student/resume/universities"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly University[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(UniversityDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly University[]>(result.value)
    } else {
      console.error(
        'GET /student/resume/universities 2XX readonly University[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/resume/universities 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/resume/universities 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/resume/universities 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/resume/universities 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentResumeUniversities"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/resume/universities XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentResumeLanguagesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentResumeLanguagesOk = readonly ResumeLanguage[]

export type getStudentResumeLanguagesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentResumeLanguages (params: getStudentResumeLanguagesParams): Promise<getStudentResumeLanguagesOk> {
  const url = "/student/resume/languages"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly ResumeLanguage[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(ResumeLanguageDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly ResumeLanguage[]>(result.value)
    } else {
      console.error(
        'GET /student/resume/languages 2XX readonly ResumeLanguage[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/resume/languages 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/resume/languages 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/resume/languages 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/resume/languages 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentResumeLanguages"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/resume/languages XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentResumePublicationTypesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentResumePublicationTypesOk = readonly ResumePublicationType[]

export type getStudentResumePublicationTypesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentResumePublicationTypes (params: getStudentResumePublicationTypesParams): Promise<getStudentResumePublicationTypesOk> {
  const url = "/student/resume/publication-types"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly ResumePublicationType[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(ResumePublicationTypeDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly ResumePublicationType[]>(result.value)
    } else {
      console.error(
        'GET /student/resume/publication-types 2XX readonly ResumePublicationType[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/resume/publication-types 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/resume/publication-types 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/resume/publication-types 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/resume/publication-types 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentResumePublicationTypes"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/resume/publication-types XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentResumeGrantTypesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentResumeGrantTypesOk = readonly ResumeGrantType[]

export type getStudentResumeGrantTypesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentResumeGrantTypes (params: getStudentResumeGrantTypesParams): Promise<getStudentResumeGrantTypesOk> {
  const url = "/student/resume/grant-types"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly ResumeGrantType[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(ResumeGrantTypeDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly ResumeGrantType[]>(result.value)
    } else {
      console.error(
        'GET /student/resume/grant-types 2XX readonly ResumeGrantType[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/resume/grant-types 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/resume/grant-types 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/resume/grant-types 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/resume/grant-types 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentResumeGrantTypes"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/resume/grant-types XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentResumeCitiesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "filters": {
      readonly "countryId": IDString
    }
  }
}

export type getStudentResumeCitiesOk = readonly ResumeCity[]

export type getStudentResumeCitiesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentResumeCities (params: getStudentResumeCitiesParams): Promise<getStudentResumeCitiesOk> {
  const url = "/student/resume/cities"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly ResumeCity[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(ResumeCityDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly ResumeCity[]>(result.value)
    } else {
      console.error(
        'GET /student/resume/cities 2XX readonly ResumeCity[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/resume/cities 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/resume/cities 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/resume/cities 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/resume/cities 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentResumeCities"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/resume/cities XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentResumeExportParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentResumeExportOk = ExportFile

export type getStudentResumeExportErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentResumeExport (params: getStudentResumeExportParams): Promise<getStudentResumeExportOk> {
  const url = "/student/resume/export"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX ExportFile
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ExportFileDecoder).decode(data)
    if (result.ok) {
      return identity<ExportFile>(result.value)
    } else {
      console.error(
        'GET /student/resume/export 2XX ExportFile\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/resume/export 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/resume/export 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/resume/export 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/resume/export 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentResumeExport"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/resume/export XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentResumePersonalParams {
  readonly "body": {
    readonly "photoTitle": string
    readonly "address": string
    readonly "homePhone": string
    readonly "mobilePhone": string
    readonly "photoUid": null | string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type postStudentResumePersonalOk = null

export type postStudentResumePersonalErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentResumePersonal (params: postStudentResumePersonalParams): Promise<postStudentResumePersonalOk> {
  const url = "/student/resume/personal"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "photoTitle": params.body["photoTitle"],
      "address": params.body["address"],
      "homePhone": params.body["homePhone"],
      "mobilePhone": params.body["mobilePhone"],
      "photoUid": params.body["photoUid"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /student/resume/personal 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/resume/personal 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/resume/personal 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/resume/personal 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/resume/personal 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentResumePersonal"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/resume/personal XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentResumeWorksParams {
  readonly "body": {
    readonly "data": readonly UpdateResumeWorkRequest[]
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type postStudentResumeWorksOk = null

export type postStudentResumeWorksErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentResumeWorks (params: postStudentResumeWorksParams): Promise<postStudentResumeWorksOk> {
  const url = "/student/resume/works"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "data": params.body["data"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /student/resume/works 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/resume/works 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/resume/works 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/resume/works 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/resume/works 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentResumeWorks"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/resume/works XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentResumeTrainingsParams {
  readonly "body": {
    readonly "data": readonly UpdateResumeTrainingRequest[]
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type postStudentResumeTrainingsOk = null

export type postStudentResumeTrainingsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentResumeTrainings (params: postStudentResumeTrainingsParams): Promise<postStudentResumeTrainingsOk> {
  const url = "/student/resume/trainings"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "data": params.body["data"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /student/resume/trainings 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/resume/trainings 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/resume/trainings 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/resume/trainings 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/resume/trainings 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentResumeTrainings"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/resume/trainings XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentResumeSkillsParams {
  readonly "body": {
    readonly "hasDriverLicence": boolean
    readonly "languages": readonly UpdateResumeSkillLanguageRequest[]
    readonly "communications": readonly UpdateResumeSkillCommunicationRequest[]
    readonly "computers": readonly UpdateResumeSkillComputerRequest[]
    readonly "organisationals": readonly UpdateResumeSkillOrganisationalRequest[]
    readonly "others": readonly UpdateResumeSkillOtherRequest[]
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type postStudentResumeSkillsOk = null

export type postStudentResumeSkillsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentResumeSkills (params: postStudentResumeSkillsParams): Promise<postStudentResumeSkillsOk> {
  const url = "/student/resume/skills"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "hasDriverLicence": params.body["hasDriverLicence"],
      "languages": params.body["languages"],
      "communications": params.body["communications"],
      "computers": params.body["computers"],
      "organisationals": params.body["organisationals"],
      "others": params.body["others"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /student/resume/skills 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/resume/skills 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/resume/skills 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/resume/skills 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/resume/skills 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentResumeSkills"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/resume/skills XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentResumeEducationsParams {
  readonly "body": {
    readonly "data": readonly UpdateResumeEducationRequest[]
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type postStudentResumeEducationsOk = null

export type postStudentResumeEducationsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentResumeEducations (params: postStudentResumeEducationsParams): Promise<postStudentResumeEducationsOk> {
  const url = "/student/resume/educations"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "data": params.body["data"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /student/resume/educations 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/resume/educations 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/resume/educations 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/resume/educations 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/resume/educations 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentResumeEducations"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/resume/educations XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentResumeAdditionalParams {
  readonly "body": {
    readonly "info": null | string
    readonly "publications": readonly UpdateResumeAdditionalPublicationRequest[]
    readonly "fellowships": readonly UpdateResumeAdditionalFellowshipRequest[]
    readonly "subjects": readonly UpdateResumeAdditionalSubjectRequest[]
    readonly "videoLectures": readonly UpdateResumeAdditionalVideoLectureRequest[]
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type postStudentResumeAdditionalOk = null

export type postStudentResumeAdditionalErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentResumeAdditional (params: postStudentResumeAdditionalParams): Promise<postStudentResumeAdditionalOk> {
  const url = "/student/resume/additional"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "info": params.body["info"],
      "publications": params.body["publications"],
      "fellowships": params.body["fellowships"],
      "subjects": params.body["subjects"],
      "videoLectures": params.body["videoLectures"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /student/resume/additional 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/resume/additional 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/resume/additional 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/resume/additional 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/resume/additional 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentResumeAdditional"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/resume/additional XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface patchStudentResumeTabsParams {
  readonly "body": {
    readonly "visibility": boolean
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "ResumeTab": ResumeTab
  }
}

export type patchStudentResumeTabsOk = null

export type patchStudentResumeTabsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function patchStudentResumeTabs (params: patchStudentResumeTabsParams): Promise<patchStudentResumeTabsOk> {
  let url = "/student/resume/tabs/"
  url += params.args["ResumeTab"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "PATCH",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "visibility": params.body["visibility"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'PATCH /student/resume/tabs/ 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'PATCH /student/resume/tabs/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'PATCH /student/resume/tabs/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'PATCH /student/resume/tabs/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'PATCH /student/resume/tabs/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("patchStudentResumeTabs"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'PATCH /student/resume/tabs/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentAvailableTabsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentAvailableTabsOk = readonly AvailableCourseTab[]

export type getStudentAvailableTabsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentAvailableTabs (params: getStudentAvailableTabsParams): Promise<getStudentAvailableTabsOk> {
  const url = "/student/available/tabs"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly AvailableCourseTab[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(AvailableCourseTabDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly AvailableCourseTab[]>(result.value)
    } else {
      console.error(
        'GET /student/available/tabs 2XX readonly AvailableCourseTab[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/available/tabs 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/available/tabs 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/available/tabs 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/available/tabs 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentAvailableTabs"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/available/tabs XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentAvailableChoicesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentAvailableChoicesOk = AvailableChoiceSummary

export type getStudentAvailableChoicesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentAvailableChoices (params: getStudentAvailableChoicesParams): Promise<getStudentAvailableChoicesOk> {
  const url = "/student/available/choices"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX AvailableChoiceSummary
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(AvailableChoiceSummaryDecoder).decode(data)
    if (result.ok) {
      return identity<AvailableChoiceSummary>(result.value)
    } else {
      console.error(
        'GET /student/available/choices 2XX AvailableChoiceSummary\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/available/choices 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/available/choices 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/available/choices 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/available/choices 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentAvailableChoices"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/available/choices XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentAvailableFreeChoicesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentAvailableFreeChoicesOk = AvailableChoiceSummary

export type getStudentAvailableFreeChoicesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentAvailableFreeChoices (params: getStudentAvailableFreeChoicesParams): Promise<getStudentAvailableFreeChoicesOk> {
  const url = "/student/available/free/choices"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX AvailableChoiceSummary
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(AvailableChoiceSummaryDecoder).decode(data)
    if (result.ok) {
      return identity<AvailableChoiceSummary>(result.value)
    } else {
      console.error(
        'GET /student/available/free/choices 2XX AvailableChoiceSummary\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/available/free/choices 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/available/free/choices 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/available/free/choices 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/available/free/choices 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentAvailableFreeChoices"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/available/free/choices XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentAvailableGeneralCoursesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentAvailableGeneralCoursesOk = AvailableCourseSummary

export type getStudentAvailableGeneralCoursesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentAvailableGeneralCourses (params: getStudentAvailableGeneralCoursesParams): Promise<getStudentAvailableGeneralCoursesOk> {
  const url = "/student/available/general/courses"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX AvailableCourseSummary
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(AvailableCourseSummaryDecoder).decode(data)
    if (result.ok) {
      return identity<AvailableCourseSummary>(result.value)
    } else {
      console.error(
        'GET /student/available/general/courses 2XX AvailableCourseSummary\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/available/general/courses 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/available/general/courses 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/available/general/courses 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/available/general/courses 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentAvailableGeneralCourses"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/available/general/courses XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentAvailableGeneralChoicesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentAvailableGeneralChoicesOk = AvailableChoiceSummary

export type getStudentAvailableGeneralChoicesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentAvailableGeneralChoices (params: getStudentAvailableGeneralChoicesParams): Promise<getStudentAvailableGeneralChoicesOk> {
  const url = "/student/available/general/choices"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX AvailableChoiceSummary
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(AvailableChoiceSummaryDecoder).decode(data)
    if (result.ok) {
      return identity<AvailableChoiceSummary>(result.value)
    } else {
      console.error(
        'GET /student/available/general/choices 2XX AvailableChoiceSummary\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/available/general/choices 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/available/general/choices 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/available/general/choices 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/available/general/choices 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentAvailableGeneralChoices"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/available/general/choices XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentAvailableProgramsCoursesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getStudentAvailableProgramsCoursesOk = AvailableCourseSummary

export type getStudentAvailableProgramsCoursesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentAvailableProgramsCourses (params: getStudentAvailableProgramsCoursesParams): Promise<getStudentAvailableProgramsCoursesOk> {
  let url = "/student/available/programs/"
  url += params.args["id"]
  url += "/courses"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX AvailableCourseSummary
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(AvailableCourseSummaryDecoder).decode(data)
    if (result.ok) {
      return identity<AvailableCourseSummary>(result.value)
    } else {
      console.error(
        'GET /student/available/programs/ 2XX AvailableCourseSummary\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/available/programs/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/available/programs/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/available/programs/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/available/programs/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentAvailableProgramsCourses"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/available/programs/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentAvailableProgramsChoicesParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getStudentAvailableProgramsChoicesOk = AvailableChoiceSummary

export type getStudentAvailableProgramsChoicesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentAvailableProgramsChoices (params: getStudentAvailableProgramsChoicesParams): Promise<getStudentAvailableProgramsChoicesOk> {
  let url = "/student/available/programs/"
  url += params.args["id"]
  url += "/choices"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX AvailableChoiceSummary
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(AvailableChoiceSummaryDecoder).decode(data)
    if (result.ok) {
      return identity<AvailableChoiceSummary>(result.value)
    } else {
      console.error(
        'GET /student/available/programs/ 2XX AvailableChoiceSummary\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/available/programs/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/available/programs/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/available/programs/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/available/programs/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentAvailableProgramsChoices"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/available/programs/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentFacultiesProgramsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentFacultiesProgramsOk = readonly Faculty[]

export type getStudentFacultiesProgramsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentFacultiesPrograms (params: getStudentFacultiesProgramsParams): Promise<getStudentFacultiesProgramsOk> {
  const url = "/student/faculties/programs"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Faculty[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(decoders.lazy(() => FacultyDecoder))).decode(data)
    if (result.ok) {
      return identity<readonly Faculty[]>(result.value)
    } else {
      console.error(
        'GET /student/faculties/programs 2XX readonly Faculty[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/faculties/programs 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/faculties/programs 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/faculties/programs 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/faculties/programs 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentFacultiesPrograms"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/faculties/programs XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentLecturersCurrentParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentLecturersCurrentOk = readonly User[]

export type getStudentLecturersCurrentErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentLecturersCurrent (params: getStudentLecturersCurrentParams): Promise<getStudentLecturersCurrentOk> {
  const url = "/student/lecturers/current"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly User[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(UserDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly User[]>(result.value)
    } else {
      console.error(
        'GET /student/lecturers/current 2XX readonly User[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/lecturers/current 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/lecturers/current 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/lecturers/current 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/lecturers/current 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentLecturersCurrent"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/lecturers/current XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentFeedbacksSubjectsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentFeedbacksSubjectsOk = readonly FeedbackSubject[]

export type getStudentFeedbacksSubjectsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentFeedbacksSubjects (params: getStudentFeedbacksSubjectsParams): Promise<getStudentFeedbacksSubjectsOk> {
  const url = "/student/feedbacks/subjects"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly FeedbackSubject[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(FeedbackSubjectDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly FeedbackSubject[]>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/subjects 2XX readonly FeedbackSubject[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/subjects 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/subjects 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/subjects 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/subjects 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentFeedbacksSubjects"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/subjects XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentFeedbacksParams {
  readonly "body": {
    readonly "subjectId": IDString
    readonly "text": IDString
    readonly "files": readonly string[]
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type postStudentFeedbacksOk = null

export type postStudentFeedbacksErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentFeedbacks (params: postStudentFeedbacksParams): Promise<postStudentFeedbacksOk> {
  const url = "/student/feedbacks"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "subjectId": params.body["subjectId"],
      "text": params.body["text"],
      "files": params.body["files"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /student/feedbacks 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/feedbacks 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/feedbacks 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/feedbacks 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/feedbacks 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentFeedbacks"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/feedbacks XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentFeedbackParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "id": IDString
  }
}

export type getStudentFeedbackOk = Feedback

export type getStudentFeedbackErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentFeedback (params: getStudentFeedbackParams): Promise<getStudentFeedbackOk> {
  let url = "/student/feedbacks/"
  url += params.args["id"]
  url += ""
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Feedback
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(FeedbackDecoder).decode(data)
    if (result.ok) {
      return identity<Feedback>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/ 2XX Feedback\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentFeedback"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentFeedbacksParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "page": number
    readonly "semId": string
  }
}

export interface getStudentFeedbacksOk {
  readonly "data": readonly Feedback[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getStudentFeedbacksErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentFeedbacks (params: getStudentFeedbacksParams): Promise<getStudentFeedbacksOk> {
  const url = "/student/feedbacks"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly Feedback[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(FeedbackDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly Feedback[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks 2XX {\n' +
        '  readonly "data": readonly Feedback[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentFeedbacks"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/feedbacks XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentChosenGroupParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getStudentChosenGroupOk = Group

export type getStudentChosenGroupErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentChosenGroup (params: getStudentChosenGroupParams): Promise<getStudentChosenGroupOk> {
  let url = "/student/courses/"
  url += params.args["id"]
  url += "/chosen-group"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Group
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(GroupDecoder).decode(data)
    if (result.ok) {
      return identity<Group>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 2XX Group\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentChosenGroup"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getCountriesParams {
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
}

export type getCountriesOk = readonly Country[]

export type getCountriesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getCountries (params: getCountriesParams): Promise<getCountriesOk> {
  const url = "/countries"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Country[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(decoders.lazy(() => CountryDecoder))).decode(data)
    if (result.ok) {
      return identity<readonly Country[]>(result.value)
    } else {
      console.error(
        'GET /countries 2XX readonly Country[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /countries 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /countries 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /countries 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /countries 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getCountries"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /countries XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getRegistrationAddressesParams {
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "query": string
  }
}

export type getRegistrationAddressesOk = readonly Address[]

export type getRegistrationAddressesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getRegistrationAddresses (params: getRegistrationAddressesParams): Promise<getRegistrationAddressesOk> {
  const url = "/registration/addresses"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Address[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(AddressDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Address[]>(result.value)
    } else {
      console.error(
        'GET /registration/addresses 2XX readonly Address[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /registration/addresses 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /registration/addresses 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /registration/addresses 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /registration/addresses 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getRegistrationAddresses"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /registration/addresses XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getRegistrationCoursesParams {
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
}

export type getRegistrationCoursesOk = readonly ExamCourse[]

export type getRegistrationCoursesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getRegistrationCourses (params: getRegistrationCoursesParams): Promise<getRegistrationCoursesOk> {
  const url = "/registration/courses"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly ExamCourse[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(ExamCourseDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly ExamCourse[]>(result.value)
    } else {
      console.error(
        'GET /registration/courses 2XX readonly ExamCourse[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /registration/courses 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /registration/courses 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /registration/courses 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /registration/courses 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getRegistrationCourses"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /registration/courses XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getRegistrationCheckStatusParams {
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "degree": string
  }
}

export type getRegistrationCheckStatusOk = RegistrationStatus

export type getRegistrationCheckStatusErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getRegistrationCheckStatus (params: getRegistrationCheckStatusParams): Promise<getRegistrationCheckStatusOk> {
  let url = "/registration/check-status/"
  url += params.args["degree"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX RegistrationStatus
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(RegistrationStatusDecoder).decode(data)
    if (result.ok) {
      return identity<RegistrationStatus>(result.value)
    } else {
      console.error(
        'GET /registration/check-status/ 2XX RegistrationStatus\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /registration/check-status/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /registration/check-status/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /registration/check-status/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /registration/check-status/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getRegistrationCheckStatus"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /registration/check-status/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getRegistrationUniversitiesParams {
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
}

export type getRegistrationUniversitiesOk = readonly University[]

export type getRegistrationUniversitiesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getRegistrationUniversities (params: getRegistrationUniversitiesParams): Promise<getRegistrationUniversitiesOk> {
  const url = "/registration/universities"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly University[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(UniversityDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly University[]>(result.value)
    } else {
      console.error(
        'GET /registration/universities 2XX readonly University[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /registration/universities 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /registration/universities 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /registration/universities 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /registration/universities 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getRegistrationUniversities"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /registration/universities XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getRegistrationTestTypesParams {
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
}

export type getRegistrationTestTypesOk = readonly University[]

export type getRegistrationTestTypesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getRegistrationTestTypes (params: getRegistrationTestTypesParams): Promise<getRegistrationTestTypesOk> {
  const url = "/registration/test-types"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly University[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(UniversityDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly University[]>(result.value)
    } else {
      console.error(
        'GET /registration/test-types 2XX readonly University[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /registration/test-types 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /registration/test-types 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /registration/test-types 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /registration/test-types 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getRegistrationTestTypes"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /registration/test-types XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getRegistrationFacultiesParams {
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
}

export type getRegistrationFacultiesOk = readonly Faculty[]

export type getRegistrationFacultiesErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getRegistrationFaculties (params: getRegistrationFacultiesParams): Promise<getRegistrationFacultiesOk> {
  const url = "/registration/faculties"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Faculty[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(FacultyDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Faculty[]>(result.value)
    } else {
      console.error(
        'GET /registration/faculties 2XX readonly Faculty[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /registration/faculties 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /registration/faculties 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /registration/faculties 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /registration/faculties 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getRegistrationFaculties"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /registration/faculties XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getRegistrationProgramsParams {
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "faculty_id": string
    readonly "testype_id": string
  }
}

export type getRegistrationProgramsOk = readonly Program[]

export type getRegistrationProgramsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getRegistrationPrograms (params: getRegistrationProgramsParams): Promise<getRegistrationProgramsOk> {
  let url = "/registration/programs/"
  url += params.args["faculty_id"]
  url += "/"
  url += params.args["testype_id"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly Program[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(ProgramDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly Program[]>(result.value)
    } else {
      console.error(
        'GET /registration/programs/ 2XX readonly Program[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /registration/programs/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /registration/programs/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /registration/programs/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /registration/programs/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getRegistrationPrograms"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /registration/programs/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postCheckPersonalNumberParams {
  readonly "body": {
    readonly "persNum": string
  }
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
}

export type postCheckPersonalNumberOk = never

export type postCheckPersonalNumberErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postCheckPersonalNumber (params: postCheckPersonalNumberParams): Promise<postCheckPersonalNumberOk> {
  const url = "/registration/check-pers-num"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "persNum": params.body["persNum"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /registration/check-pers-num 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /registration/check-pers-num 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /registration/check-pers-num 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /registration/check-pers-num 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postCheckPersonalNumber"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /registration/check-pers-num XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postMasterRegistrationParams {
  readonly "body": {
    readonly "step1": MasterRegistrationStep1
    readonly "step2": MasterRegistrationStep2
    readonly "step3": RegistrationStep3
  }
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
}

export type postMasterRegistrationOk = Registration

export type postMasterRegistrationErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postMasterRegistration (params: postMasterRegistrationParams): Promise<postMasterRegistrationOk> {
  const url = "/registration/masters"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "step1": params.body["step1"],
      "step2": params.body["step2"],
      "step3": params.body["step3"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Registration
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(RegistrationDecoder).decode(data)
    if (result.ok) {
      return identity<Registration>(result.value)
    } else {
      console.error(
        'POST /registration/masters 2XX Registration\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /registration/masters 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /registration/masters 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /registration/masters 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /registration/masters 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postMasterRegistration"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /registration/masters XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postLecturerRegistrationParams {
  readonly "body": {
    readonly "step1": LecturerRegistrationStep1
    readonly "step2": LecturerRegistrationStep2
    readonly "step3": RegistrationStep3
  }
  readonly "headers": {
    readonly "Accept-Language": LanguageString
  }
}

export type postLecturerRegistrationOk = Registration

export type postLecturerRegistrationErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postLecturerRegistration (params: postLecturerRegistrationParams): Promise<postLecturerRegistrationOk> {
  const url = "/registration/lecturers"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "step1": params.body["step1"],
      "step2": params.body["step2"],
      "step3": params.body["step3"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Registration
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(RegistrationDecoder).decode(data)
    if (result.ok) {
      return identity<Registration>(result.value)
    } else {
      console.error(
        'POST /registration/lecturers 2XX Registration\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /registration/lecturers 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /registration/lecturers 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /registration/lecturers 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /registration/lecturers 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postLecturerRegistration"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /registration/lecturers XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getMetaDataParams {
  readonly "query": {
    readonly "_": string
  }
}

export type getMetaDataOk = MetaData

export type getMetaDataErr = never

export async function getMetaData (params: getMetaDataParams): Promise<getMetaDataOk> {
  const url = "/metadata.json"
  const query = queryToString(params.query)
  const response = await fetch(location.origin + url + query, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX MetaData
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(MetaDataDecoder).decode(data)
    if (result.ok) {
      return identity<MetaData>(result.value)
    } else {
      console.error(
        'GET /metadata.json 2XX MetaData\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getMetaData"))
  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentSurveyActiveParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getStudentSurveyActiveOk = Survey

export type getStudentSurveyActiveErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentSurveyActive (params: getStudentSurveyActiveParams): Promise<getStudentSurveyActiveOk> {
  const url = "/student/survey/active"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Survey
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(SurveyDecoder).decode(data)
    if (result.ok) {
      return identity<Survey>(result.value)
    } else {
      console.error(
        'GET /student/survey/active 2XX Survey\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/survey/active 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/survey/active 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/survey/active 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/survey/active 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentSurveyActive"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/survey/active XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getStudentSurveyQuestionsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "page": number
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getStudentSurveyQuestionsOk = readonly SurveyQuestion[]

export type getStudentSurveyQuestionsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getStudentSurveyQuestions (params: getStudentSurveyQuestionsParams): Promise<getStudentSurveyQuestionsOk> {
  let url = "/student/survey/"
  url += params.args["id"]
  url += "/questions"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly SurveyQuestion[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(SurveyQuestionDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly SurveyQuestion[]>(result.value)
    } else {
      console.error(
        'GET /student/survey/ 2XX readonly SurveyQuestion[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /student/survey/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /student/survey/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /student/survey/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /student/survey/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getStudentSurveyQuestions"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /student/survey/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentSurveyQuestionsAnswersParams {
  readonly "body": {
    readonly "data": readonly QuestionAnswerRequest[]
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type postStudentSurveyQuestionsAnswersOk = SurveyProgress

export type postStudentSurveyQuestionsAnswersErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentSurveyQuestionsAnswers (params: postStudentSurveyQuestionsAnswersParams): Promise<postStudentSurveyQuestionsAnswersOk> {
  let url = "/student/survey/"
  url += params.args["id"]
  url += "/questions/answers"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "data": params.body["data"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX SurveyProgress
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(SurveyProgressDecoder).decode(data)
    if (result.ok) {
      return identity<SurveyProgress>(result.value)
    } else {
      console.error(
        'POST /student/survey/ 2XX SurveyProgress\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/survey/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/survey/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/survey/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/survey/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentSurveyQuestionsAnswers"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/survey/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentSurveyCloseParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type postStudentSurveyCloseOk = never

export type postStudentSurveyCloseErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentSurveyClose (params: postStudentSurveyCloseParams): Promise<postStudentSurveyCloseOk> {
  let url = "/student/survey/"
  url += params.args["id"]
  url += "/close"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/survey/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/survey/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/survey/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/survey/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentSurveyClose"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/survey/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postStudentSurveyFinishParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type postStudentSurveyFinishOk = never

export type postStudentSurveyFinishErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postStudentSurveyFinish (params: postStudentSurveyFinishParams): Promise<postStudentSurveyFinishOk> {
  let url = "/student/survey/"
  url += params.args["id"]
  url += "/finish"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /student/survey/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /student/survey/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /student/survey/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /student/survey/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postStudentSurveyFinish"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /student/survey/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerSurveyActiveParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
}

export type getLecturerSurveyActiveOk = Survey

export type getLecturerSurveyActiveErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerSurveyActive (params: getLecturerSurveyActiveParams): Promise<getLecturerSurveyActiveOk> {
  const url = "/lecturer/survey/active"
  const response = await fetch(baseURL + url, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX Survey
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(SurveyDecoder).decode(data)
    if (result.ok) {
      return identity<Survey>(result.value)
    } else {
      console.error(
        'GET /lecturer/survey/active 2XX Survey\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/survey/active 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/survey/active 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/survey/active 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/survey/active 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerSurveyActive"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/survey/active XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerSurveyQuestionsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "page": number
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getLecturerSurveyQuestionsOk = readonly SurveyQuestion[]

export type getLecturerSurveyQuestionsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerSurveyQuestions (params: getLecturerSurveyQuestionsParams): Promise<getLecturerSurveyQuestionsOk> {
  let url = "/lecturer/survey/"
  url += params.args["id"]
  url += "/questions"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly SurveyQuestion[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(SurveyQuestionDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly SurveyQuestion[]>(result.value)
    } else {
      console.error(
        'GET /lecturer/survey/ 2XX readonly SurveyQuestion[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/survey/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/survey/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/survey/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/survey/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerSurveyQuestions"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/survey/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postLecturerSurveyQuestionsAnswersParams {
  readonly "body": {
    readonly "data": readonly QuestionAnswerRequest[]
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type postLecturerSurveyQuestionsAnswersOk = SurveyProgress

export type postLecturerSurveyQuestionsAnswersErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postLecturerSurveyQuestionsAnswers (params: postLecturerSurveyQuestionsAnswersParams): Promise<postLecturerSurveyQuestionsAnswersOk> {
  let url = "/lecturer/survey/"
  url += params.args["id"]
  url += "/questions/answers"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "data": params.body["data"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX SurveyProgress
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(SurveyProgressDecoder).decode(data)
    if (result.ok) {
      return identity<SurveyProgress>(result.value)
    } else {
      console.error(
        'POST /lecturer/survey/ 2XX SurveyProgress\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /lecturer/survey/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /lecturer/survey/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /lecturer/survey/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /lecturer/survey/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postLecturerSurveyQuestionsAnswers"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /lecturer/survey/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postLecturerSurveyCloseParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type postLecturerSurveyCloseOk = never

export type postLecturerSurveyCloseErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postLecturerSurveyClose (params: postLecturerSurveyCloseParams): Promise<postLecturerSurveyCloseOk> {
  let url = "/lecturer/survey/"
  url += params.args["id"]
  url += "/close"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /lecturer/survey/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /lecturer/survey/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /lecturer/survey/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /lecturer/survey/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postLecturerSurveyClose"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /lecturer/survey/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postLecturerSurveyFinishParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type postLecturerSurveyFinishOk = never

export type postLecturerSurveyFinishErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postLecturerSurveyFinish (params: postLecturerSurveyFinishParams): Promise<postLecturerSurveyFinishOk> {
  let url = "/lecturer/survey/"
  url += params.args["id"]
  url += "/finish"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /lecturer/survey/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /lecturer/survey/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /lecturer/survey/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /lecturer/survey/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postLecturerSurveyFinish"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /lecturer/survey/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerCoursesDiscussionsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "id": string
  }
}

export type getLecturerCoursesDiscussionsOk = readonly CoursePost[]

export type getLecturerCoursesDiscussionsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerCoursesDiscussions (params: getLecturerCoursesDiscussionsParams): Promise<getLecturerCoursesDiscussionsOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/discussions"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX readonly CoursePost[]
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(ArrayObjectDecoder(CoursePostDecoder)).decode(data)
    if (result.ok) {
      return identity<readonly CoursePost[]>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 2XX readonly CoursePost[]\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerCoursesDiscussions"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postLecturerCoursesDiscussionsParams {
  readonly "body": {
    readonly "semId": number
    readonly "visibility": number
    readonly "groups"?: readonly string[]
    readonly "commenting": number
    readonly "body": string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
  }
}

export type postLecturerCoursesDiscussionsOk = null

export type postLecturerCoursesDiscussionsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postLecturerCoursesDiscussions (params: postLecturerCoursesDiscussionsParams): Promise<postLecturerCoursesDiscussionsOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/discussions"
  const response = await fetch(baseURL + url, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "semId": params.body["semId"],
      "visibility": params.body["visibility"],
      "groups": params.body["groups"],
      "commenting": params.body["commenting"],
      "body": params.body["body"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postLecturerCoursesDiscussions"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface deleteLecturerCoursesDiscussionsParams {
  readonly "body": {
    readonly "semId": string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
    readonly "postId": string
  }
}

export type deleteLecturerCoursesDiscussionsOk = MessageObject

export type deleteLecturerCoursesDiscussionsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function deleteLecturerCoursesDiscussions (params: deleteLecturerCoursesDiscussionsParams): Promise<deleteLecturerCoursesDiscussionsOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/discussions/"
  url += params.args["postId"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "DELETE",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "semId": params.body["semId"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX MessageObject
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(MessageObjectDecoder).decode(data)
    if (result.ok) {
      return identity<MessageObject>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ 2XX MessageObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("deleteLecturerCoursesDiscussions"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface patchLecturerCoursesDiscussionsParams {
  readonly "body": {
    readonly "semId": string
    readonly "visibility": number
    readonly "groups"?: readonly string[]
    readonly "commenting": number
    readonly "body": string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "args": {
    readonly "id": string
    readonly "postId": string
  }
}

export type patchLecturerCoursesDiscussionsOk = null

export type patchLecturerCoursesDiscussionsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function patchLecturerCoursesDiscussions (params: patchLecturerCoursesDiscussionsParams): Promise<patchLecturerCoursesDiscussionsOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/discussions/"
  url += params.args["postId"]
  url += ""
  const response = await fetch(baseURL + url, {
    method: "PATCH",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "semId": params.body["semId"],
      "visibility": params.body["visibility"],
      "groups": params.body["groups"],
      "commenting": params.body["commenting"],
      "body": params.body["body"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX null
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.hardcoded(null)).decode(data)
    if (result.ok) {
      return identity<null>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 2XX null\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("patchLecturerCoursesDiscussions"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'PATCH /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerCoursesDiscussionParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "id": string
    readonly "postId": string
  }
}

export type getLecturerCoursesDiscussionOk = CoursePost

export type getLecturerCoursesDiscussionErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerCoursesDiscussion (params: getLecturerCoursesDiscussionParams): Promise<getLecturerCoursesDiscussionOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/discussions/"
  url += params.args["postId"]
  url += ""
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX CoursePost
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(CoursePostDecoder).decode(data)
    if (result.ok) {
      return identity<CoursePost>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 2XX CoursePost\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerCoursesDiscussion"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface getLecturerCoursesDiscussionsCommentsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": number
  }
  readonly "args": {
    readonly "id": string
    readonly "postId": string
  }
}

export interface getLecturerCoursesDiscussionsCommentsOk {
  readonly "data": readonly CoursePostComment[]
  readonly "meta": {
    readonly "pagination": Pagination
  }
}

export type getLecturerCoursesDiscussionsCommentsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function getLecturerCoursesDiscussionsComments (params: getLecturerCoursesDiscussionsCommentsParams): Promise<getLecturerCoursesDiscussionsCommentsOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/discussions/"
  url += params.args["postId"]
  url += "/comments"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "GET",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX {
  //   readonly "data": readonly CoursePostComment[]
  //   readonly "meta": {
  //     readonly "pagination": Pagination
  //   }
  // }
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(decoders.object({
      "data": ArrayObjectDecoder(CoursePostCommentDecoder),
      "meta": decoders.object({
        "pagination": PaginationDecoder,
      }),
    })).decode(data)
    if (result.ok) {
      return identity<{
        readonly "data": readonly CoursePostComment[]
        readonly "meta": {
          readonly "pagination": Pagination
        }
      }>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 2XX {\n' +
        '  readonly "data": readonly CoursePostComment[]\n' +
        '  readonly "meta": {\n' +
        '    readonly "pagination": Pagination\n' +
        '  }\n' +
        '}\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("getLecturerCoursesDiscussionsComments"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'GET /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface postLecturerCoursesDiscussionsCommentsParams {
  readonly "body": {
    readonly "replyCommentId"?: number
    readonly "body": string
  }
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": number
  }
  readonly "args": {
    readonly "id": string
    readonly "postId": string
  }
}

export type postLecturerCoursesDiscussionsCommentsOk = CoursePostComment

export type postLecturerCoursesDiscussionsCommentsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function postLecturerCoursesDiscussionsComments (params: postLecturerCoursesDiscussionsCommentsParams): Promise<postLecturerCoursesDiscussionsCommentsOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/discussions/"
  url += params.args["postId"]
  url += "/comments"
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "POST",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
    body: JSON.stringify({
      "replyCommentId": params.body["replyCommentId"],
      "body": params.body["body"],
    }),
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX CoursePostComment
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(CoursePostCommentDecoder).decode(data)
    if (result.ok) {
      return identity<CoursePostComment>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 2XX CoursePostComment\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("postLecturerCoursesDiscussionsComments"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'POST /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}

export interface deleteLecturerCoursesDiscussionsCommentsParams {
  readonly "headers": {
    readonly "Authorization": string
    readonly "Profile-ID": string
    readonly "Accept-Language": LanguageString
  }
  readonly "query": {
    readonly "semId": string
  }
  readonly "args": {
    readonly "id": string
    readonly "postId": string
    readonly "commentId": string
  }
}

export type deleteLecturerCoursesDiscussionsCommentsOk = MessageObject

export type deleteLecturerCoursesDiscussionsCommentsErr = base.AuthError | base.ForbiddenError | base.ErrorsObject | base.MaintenanceMode | base.ErrorMessage

export async function deleteLecturerCoursesDiscussionsComments (params: deleteLecturerCoursesDiscussionsCommentsParams): Promise<deleteLecturerCoursesDiscussionsCommentsOk> {
  let url = "/lecturer/courses/"
  url += params.args["id"]
  url += "/discussions/"
  url += params.args["postId"]
  url += "/comments/"
  url += params.args["commentId"]
  url += ""
  const query = queryToString(params.query)
  const response = await fetch(baseURL + url + query, {
    method: "DELETE",
    headers: {
      "Authorization": params.headers["Authorization"],
      "Profile-ID": params.headers["Profile-ID"],
      "Accept-Language": params.headers["Accept-Language"],
      "Content-Type": "application/json",
      "Accept": "application/json",
    },
  })

  let data: unknown = null
  try {
    data = await response.json()
  } catch (err) {}

  // 2XX MessageObject
  if (response.status >= 200 && response.status <= 299) {
    const result = DataDecoder(MessageObjectDecoder).decode(data)
    if (result.ok) {
      return identity<MessageObject>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ 2XX MessageObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 401 base.AuthError
  if (response.status === 401) {
    const result = DataDecoder(base.AuthErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.AuthError>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ 401 base.AuthError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 403 base.ForbiddenError
  if (response.status === 403) {
    const result = DataDecoder(base.ForbiddenErrorDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ForbiddenError>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ 403 base.ForbiddenError\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 422 base.ErrorsObject
  if (response.status === 422) {
    const result = DataDecoder(base.ErrorsObjectDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorsObject>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ 422 base.ErrorsObject\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // 503 base.MaintenanceMode
  if (response.status === 503) {
    const result = DataDecoder(base.MaintenanceModeDecoder).decode(data)
    if (result.ok) {
      throw identity<base.MaintenanceMode>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ 503 base.MaintenanceMode\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // XXX base.ErrorMessage
  Sentry.setExtra("data", JSON.stringify(data))
  Sentry.captureException(new Error("deleteLecturerCoursesDiscussionsComments"))
  {
    const result = DataDecoder(base.ErrorMessageDecoder).decode(data)
    if (result.ok) {
      throw identity<base.ErrorMessage>(result.value)
    } else {
      console.error(
        'DELETE /lecturer/courses/ XXX base.ErrorMessage\n' +
        '\n' +
        (result.error?.text ?? '')
      )
    }
  }

  // fallback ErrorMessage
  throw identity<ErrorMessage>({
    type: "ErrorMessage",
    message: "Could not process response!"
  })
}
