import i18n, { changeLanguage, use, type Resource } from 'i18next'
import { useEffect } from 'react'
import { initReactI18next } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { createStateContext } from 'react-use'
import * as Types from 'src/api/types'
import { useStorageValue } from 'src/helpers/storage'
import { translations, type Translations } from 'src/translations'
import { getCurrentLocale, localeStorage, type State } from './localeStorage'

const [useState, StateProvider] = createStateContext<State>(getCurrentLocale())

export function useLocale(): State {
  const [locale] = useState()

  return locale
}

export const LocaleStateProvider = StateProvider

void use(initReactI18next).init({
  resources: {
    ka: resource(0, translations),
    en: resource(1, translations),
  },
  lng: getCurrentLocale(),
  interpolation: {
    escapeValue: false,
  },
  fallbackNS: 'uncategorized',
})

export function LocaleManager(): null {
  const locale = useStorageValue(localeStorage)
  const params = useParams()
  const currentLocale = Types.LanguageStringDecoder.value(params.locale) ?? getCurrentLocale()

  const [, setLocale] = useState()

  useEffect(() => {
    setLocale(currentLocale)
  }, [currentLocale])

  useEffect(() => {
    if (locale !== currentLocale) {
      localeStorage.setValue(currentLocale)
    }
  }, [locale, currentLocale])

  useEffect(() => {
    if (i18n.language !== currentLocale) void changeLanguage(currentLocale)
  }, [currentLocale])

  return null
}

function resource(nth: number, translations: Translations): Resource {
  const newResource: Resource = {}

  for (const [categoryName, categoryValue] of Object.entries(translations)) {
    const nameSpace: { [key: string]: string } = {}

    for (const [key, value] of Object.entries(categoryValue)) {
      nameSpace[key] = value[nth]!
    }

    newResource[categoryName] = nameSpace
  }

  return newResource
}
