import { captureException, ErrorBoundary as SentryErrorBoundaryBase } from '@sentry/react'
import React, { createContext, useContext } from 'react'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'

export function FallbackComponent(): JSX.Element {
  const t = useTranslatable()

  try {
    return (
      <div className="m-auto border border-orange-700 text-center text-orange-700" role="alert">
        {t('error:There_was_an_error_please_try_again_later')}
      </div>
    )
  } catch (err) {
    captureException(err)

    return (
      <div className="m-auto border border-orange-700 text-center text-orange-700" role="alert">
        {t('error:There_was_an_error_please_try_again_later')}
      </div>
    )
  }
}

export function SentryErrorBoundary({ children }: { readonly children: React.ReactNode }): JSX.Element {
  return (
    <SentryErrorBoundaryBase fallback={FallbackComponent} showDialog>
      {children}
    </SentryErrorBoundaryBase>
  )
}

interface Props {
  readonly children: React.ReactNode
  readonly errorElement: React.ReactNode
}

interface State {
  readonly hasError: boolean
  readonly err: any
}

const ErrorContext = createContext<any>(null)

export function useErrorValue(): any {
  return useContext(ErrorContext)
}

class ErrorBoundaryBase extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false, err: null }
  }

  static getDerivedStateFromError(err: any): State {
    return { hasError: true, err }
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <ErrorContext.Provider value={this.state.err}>{this.props.errorElement}</ErrorContext.Provider>
    }

    return this.props.children
  }
}

export function ErrorBoundary(props: Props): JSX.Element {
  const locale = useLocale()

  return <ErrorBoundaryBase {...props} key={locale} />
}
