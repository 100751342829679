import { CloseIcon } from 'src/assets/icons/customIcons/Close'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useFormData } from './Form'

export function FormError(): JSX.Element | null {
  const [formData] = useFormData()
  const t = useTranslatable()

  if (formData.errors.general.length === 0) return null
  if (formData.errors.general.length === 1) {
    return (
      <div className="my-3 flex items-center rounded-[4px] bg-seconderyRed/20 px-4 py-3 text-primaryRed" role="alert">
        <div>
          <div className="mr-[11px] rounded-full bg-primaryRed p-1">
            <CloseIcon width="10" height="10" />
          </div>
        </div>
        <div>
          <div className="mb-1 font-bold">{t('error:an_error_occurred')}</div>
          {formData.errors.general[0]}
        </div>
      </div>
    )
  }

  return (
    <div
      className="my-3 flex items-center rounded-[4px] bg-seconderyRed/20 px-4 py-3 font-bold text-primaryRed"
      role="alert"
    >
      <div>
        <div className="mr-[11px] rounded-full bg-primaryRed p-1">
          <CloseIcon width="12" />
        </div>
      </div>
      <div>
        <div className="mb-1 font-bold">
          {t('error:an_error_occurred')}
          <ul>
            {formData.errors.general.map((text, index) => (
              <li key={index}>{text}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}
