import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import type * as Api from 'src/api'
import { useApi, type APIResponse } from 'src/helpers/hooks'
import { useTranslatable } from 'src/hooks/locale/utils'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Select from 'src/views/components/Select'
import { BarChart } from 'src/views/components/highCharts'
import Layout from 'src/views/pages/Authenticated/administrator/statistics/Layout'
import Loader from '../../../../components/Loader'

export default function AdministratorStatisticChoicesPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('system:home_page')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

async function getChoices(): Promise<BarChartState> {
  await new Promise((resolve) => setTimeout(resolve, 1000))

  return {
    title: {
      ka: 'არჩევები 2021-2022 წლის შემოდგომის სემესტრი',
      en: 'Choices in Fall Semester of 2021-2022 Academic Year',
    },
    total: 72127,
    categories: [
      { ka: 'ბაკალავრი', en: 'Bachelor' },
      { ka: 'მაგისტრანტი', en: 'Master' },
      { ka: 'დოქტორანტი', en: 'Doctoral' },
      { ka: 'უმ.პროფესიული', en: 'Higher Profesional' },
      { ka: 'მოსამზადებელი', en: 'Preparational' },
      { ka: 'მსმენელი', en: 'Listener' },
      { ka: 'პროფესიული 3', en: 'Profesional 3' },
      { ka: 'პროფესიული 4', en: 'Profesional 4' },
      { ka: 'პროფესიული 5', en: 'Profesional 5' },
      { ka: 'სასერთიფიკატო', en: 'Certificate' },
      { ka: 'საბაკალავრო-სამაგისტრო', en: 'Bachelor' },
      { ka: 'ერთსაფეხურიანი უმაღლესი საგანმანათლებლო პროგრამა', en: 'One Cycle' },
      { ka: 'ერთსაფეხურიანი(5 წლიანი)', en: 'One Cycle 5' },
      { ka: 'ერთსაფეხურიანი(4 წლიანი)', en: 'One Cycle 4' },
    ],
    xAxis: [60702, 8, 412, 69, 0, 1498, 0, 0, 0, 0, 439, 997, 0, 0, 0],
  }
}

const params = {}
export interface BarChartState {
  readonly title: Api.Translatable
  readonly total: number
  readonly categories: readonly Api.Translatable[]
  readonly xAxis: readonly number[]
}

function PageContent(): JSX.Element | null {
  const choices: APIResponse<BarChartState | null, Error> = useApi({
    endpoint: getChoices,
    params,
    suspense: false,
  })

  return (
    <Layout>
      {choices.data == null ? (
        <Loader className="flex justify-center" />
      ) : (
        <div className="flex-auto p-5 px-2 pt-0 text-primaryTextColor">
          <div className="my-3 flex flex-wrap">
            <Select className="mb-2" value={[]} options={[]} />
          </div>
          <div>
            <React.Suspense fallback={<Loader />}>
              <BarChart apiResponse={choices} />
            </React.Suspense>
          </div>
        </div>
      )}
    </Layout>
  )
}
