import { Formik, useFormikContext, type FormikConfig, type FormikValues } from 'formik'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { createStateContext } from 'react-use'
import { type AuthError, type ErrorMessage, type ErrorsObject, type MaintenanceMode } from 'src/api'
import { useFormikUnload } from 'src/helpers/hooks'

interface Props {
  readonly children?: React.ReactNode
  readonly classNames?: {
    readonly form?: string
  }
  readonly styles?: {
    readonly form?: React.CSSProperties
  }
  readonly formRef?: React.RefObject<HTMLFormElement>
  readonly isConfirmable?: boolean
}

export interface FormData {
  readonly submitted: boolean
  readonly errors: {
    readonly general: readonly string[]
    readonly fields: {
      readonly [field: string]: readonly string[]
    }
  }
}

export const [useFormData, FormDataProvider, FormDataContext] = createStateContext<FormData>({
  submitted: false,
  errors: {
    general: [],
    fields: {},
  },
})

export const FormDataConsumer = FormDataContext.Consumer

type OnSubmitError = ErrorsObject | ErrorMessage | AuthError | MaintenanceMode

function FormWithoutFormDataProvider<Values extends FormikValues = FormikValues>({
  formRef,
  children,
  onSubmit,
  ...props
}: Props & FormikConfig<Values>): JSX.Element {
  const [, setFormData] = useFormData()

  return (
    <Formik
      onSubmit={async (values, formikHelpers) => {
        try {
          setFormData((state) => ({ ...state, submitted: false }))
          await onSubmit(values, formikHelpers)
          setFormData((state) => ({
            ...state,
            errors: {
              general: [],
              fields: {},
            },
            submitted: true,
          }))
        } catch (_err: any) {
          const err = _err as OnSubmitError
          const fields = new Set(Object.keys(values))
          const fieldErrors: { [field: string]: readonly string[] } = {}
          const otherErrors: string[] = []

          if (err.type === 'ErrorsObject') {
            Object.entries(err.errors).forEach(([key, errors]) => {
              if (fields.has(key) || key.includes('.')) {
                fieldErrors[key] = errors
              } else {
                otherErrors.push(...errors)
              }
            })
          } else {
            otherErrors.push(err.message)
          }

          setFormData((state) => ({
            ...state,
            submitted: true,
            errors: {
              ...state.errors,
              general: otherErrors,
              fields: fieldErrors,
            },
          }))
        } finally {
          setTimeout(() => {
            const firstInvalid = document.querySelector<HTMLElement>('.is-invalid')

            if (firstInvalid != null) {
              firstInvalid.scrollIntoView({ behavior: 'smooth', block: 'center' })
              firstInvalid.focus({ preventScroll: true })
            }
          }, 50)
        }
      }}
      {...props}
    >
      <FormChildren {...props} formRef={formRef}>
        {children}
      </FormChildren>
    </Formik>
  )
}

function FormChildren({ formRef, children, isConfirmable = false, ...props }: Props): JSX.Element {
  const formik = useFormikContext()
  useFormikUnload({ when: formik.dirty && isConfirmable })
  const { locale } = useParams()

  React.useEffect(() => {
    formik.resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  return (
    <form
      className={`w-full ${formik.isSubmitting ? 'pointer-events-none opacity-50' : ''} ${
        props.classNames?.form ?? ''
      }`}
      onSubmit={(e) => void formik.handleSubmit(e)}
      style={props.styles?.form ?? undefined}
      ref={formRef}
    >
      {children}
    </form>
  )
}

function FormWithFormDataProvider<Values extends FormikValues = FormikValues>(
  props: Props & FormikConfig<Values>
): JSX.Element {
  return (
    <FormDataProvider>
      <FormWithoutFormDataProvider {...props} />
    </FormDataProvider>
  )
}

export const Form = FormWithFormDataProvider
