import * as FreeBrandsSvgIcons from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet-async'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserStateMutateOnMount } from 'src/hooks/userState'

export default function AdministratorHomePage(): JSX.Element | null {
  const t = useTranslatable()
  useUserStateMutateOnMount()

  return (
    <>
      <Helmet title={t('system:home_page')} />
      <PageContent />
    </>
  )
}

export function PageContent(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <h3 className="mb-3 font-bold">
      <small>
        <FontAwesomeIcon icon={FreeBrandsSvgIcons.faBandcamp} />
        <span style={{ margin: '0 0 .1rem .5rem' }}>{t('system:home_page')}</span>
      </small>
    </h3>
  )
}
