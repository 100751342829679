import EventEmitter from 'eventemitter3'
import { useSyncExternalStore } from 'react'

export interface ObservableValue<T> {
  readonly setValue: (arg0: T) => void
  readonly getValue: () => T
  readonly eventEmitter: EventEmitter<{ update: [T] }>
}

export function observableValue<T>(initialValue: T, isEqual?: (update: T, current: T) => boolean): ObservableValue<T> {
  let currentValue = initialValue

  function setValue(updatedValue: T): void {
    try {
      if (!internalIsEqual(updatedValue, currentValue)) {
        currentValue = updatedValue

        eventEmitter.emit('update', updatedValue)
      }
    } catch (err) {
      console.error(err)
    }

    currentValue = updatedValue
  }

  function getValue(): T {
    return currentValue
  }

  function internalIsEqual(a: T, b: T): boolean {
    // eslint-disable-next-line eqeqeq
    if (isEqual == null) return a == b

    const aa = isEqual(a, b)
    return aa
  }

  const eventEmitter = new EventEmitter<{ update: [T] }>()

  return {
    setValue,
    getValue,
    eventEmitter,
  }
}

export function useObservableValue<T>(observableValue: ObservableValue<T>): T {
  return useSyncExternalStore(
    (onStoreChange) => {
      observableValue.eventEmitter.addListener('update', onStoreChange)

      return () => {
        observableValue.eventEmitter.removeListener('update', onStoreChange)
      }
    },
    () => observableValue.getValue()
  )
}
