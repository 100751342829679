import { format } from 'date-fns'
import type * as React from 'react'
import type * as base from 'src/api/base'

export function isPlainLeftClick(e: React.MouseEvent<any>): boolean {
  return e.button === 0 && !e.altKey && !e.ctrlKey && !e.metaKey && !e.shiftKey
}

export interface ActionProps {
  readonly onClick: (arg0: React.MouseEvent<any>) => void
  readonly href: string
}

export function actionProps(fn: (arg0: React.MouseEvent<any>) => unknown): ActionProps {
  return {
    onClick: (e) => {
      if (isPlainLeftClick(e)) {
        e.preventDefault()

        fn(e)
      }
    },
    href: '',
  }
}

interface Page {
  readonly kind: 'firstPage' | 'lastPage' | 'currentPage' | 'page' | 'dots'
  readonly page: number
}

export function pagination(currentPage: number, totalPages: number, width: number): readonly Page[] {
  const links: Page[] = []
  const offset = width < 380 ? 1 : 3

  if (currentPage - offset > 1) {
    links.push({
      kind: 'firstPage',
      page: 1,
    })
  }

  if (currentPage - (offset + 1) > 2) {
    links.push({
      kind: 'dots',
      page: 0,
    })
  }

  for (
    let i = Math.max(
      1,
      currentPage - (offset + (currentPage > offset + 2 && currentPage - offset < offset + 1 ? 1 : 0))
    );
    i < currentPage;
    i++
  ) {
    links.push({
      kind: 'page',
      page: i,
    })
  }

  links.push({
    kind: 'currentPage',
    page: currentPage,
  })

  if (currentPage < totalPages + 1) {
    for (let i = currentPage + 1; i < Math.min(currentPage + (offset + 1), totalPages); i++) {
      links.push({
        kind: 'page',
        page: i,
      })
    }
  }

  if (currentPage < totalPages - (offset + 1)) {
    links.push({
      kind: 'dots',
      page: totalPages + 1,
    })
  }

  if (currentPage < totalPages) {
    links.push({
      kind: 'lastPage',
      page: totalPages,
    })
  }

  return links
}

export type QueryValue = null | string | number | boolean | QueryArray | QueryObject
export type QueryArray = readonly QueryValue[]
export interface QueryObject {
  readonly [key: string]: QueryValue
}

function isQueryArray(queryValue: QueryValue): queryValue is QueryArray {
  return Array.isArray(queryValue)
}
function isQueryObject(queryValue: QueryValue): queryValue is QueryObject {
  return queryValue != null && typeof queryValue === 'object'
}

export function queryToString(queryObject: QueryObject): string {
  const values = Object.entries(queryObject).flatMap(([i, queryValue]) => {
    if (queryValue == null) {
      return []
    } else {
      return QueryValueToString(i, queryValue)
    }
  })

  if (values.length > 0) {
    return `?${values.join('&')}`
  }

  return ''
}

function QueryObjectToString(key: string, queryObject: QueryObject): string {
  const values = Object.entries(queryObject).flatMap(([i, queryValue]) => {
    if (queryValue == null) {
      return []
    } else {
      return QueryValueToString(`${key}[${i}]`, queryValue)
    }
  })

  return values.join('&')
}

function QueryValueToString(key: string, queryValue: Exclude<QueryValue, null>): string {
  if (isQueryArray(queryValue)) {
    return QueryArrayToString(key, queryValue)
  } else if (isQueryObject(queryValue)) {
    return QueryObjectToString(key, queryValue)
  } else {
    return `${key}=${queryValue.toString()}`
  }
}

function QueryArrayToString(key: string, queryArray: QueryArray): string {
  const values = queryArray.flatMap((queryValue, i) => {
    if (queryValue == null) {
      return []
    } else {
      return QueryValueToString(`${key}[${i.toString()}]`, queryValue)
    }
  })

  return values.join('&')
}

const byteUnits = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] as const

export function formatFileSize(bytes: number): string {
  if (bytes === 0) return '0 Bytes'

  let i = -1
  do {
    bytes = bytes / 1024
    i++
  } while (bytes > 1024)

  return Math.max(bytes, 0.1).toFixed(1) + ' ' + byteUnits[i]!
}

export type Assignable<A, B> = A extends B ? true : false
export type Interchangeable<A, B> = A extends B ? (B extends A ? true : false) : false

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function staticAssert<A extends true>(): void {}

export function identity<T>(value: T): T {
  return value
}

export function errorsToArray(obj: { readonly [key: string]: readonly string[] } | undefined): readonly string[] {
  const errors: string[] = []
  if (obj == null) return []
  for (const value in obj) {
    errors.push(...obj[value]!)
  }
  return errors
}

export function shortenString(str: string = '', maxNum: number | undefined = 20): string {
  return str.length > maxNum ? str.slice(0, maxNum) + '...' : str
}

export function errorMessage(error: base.CommonError): string {
  return error.type === 'ErrorsObject'
    ? error.errors.general?.[0] != null
      ? error.errors?.general?.[0]
      : 'unknown Error'
    : error.message
}

export const formatTelNumber = (str: string, type: 'home' | 'mobile' = 'mobile'): string => {
  const numericInput = str.replace(/\D/g, '').slice(0, type === 'home' ? 10 : 9)
  const firstThreeDigits = numericInput.slice(0, 3)
  const groupedDigits =
    type === 'home'
      ? [...(numericInput.slice(3, 6).match(/.{1,3}/g) ?? []), ...(numericInput.slice(6).match(/.{1,2}/g) ?? [])]
      : numericInput.slice(3).match(/.{1,2}/g) ?? []
  return `${numericInput.length > 3 ? `(${firstThreeDigits}) ` : firstThreeDigits}${groupedDigits.join(' ')}`
}

export function cacheValidation<T>(asyncValidate: (val: T) => Promise<boolean>): (val: T) => Promise<boolean> {
  let _valid = false
  let _value: any

  return async (value) => {
    if (value == null && _value == null) return true
    if (value !== _value) {
      const response = await asyncValidate(value)
      _value = value
      _valid = response
      return response
    }
    return _valid
  }
}

const active = new Map<string, Promise<any>>()

export async function inFlightReUse<T>(unique: string, doFly: () => Promise<T>): Promise<T> {
  if (!active.has(unique)) {
    const result = doFly()
    active.set(unique, result)

    void result.finally(() => active.delete(unique))
  }

  return await active.get(unique)
}

export function formatDate(date: Date | string | null): string | null {
  if (date != null) {
    if (typeof date === 'string') {
      return date
    } else {
      return format(date, 'dd/MM/yyyy')
    }
  }
  return null
}

export function formatDatelocale(value: string | Date | null): Date | string | null {
  if (typeof value === 'string' && value !== '') {
    const parts = value.split(/\/|-/)
    const day = parseInt(parts[0] ?? '', 10)
    const month = parseInt(parts[1] ?? '', 10) - 1 // mnths are 0-indexed (0-11)
    const year = parseInt(parts[2] ?? '', 10)

    return new Date(year, month, day)
  }
  return value
}
export function checkYoutubeLink(url: string): boolean {
  return /^https?:\/\/(www\.)?youtu(\.be|be\.com)\//.test(url)
}
export function checkVimeoLink(url: string): boolean {
  return /^https?:\/\/(www\.)?vimeo.com\//.test(url)
}
