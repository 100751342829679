import * as d from 'decoders'
import * as Types from 'src/api/types'
import { storageValue } from 'src/helpers/storage'

export * from './authObservableValue'

export interface RefreshTokenValue {
  readonly authObject: Types.AuthObject
  readonly profileId: Types.IDString
}

export const refreshTokenStorage = storageValue<RefreshTokenValue | null>(
  'hooks:auth:refreshToken',
  (value) => JSON.stringify(value),
  (serializedValue: string) => {
    const decoder = d.nullable(
      d.object({
        authObject: d.object({
          tokenType: Types.TokenTypeDecoder,
          expiresIn: d.number,
          accessToken: Types.AccessTokenDecoder,
          refreshToken: Types.RefreshTokenDecoder,
        }),
        profileId: Types.IDStringDecoder,
      })
    )

    return decoder.verify(JSON.parse(serializedValue))
  }
)
