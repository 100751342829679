import * as Types from 'src/api/types'
import { storageValue } from 'src/helpers/storage'

export type State = Types.LanguageString

export const localeStorage = storageValue<State>(
  'LocaleManager:locale',
  (value) => JSON.stringify(value),
  (serializedValue: string) => {
    return Types.LanguageStringDecoder.verify(JSON.parse(serializedValue))
  }
)

export function getCurrentLocale(): State {
  return (location.pathname.match(/^\/(en|ka)/)?.[1] as Types.LanguageString) ?? localeStorage.getValue() ?? 'ka'
}
