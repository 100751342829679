import * as d from 'decoders'
import * as React from 'react'
import { useMedia } from 'react-use'
import { storageValue, useStorageValue } from 'src/helpers/storage'

export type State = 'light' | 'dark'

export const themeStorage = storageValue<State>(
  'app:theme',
  (value) => JSON.stringify(value),
  (serializedValue) => {
    const decoder = d.either(d.constant('light'), d.constant('dark'))

    return decoder.verify(JSON.parse(serializedValue))
  }
)

export function useTheme(): State {
  const preferedTheme = useMedia('(prefers-color-scheme: dark)', false) ? 'dark' : 'light'
  const theme = useStorageValue(themeStorage)

  return theme ?? preferedTheme
}

export function useThemeSwitch(): <T>(themeSwitchMap: {
  [key in State]: T
}) => T {
  const theme = useTheme()

  return React.useCallback((themeSwitchMap) => themeSwitchMap[theme], [theme])
}

export function ThemeManager(): null {
  const theme = useTheme()

  React.useEffect(() => {
    if (theme === 'dark') {
      document.body.classList.add('dark-theme')
      document.body.classList.remove('light-theme')
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark-theme')
      document.body.classList.add('light-theme')
      document.body.classList.remove('dark')
    }
  }, [theme])

  return null
}
