import classnames from 'classnames'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import type * as Api from 'src/api'
import { ArrowLeft } from 'src/assets/icons/customIcons/ArrowLeft'
import { ArrowRight } from 'src/assets/icons/customIcons/ArrowRight'
import { pagination } from 'src/helpers/fns'
import { useAllSearchParams } from 'src/helpers/hooks'
import { useTranslatable } from 'src/hooks/locale/utils'
import Select from './Select'

interface Props {
  readonly pagination: Api.Pagination
  readonly withOutPerPage?: boolean
}

export default function Pagination(props: Props): React.ReactElement | null {
  const t = useTranslatable()
  const allSearchParams = useAllSearchParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { width } = useWindowSize()

  const pager = pagination(props.pagination.currentPage, props.pagination.totalPages, width)

  interface PageItemProps {
    readonly page?: number
    readonly children: React.ReactNode
    readonly className?: string
  }

  function PageItem({
    page = props.pagination.currentPage - 1,
    className,
    children,
    ...pageItemProps
  }: PageItemProps): JSX.Element {
    const disabled = page > props.pagination.totalPages || page < 1

    return (
      <li className="ml-1 rounded !border !border-borderGrey hover:bg-veryLightGrey hover:text-white">
        <button
          className={classnames('flex size-9 items-center justify-center', className, {
            '!cursor-not-allowed': disabled,
          })}
          onClick={() => setSearchParams({ ...allSearchParams, page: page.toString() }, { replace: true })}
          disabled={disabled}
          {...pageItemProps}
        >
          {children}
        </button>
      </li>
    )
  }

  const perPageOptions = React.useMemo(() => {
    if (props.pagination.total < 10) {
      return [{ value: props.pagination.total, label: props.pagination.total }]
    } else if (props.pagination.total < 20) {
      return [
        { value: 10, label: 10 },
        { value: props.pagination.total, label: props.pagination.total },
      ]
    } else if (props.pagination.total < 50) {
      return [
        { value: 10, label: 10 },
        { value: 20, label: 20 },
        { value: props.pagination.total, label: props.pagination.total },
      ]
    }
    return [
      { value: 10, label: 10 },
      { value: 20, label: 20 },
      { value: 50, label: 50 },
    ]
  }, [props.pagination.total])

  return (
    <div
      className={
        'flex flex-wrap sm:justify-end xxs:mt-2 xxs:justify-end xs:mt-2 xs:justify-end' +
        (props.withOutPerPage ?? false ? 'justify-end' : 'mt-6 justify-between')
      }
    >
      {!(props.withOutPerPage ?? false) && (
        <div className="my-2 flex items-center text-primaryTextColor">
          <span className="text-title">{t('common:shown')}</span>
          <span className="ml-2">
            <Select<{ value: number; label: number }>
              options={perPageOptions}
              controlStyles={{ height: 36 }}
              value={perPageOptions.find((option) => option.value === Number(props.pagination.count))}
              defaultValue={perPageOptions.find((option) => option.value === Number(props.pagination.count))}
              onChange={(option) => {
                searchParams.delete('page')
                searchParams.set('perPage', option!.value.toString())
                setSearchParams(searchParams, { replace: true })
              }}
            />
          </span>
          <span className="mx-2 text-title">{t('common:records_from')}</span>
          <span data-testid="records-total">{props.pagination.total}</span>
          <span className="text-title">{t('uncategorized:from')}</span>
        </div>
      )}
      <ul className="mb-6 flex text-primaryTextColor">
        <PageItem data-testid="previousButton">
          <ArrowLeft />
        </PageItem>

        {pager.map((page) => {
          if (page.kind === 'dots') {
            return (
              <li
                key={page.page}
                className="ml-1 flex size-9 items-center justify-center rounded !border !border-borderGrey"
              >
                <a>...</a>
              </li>
            )
          } else {
            return (
              <li
                key={page.page}
                className={classnames('ml-1 rounded !border !border-borderGrey', {
                  active: page.kind === 'currentPage',
                })}
              >
                <button
                  className="size-9 rounded hover:bg-veryLightGrey hover:text-white"
                  onClick={() => setSearchParams({ ...allSearchParams, page: page.page.toString() }, { replace: true })}
                  data-testid={`page-${page.page}`}
                >
                  {page.page}
                </button>
              </li>
            )
          }
        })}

        <PageItem page={props.pagination.currentPage + 1} data-testid="nextButton">
          <ArrowRight />
        </PageItem>
      </ul>
    </div>
  )
}
