import { setContext } from '@sentry/react'
import * as decoders from 'decoders'

interface Env {
  readonly GOOGLE_CLIENT_ID: string
  readonly REQUIRE_REFRESH_INTERVAL: number
  readonly REVISION: string
  readonly SENTRY_DSN: string
  readonly SERVICE_URL: string
  readonly OLD_ARGUS_URL?: string
  readonly FIREBASE_API_KEY: string
  readonly FIREBASE_AUTH_DOMAIN: string
  readonly FIREBASE_PROJECT_ID: string
  readonly FIREBASE_STORAGE_BUCKET: string
  readonly FIREBASE_MESSAGING_SENDER_ID: string
  readonly FIREBASE_APP_ID: string
  readonly FIREBASE_MEASUREMENT_ID: string
  readonly VAPID_KEY: string
  readonly APP_ENV: 'LOCAL' | 'STAGING' | 'PRODUCTION'
}

const EnvDecoder: decoders.Decoder<Env> = decoders.object({
  GOOGLE_CLIENT_ID: decoders.string,
  REQUIRE_REFRESH_INTERVAL: decoders.string
    .transform((input) => parseInt(input, 10))
    .refine((input) => !Number.isNaN(input), 'REQUIRE_REFRESH_INTERVAL must be a number!'),
  REVISION: decoders.string,
  SENTRY_DSN: decoders.string,
  SERVICE_URL: decoders.string,
  OLD_ARGUS_URL: decoders.optional(decoders.string),
  FIREBASE_API_KEY: decoders.string,
  FIREBASE_AUTH_DOMAIN: decoders.string,
  FIREBASE_PROJECT_ID: decoders.string,
  FIREBASE_STORAGE_BUCKET: decoders.string,
  FIREBASE_MESSAGING_SENDER_ID: decoders.string,
  FIREBASE_APP_ID: decoders.string,
  FIREBASE_MEASUREMENT_ID: decoders.string,
  VAPID_KEY: decoders.string,
  APP_ENV: decoders.either(
    decoders.constant('LOCAL' as const),
    decoders.constant('STAGING' as const),
    decoders.constant('PRODUCTION' as const)
  ),
})

function getServiceUrl(env: Env): string {
  if (location.hostname.endsWith('.trycloudflare.com')) return new URL(env.SERVICE_URL).pathname
  if (location.port !== '' && !/\.(iliauni\.(edu\.ge|io)|test)$/.test(location.hostname))
    return new URL(env.SERVICE_URL).pathname

  return env.SERVICE_URL
}

function getEnv(): Env {
  const env: Env = EnvDecoder.verify({
    GOOGLE_CLIENT_ID: import.meta.env.REACT_APP_GOOGLE_CLIENT_ID,
    REQUIRE_REFRESH_INTERVAL: import.meta.env.REACT_APP_REQUIRE_REFRESH_INTERVAL,
    REVISION: import.meta.env.REACT_APP_REVISION,
    SENTRY_DSN: import.meta.env.REACT_APP_SENTRY_DSN,
    SERVICE_URL: import.meta.env.REACT_APP_SERVICE_URL,
    OLD_ARGUS_URL: import.meta.env.REACT_APP_OLD_ARGUS_URL,
    FIREBASE_API_KEY: import.meta.env.REACT_APP_FIREBASE_API_KEY,
    FIREBASE_AUTH_DOMAIN: import.meta.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    FIREBASE_PROJECT_ID: import.meta.env.REACT_APP_FIREBASE_PROJECT_ID,
    FIREBASE_STORAGE_BUCKET: import.meta.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    FIREBASE_MESSAGING_SENDER_ID: import.meta.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    FIREBASE_APP_ID: import.meta.env.REACT_APP_FIREBASE_APP_ID,
    FIREBASE_MEASUREMENT_ID: import.meta.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    VAPID_KEY: import.meta.env.REACT_APP_VAPID_KEY,
    APP_ENV: import.meta.env.REACT_APP_ENV,
  })

  const SERVICE_URL = getServiceUrl(env)

  return {
    ...env,
    SERVICE_URL,
  }
}

const env = getEnv()

setContext('env', {
  APP_ENV: env.APP_ENV,
  REVISION: env.REVISION,
})

export default env
