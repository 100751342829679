import React from 'react'
import { Link } from 'react-router-dom'
import { ArrowNext } from 'src/assets/icons/customIcons/ArrowNext'
import { shortenString } from 'src/helpers/fns'
import { useTranslatable } from 'src/hooks/locale/utils'

interface Props {
  children?: React.ReactNode | React.ReactNode[]
  rightElement?: JSX.Element
  icon?: JSX.Element
  title?: string
  subTitle?: string
  homePage?: boolean
  breadcrubms?: breadcrubmsItem[] | null
}

interface breadcrubmsItem {
  page: string | undefined
  path: string
}

export const Section = React.forwardRef<HTMLElement, JSX.IntrinsicElements['section'] & Props>(function Section(
  { children, icon, title, subTitle, rightElement, homePage = false, breadcrubms, ...props },
  ref
): JSX.Element {
  const t = useTranslatable()

  return (
    <section {...props} ref={ref} className="w-full bg-primaryWhite pb-2" style={{ marginBottom: homePage ? 46 : 0 }}>
      {title !== undefined || icon !== undefined || subTitle !== undefined ? (
        <div style={{ marginBottom: homePage ? 16 : 36 }} className="text-primaryTextColor">
          <div className="flex flex-wrap justify-between">
            <div className="flex items-center">
              <figure className="mb-0">{icon}</figure>
              <span
                className={
                  'text-headline font-semibold text-primaryTextColor xxs:text-[20px] xs:text-[20px]' +
                  (icon != null ? 'ml-3' : '')
                }
                data-testid="sectionTitle"
              >
                {title}
              </span>
            </div>
            {rightElement ?? null}
          </div>
          <div className="mt-[14px] text-captionColor">{subTitle}</div>
          {(() => {
            if (breadcrubms != null) {
              return (
                <ul className="mt-[14px] inline-flex print:hidden" data-testid="breadcrubms">
                  <li className="text-xs !text-captionColor">
                    <Link to="/" className="hover:opacity-70">
                      {t('system:home')}
                    </Link>
                  </li>
                  {breadcrubms?.map((item, key) => (
                    <li key={key} className="text-xs !text-captionColor">
                      <div className="flex justify-center">
                        <span className="px-1 pt-[3px]">
                          <ArrowNext />
                        </span>
                        <Link to={item.path} className="hover:opacity-70">
                          {window.innerWidth < 480
                            ? shortenString(item.page, 40)
                            : window.innerWidth < 767
                              ? shortenString(item.page, 70)
                              : item.page}
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              )
            }
          })()}
        </div>
      ) : undefined}
      {children}
    </section>
  )
})
