import { Helmet } from 'react-helmet-async'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserStateMutateOnMount } from 'src/hooks/userState'
import Layout from './Layout'

export default function AdministratorUsersLecturersPage(): JSX.Element | null {
  const t = useTranslatable()
  useUserStateMutateOnMount()

  return (
    <>
      <Helmet title={t('lecturer:lecturers')} />
      <PageContent />
    </>
  )
}

export function PageContent(): JSX.Element | null {
  return <Layout />
}
