import { type LocaleInput } from '@fullcalendar/core'
import fen from '@fullcalendar/core/locales/en-gb'
import fka from '@fullcalendar/core/locales/ka'
import type * as date from 'date-fns'
import { enUS } from 'date-fns/locale/en-US'
import { ka } from 'date-fns/locale/ka'
import { t as tt } from 'i18next'
import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { type Translatable } from 'src/api/base'
import { LanguageStringDecoder, type LanguageString } from 'src/api/types'
import { type TranslationKeys } from 'src/translations'
import { useLocale } from './locale'

export function translate(
  locale: LanguageString,
  translatable: Translatable | [ka: string, en: string] | string,
  en?: string
): string {
  if (typeof translatable === 'string') {
    if (locale === 'ka') {
      return translatable
    } else {
      locale satisfies 'en'

      if (typeof en === 'string') {
        return en
      } else {
        return translatable
      }
    }
  } else if (Array.isArray(translatable)) {
    if (locale === 'ka') {
      return translatable[0]
    } else {
      locale satisfies 'en'

      return translatable[1]
    }
  } else {
    if (locale === 'ka') {
      return translatable.ka
    } else {
      locale satisfies 'en'

      return translatable.en
    }
  }
}

export function useDateLocale(): date.Locale {
  const locale = useLocale()

  if (locale === 'ka') {
    return ka
  }

  locale satisfies 'en'

  return enUS
}

export function useFullCalendarLocale(): LocaleInput {
  const locale = useLocale()

  if (locale === 'ka') {
    return fka
  }

  locale satisfies 'en'

  return fen
}

export interface Fn {
  (translatable: Translatable | [ka: string, en: string] | TranslationKeys): string
  (ka: string, en: string): string
}

export function useTranslatable(): Fn {
  const locale = useLocale()

  return React.useCallback<Fn>(
    (translatable: Translatable | [ka: string, en: string] | string, en?: string): string => {
      if (typeof translatable === 'string') {
        return tt(translatable, translate(locale, translatable, en))
      } else {
        return translate(locale, translatable, en)
      }
    },
    [locale]
  )
}

export function useLocaleLinks(): Translatable {
  const loc = useLocation()

  const [, languageString, ...pathname] = loc.pathname.split('/')
  const languageResult = LanguageStringDecoder.decode(languageString)

  if (languageResult.ok) {
    return {
      ka: `${['/ka', ...pathname].join('/')}${loc.search}${loc.hash}`,
      en: `${['/en', ...pathname].join('/')}${loc.search}${loc.hash}`,
    }
  }

  return {
    ka: '/ka',
    en: '/en',
  }
}
