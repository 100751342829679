// Auto Generated, Don't Modify Manually
import * as decoders from 'decoders'
import { ArrayObjectDecoder } from './base'
import { optionalNullableDecoder } from './base'

export type TokenType = "Bearer"

export const TokenTypeDecoder: decoders.Decoder<TokenType> = decoders.constant("Bearer" as const)

export type AccessToken = string

export const AccessTokenDecoder: decoders.Decoder<AccessToken> = decoders.string

export type RefreshToken = string

export const RefreshTokenDecoder: decoders.Decoder<RefreshToken> = decoders.string

export type IDString = string

export const IDStringDecoder: decoders.Decoder<IDString> = decoders.either(decoders.number, decoders.string).transform(data => data.toString())

export type Stringable = string

export const StringableDecoder: decoders.Decoder<Stringable> = decoders.nullable(decoders.string).transform(str => str ?? '')

export type EnumValue = string | number

export const EnumValueDecoder: decoders.Decoder<EnumValue> = decoders.either(decoders.number, decoders.string).transform(data => data.toString())

export type EmailString = string

export const EmailStringDecoder: decoders.Decoder<EmailString> = decoders.string

export type SVGString = string

export const SVGStringDecoder: decoders.Decoder<SVGString> = decoders.string

export type URLString = string

export const URLStringDecoder: decoders.Decoder<URLString> = decoders.string

export type LanguageString = "ka" | "en"

export const LanguageStringDecoder: decoders.Decoder<LanguageString> = decoders.either(
  decoders.constant("ka" as const),
  decoders.constant("en" as const)
)

export interface MessageObject {
  readonly "message": string
}

export const MessageObjectDecoder: decoders.Decoder<MessageObject> = decoders.object({
  "message": decoders.string,
})

export type MessageTypeEnum =
  | "MESSAGE_TYPE"
  | "RECOMMENDATION_TYPE"

export const MessageTypeEnumDecoder: decoders.Decoder<MessageTypeEnum> = (
  decoders.either(decoders.number, decoders.string)
  .transform(key => {
    switch (key) {
      case 0: return "MESSAGE_TYPE"
      case 1: return "MESSAGE_TYPE"
      case 2: return "RECOMMENDATION_TYPE"
    }

    throw new Error(`Could not decode MessageTypeEnum from value: ${key}`)
  })
)

export function MessageTypeEnumEncoder (label: MessageTypeEnum): string | number {
  switch (label) {
    case "MESSAGE_TYPE": return 1
    case "RECOMMENDATION_TYPE": return 2
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Could not decode __UNNAMED_TYPE__ from value: ${label}`)
  }
}

export const MessageTypeEnumValues = [
  "MESSAGE_TYPE",
  "RECOMMENDATION_TYPE",
] as const

export interface AuthObject {
  readonly "tokenType": TokenType
  readonly "expiresIn": number
  readonly "accessToken": AccessToken
  readonly "refreshToken": RefreshToken
}

export const AuthObjectDecoder: decoders.Decoder<AuthObject> = (
  decoders.object({
    attributes: decoders.object({
      "tokenType": TokenTypeDecoder,
      "expiresIn": decoders.number,
      "accessToken": AccessTokenDecoder,
      "refreshToken": RefreshTokenDecoder,
    }),
  })
  .transform(obj => {
    return {
      "tokenType": obj.attributes["tokenType"],
      "expiresIn": obj.attributes["expiresIn"],
      "accessToken": obj.attributes["accessToken"],
      "refreshToken": obj.attributes["refreshToken"],
    }
  })
)

export type StudentDegree =
  | "STUDENT_BACHELOR"
  | "STUDENT_MASTER"
  | "STUDENT_DOCTOR"
  | "STUDENT_PROFESSIONAL"
  | "STUDENT_PREPARATIONAL"
  | "STUDENT_FREE"
  | "STUDENT_PROFESSIONAL_3"
  | "STUDENT_PROFESSIONAL_4"
  | "STUDENT_PROFESSIONAL_5"
  | "STUDENT_FORCERTIFICATE"
  | "STUDENT_BACHELOR_MASTER"
  | "STUDENT_ONE_STAGE_HIGHER"

export const StudentDegreeDecoder: decoders.Decoder<StudentDegree> = (
  decoders.either(decoders.number, decoders.string)
  .transform(key => {
    switch (key) {
      case 1: return "STUDENT_BACHELOR"
      case 2: return "STUDENT_MASTER"
      case 3: return "STUDENT_DOCTOR"
      case 4: return "STUDENT_PROFESSIONAL"
      case 5: return "STUDENT_PREPARATIONAL"
      case 6: return "STUDENT_FREE"
      case 7: return "STUDENT_PROFESSIONAL_3"
      case 8: return "STUDENT_PROFESSIONAL_4"
      case 9: return "STUDENT_PROFESSIONAL_5"
      case 10: return "STUDENT_FORCERTIFICATE"
      case 11: return "STUDENT_BACHELOR_MASTER"
      case 12: return "STUDENT_ONE_STAGE_HIGHER"
    }

    throw new Error(`Could not decode StudentDegree from value: ${key}`)
  })
)

export function StudentDegreeEncoder (label: StudentDegree): string | number {
  switch (label) {
    case "STUDENT_BACHELOR": return 1
    case "STUDENT_MASTER": return 2
    case "STUDENT_DOCTOR": return 3
    case "STUDENT_PROFESSIONAL": return 4
    case "STUDENT_PREPARATIONAL": return 5
    case "STUDENT_FREE": return 6
    case "STUDENT_PROFESSIONAL_3": return 7
    case "STUDENT_PROFESSIONAL_4": return 8
    case "STUDENT_PROFESSIONAL_5": return 9
    case "STUDENT_FORCERTIFICATE": return 10
    case "STUDENT_BACHELOR_MASTER": return 11
    case "STUDENT_ONE_STAGE_HIGHER": return 12
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Could not decode __UNNAMED_TYPE__ from value: ${label}`)
  }
}

export const StudentDegreeValues = [
  "STUDENT_BACHELOR",
  "STUDENT_MASTER",
  "STUDENT_DOCTOR",
  "STUDENT_PROFESSIONAL",
  "STUDENT_PREPARATIONAL",
  "STUDENT_FREE",
  "STUDENT_PROFESSIONAL_3",
  "STUDENT_PROFESSIONAL_4",
  "STUDENT_PROFESSIONAL_5",
  "STUDENT_FORCERTIFICATE",
  "STUDENT_BACHELOR_MASTER",
  "STUDENT_ONE_STAGE_HIGHER",
] as const

export type LecturerDegree =
  | "LECTURER_PROFESSOR"
  | "LECTURER_ASSOC_PROFESSOR"
  | "LECTURER_ASSISTANT_PROFESSOR"
  | "LECTURER_INVITED_LECTURER"
  | "LECTURER_PROFESSOR_EMERITUS"
  | "LECTURER_RESP_PROFESSOR"
  | "LECTURER_ASSISTANT"
  | "LECTURER_COURSE_CURATOR"
  | "LECTURER_ACADEMIC_PERSONAL"
  | "LECTURER_TEACHER"

export const LecturerDegreeDecoder: decoders.Decoder<LecturerDegree> = (
  decoders.either(decoders.number, decoders.string)
  .transform(key => {
    switch (key) {
      case 1: return "LECTURER_PROFESSOR"
      case 2: return "LECTURER_ASSOC_PROFESSOR"
      case 3: return "LECTURER_ASSISTANT_PROFESSOR"
      case 4: return "LECTURER_INVITED_LECTURER"
      case 5: return "LECTURER_PROFESSOR_EMERITUS"
      case 6: return "LECTURER_RESP_PROFESSOR"
      case 7: return "LECTURER_ASSISTANT"
      case 8: return "LECTURER_COURSE_CURATOR"
      case 9: return "LECTURER_ACADEMIC_PERSONAL"
      case 10: return "LECTURER_TEACHER"
    }

    throw new Error(`Could not decode LecturerDegree from value: ${key}`)
  })
)

export function LecturerDegreeEncoder (label: LecturerDegree): string | number {
  switch (label) {
    case "LECTURER_PROFESSOR": return 1
    case "LECTURER_ASSOC_PROFESSOR": return 2
    case "LECTURER_ASSISTANT_PROFESSOR": return 3
    case "LECTURER_INVITED_LECTURER": return 4
    case "LECTURER_PROFESSOR_EMERITUS": return 5
    case "LECTURER_RESP_PROFESSOR": return 6
    case "LECTURER_ASSISTANT": return 7
    case "LECTURER_COURSE_CURATOR": return 8
    case "LECTURER_ACADEMIC_PERSONAL": return 9
    case "LECTURER_TEACHER": return 10
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Could not decode __UNNAMED_TYPE__ from value: ${label}`)
  }
}

export const LecturerDegreeValues = [
  "LECTURER_PROFESSOR",
  "LECTURER_ASSOC_PROFESSOR",
  "LECTURER_ASSISTANT_PROFESSOR",
  "LECTURER_INVITED_LECTURER",
  "LECTURER_PROFESSOR_EMERITUS",
  "LECTURER_RESP_PROFESSOR",
  "LECTURER_ASSISTANT",
  "LECTURER_COURSE_CURATOR",
  "LECTURER_ACADEMIC_PERSONAL",
  "LECTURER_TEACHER",
] as const

export type AdministratorDegree =
  | "ADMINISTRATOR_FACULTY_ADMINISTRATOR"
  | "ADMINISTRATOR_FACULTY_MODERATOR"
  | "ADMINISTRATOR_REGISTRAR"
  | "ADMINISTRATOR_MAIN_ADMINISTRATOR"

export const AdministratorDegreeDecoder: decoders.Decoder<AdministratorDegree> = (
  decoders.either(decoders.number, decoders.string)
  .transform(key => {
    switch (key) {
      case 3: return "ADMINISTRATOR_FACULTY_ADMINISTRATOR"
      case 4: return "ADMINISTRATOR_FACULTY_MODERATOR"
      case 55: return "ADMINISTRATOR_REGISTRAR"
      case 99: return "ADMINISTRATOR_MAIN_ADMINISTRATOR"
    }

    throw new Error(`Could not decode AdministratorDegree from value: ${key}`)
  })
)

export function AdministratorDegreeEncoder (label: AdministratorDegree): string | number {
  switch (label) {
    case "ADMINISTRATOR_FACULTY_ADMINISTRATOR": return 3
    case "ADMINISTRATOR_FACULTY_MODERATOR": return 4
    case "ADMINISTRATOR_REGISTRAR": return 55
    case "ADMINISTRATOR_MAIN_ADMINISTRATOR": return 99
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Could not decode __UNNAMED_TYPE__ from value: ${label}`)
  }
}

export const AdministratorDegreeValues = [
  "ADMINISTRATOR_FACULTY_ADMINISTRATOR",
  "ADMINISTRATOR_FACULTY_MODERATOR",
  "ADMINISTRATOR_REGISTRAR",
  "ADMINISTRATOR_MAIN_ADMINISTRATOR",
] as const

export type ResumeDocumentType =
  | "DIPLOMA"
  | "CERTIFICATE"

export const ResumeDocumentTypeDecoder: decoders.Decoder<ResumeDocumentType> = (
  decoders.either(decoders.number, decoders.string)
  .transform(key => {
    switch (key) {
      case 1: return "DIPLOMA"
      case 2: return "CERTIFICATE"
    }

    throw new Error(`Could not decode ResumeDocumentType from value: ${key}`)
  })
)

export function ResumeDocumentTypeEncoder (label: ResumeDocumentType): string | number {
  switch (label) {
    case "DIPLOMA": return 1
    case "CERTIFICATE": return 2
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Could not decode __UNNAMED_TYPE__ from value: ${label}`)
  }
}

export const ResumeDocumentTypeValues = [
  "DIPLOMA",
  "CERTIFICATE",
] as const

export type ResumeLanguageLevel =
  | "A1"
  | "A2"
  | "B1"
  | "B2"
  | "C1"
  | "C2"

export const ResumeLanguageLevelDecoder: decoders.Decoder<ResumeLanguageLevel> = (
  decoders.either(decoders.number, decoders.string)
  .transform(key => {
    switch (key) {
      case 1: return "A1"
      case 2: return "A2"
      case 3: return "B1"
      case 4: return "B2"
      case 5: return "C1"
      case 6: return "C2"
    }

    throw new Error(`Could not decode ResumeLanguageLevel from value: ${key}`)
  })
)

export function ResumeLanguageLevelEncoder (label: ResumeLanguageLevel): string | number {
  switch (label) {
    case "A1": return 1
    case "A2": return 2
    case "B1": return 3
    case "B2": return 4
    case "C1": return 5
    case "C2": return 6
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Could not decode __UNNAMED_TYPE__ from value: ${label}`)
  }
}

export const ResumeLanguageLevelValues = [
  "A1",
  "A2",
  "B1",
  "B2",
  "C1",
  "C2",
] as const

export type ChoiceStatusEnum =
  | "PASSED"
  | "CURRENT"
  | "FAILED"

export const ChoiceStatusEnumDecoder: decoders.Decoder<ChoiceStatusEnum> = (
  decoders.either(decoders.number, decoders.string)
  .transform(key => {
    switch (key) {
      case 1: return "PASSED"
      case 2: return "CURRENT"
      case 3: return "FAILED"
    }

    throw new Error(`Could not decode ChoiceStatusEnum from value: ${key}`)
  })
)

export function ChoiceStatusEnumEncoder (label: ChoiceStatusEnum): string | number {
  switch (label) {
    case "PASSED": return 1
    case "CURRENT": return 2
    case "FAILED": return 3
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Could not decode __UNNAMED_TYPE__ from value: ${label}`)
  }
}

export const ChoiceStatusEnumValues = [
  "PASSED",
  "CURRENT",
  "FAILED",
] as const

export type ChoiceTypeEnum =
  | "MANDATORY"
  | "MANDATORY_ELECTIVE"
  | "ELECTIVE"

export const ChoiceTypeEnumDecoder: decoders.Decoder<ChoiceTypeEnum> = (
  decoders.either(decoders.number, decoders.string)
  .transform(key => {
    switch (key) {
      case 1: return "MANDATORY"
      case 2: return "MANDATORY_ELECTIVE"
      case 3: return "ELECTIVE"
    }

    throw new Error(`Could not decode ChoiceTypeEnum from value: ${key}`)
  })
)

export function ChoiceTypeEnumEncoder (label: ChoiceTypeEnum): string | number {
  switch (label) {
    case "MANDATORY": return 1
    case "MANDATORY_ELECTIVE": return 2
    case "ELECTIVE": return 3
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Could not decode __UNNAMED_TYPE__ from value: ${label}`)
  }
}

export const ChoiceTypeEnumValues = [
  "MANDATORY",
  "MANDATORY_ELECTIVE",
  "ELECTIVE",
] as const

export type ChoiceAssignmentAction =
  | "ASSIGN_TO_PROGRAM"
  | "ASSIGN_TO_FREE_CREDITS"
  | "REMOVE_ASSIGNMENT"

export const ChoiceAssignmentActionDecoder: decoders.Decoder<ChoiceAssignmentAction> = (
  decoders.either(decoders.number, decoders.string)
  .transform(key => {
    switch (key) {
      case "assign_to_program": return "ASSIGN_TO_PROGRAM"
      case "assign_to_free_credits": return "ASSIGN_TO_FREE_CREDITS"
      case "remove_assignment": return "REMOVE_ASSIGNMENT"
    }

    throw new Error(`Could not decode ChoiceAssignmentAction from value: ${key}`)
  })
)

export function ChoiceAssignmentActionEncoder (label: ChoiceAssignmentAction): string | number {
  switch (label) {
    case "ASSIGN_TO_PROGRAM": return "assign_to_program"
    case "ASSIGN_TO_FREE_CREDITS": return "assign_to_free_credits"
    case "REMOVE_ASSIGNMENT": return "remove_assignment"
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Could not decode __UNNAMED_TYPE__ from value: ${label}`)
  }
}

export const ChoiceAssignmentActionValues = [
  "ASSIGN_TO_PROGRAM",
  "ASSIGN_TO_FREE_CREDITS",
  "REMOVE_ASSIGNMENT",
] as const

export type AvailableCourseTabType =
  | "ALL"
  | "GENERAL"
  | "PROGRAM"
  | "FREE"
  | "OTHER_PROGRAMS"

export const AvailableCourseTabTypeDecoder: decoders.Decoder<AvailableCourseTabType> = (
  decoders.either(decoders.number, decoders.string)
  .transform(key => {
    switch (key) {
      case "courses_all": return "ALL"
      case "courses_general": return "GENERAL"
      case "program": return "PROGRAM"
      case "courses_free": return "FREE"
      case "other_programs": return "OTHER_PROGRAMS"
    }

    throw new Error(`Could not decode AvailableCourseTabType from value: ${key}`)
  })
)

export function AvailableCourseTabTypeEncoder (label: AvailableCourseTabType): string | number {
  switch (label) {
    case "ALL": return "courses_all"
    case "GENERAL": return "courses_general"
    case "PROGRAM": return "program"
    case "FREE": return "courses_free"
    case "OTHER_PROGRAMS": return "other_programs"
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Could not decode __UNNAMED_TYPE__ from value: ${label}`)
  }
}

export const AvailableCourseTabTypeValues = [
  "ALL",
  "GENERAL",
  "PROGRAM",
  "FREE",
  "OTHER_PROGRAMS",
] as const

export type ResumeTab =
  | "personal"
  | "education"
  | "work"
  | "trainings"
  | "skills"
  | "additional"

export const ResumeTabDecoder: decoders.Decoder<ResumeTab> = (
  decoders.either(decoders.number, decoders.string)
  .transform(key => {
    switch (key) {
      case "TAB_PERSONAL": return "personal"
      case "TAB_EDUCATION": return "education"
      case "TAB_WORK": return "work"
      case "TAB_TRAININGS": return "trainings"
      case "TAB_SKILLS": return "skills"
      case "TAB_ADDITIONAL": return "additional"
    }

    throw new Error(`Could not decode ResumeTab from value: ${key}`)
  })
)

export function ResumeTabEncoder (label: ResumeTab): string | number {
  switch (label) {
    case "personal": return "TAB_PERSONAL"
    case "education": return "TAB_EDUCATION"
    case "work": return "TAB_WORK"
    case "trainings": return "TAB_TRAININGS"
    case "skills": return "TAB_SKILLS"
    case "additional": return "TAB_ADDITIONAL"
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Could not decode __UNNAMED_TYPE__ from value: ${label}`)
  }
}

export const ResumeTabValues = [
  "personal",
  "education",
  "work",
  "trainings",
  "skills",
  "additional",
] as const

export interface MediaFile {
  readonly "id": IDString
  readonly "name": string
  readonly "title": string
  readonly "originalName": string
  readonly "mimeType": string
  readonly "size": number
  readonly "extension": string
  readonly "downloads": number
  readonly "downloadEndpoint": string
  readonly "isPublic": boolean
  readonly "createdAt": string
}

export const MediaFileDecoder: decoders.Decoder<MediaFile> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
      "title": decoders.string,
      "originalName": decoders.string,
      "mimeType": decoders.string,
      "size": decoders.number,
      "extension": decoders.string,
      "downloads": decoders.number,
      "downloadEndpoint": decoders.string,
      "isPublic": decoders.boolean,
      "createdAt": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "title": obj.attributes["title"],
      "originalName": obj.attributes["originalName"],
      "mimeType": obj.attributes["mimeType"],
      "size": obj.attributes["size"],
      "extension": obj.attributes["extension"],
      "downloads": obj.attributes["downloads"],
      "downloadEndpoint": obj.attributes["downloadEndpoint"],
      "isPublic": obj.attributes["isPublic"],
      "createdAt": obj.attributes["createdAt"],
    }
  })
)

export interface DownloadMediaFile {
  readonly "url": string
  readonly "expiresAt": null | string
  readonly "mediaFile": MediaFile
}

export const DownloadMediaFileDecoder: decoders.Decoder<DownloadMediaFile> = (
  decoders.object({
    attributes: decoders.object({
      "url": decoders.string,
      "expiresAt": decoders.nullable(decoders.string),
    }),
    relationships: decoders.object({
      "mediaFile": decoders.object({
        data: MediaFileDecoder
      }),
    }),
  })
  .transform(obj => {
    return {
      "url": obj.attributes["url"],
      "expiresAt": obj.attributes["expiresAt"],
      "mediaFile": obj.relationships["mediaFile"].data,
    }
  })
)

export interface GeneralSettings {
  readonly "locale": LanguageString
  readonly "showMail": boolean
}

export const GeneralSettingsDecoder: decoders.Decoder<GeneralSettings> = (
  decoders.object({
    attributes: decoders.object({
      "locale": LanguageStringDecoder,
      "showMail": decoders.boolean,
    }),
  })
  .transform(obj => {
    return {
      "locale": obj.attributes["locale"],
      "showMail": obj.attributes["showMail"],
    }
  })
)

export interface NotificationsData {
  readonly "mail": boolean
  readonly "sms": boolean
  readonly "push": boolean
}

export const NotificationsDataDecoder: decoders.Decoder<NotificationsData> = (
  decoders.object({
    attributes: decoders.object({
      "mail": decoders.boolean,
      "sms": decoders.boolean,
      "push": decoders.boolean,
    }),
  })
  .transform(obj => {
    return {
      "mail": obj.attributes["mail"],
      "sms": obj.attributes["sms"],
      "push": obj.attributes["push"],
    }
  })
)

export interface NotificationSettings {
  readonly "notifyOnMessageByEmail": boolean
  readonly "notifyOnMessageBySms": boolean
  readonly "notifyOnMessageByPush": boolean
  readonly "notifyOnLectureStartsByEmail": boolean
  readonly "notifyOnLectureStartsBySms": boolean
  readonly "notifyOnLectureStartsByPush": boolean
  readonly "notifyOnNewsAddByEmail": boolean
  readonly "notifyOnNewsAddByPush": boolean
  readonly "notifyOnNewsAddBySms": boolean
  readonly "notifyOnScoreSaveByEmail": boolean
  readonly "notifyOnScoreSaveBySms": boolean
  readonly "notifyOnScoreSaveByPush": boolean
  readonly "notifyOnMaterialAddByEmail": boolean
  readonly "notifyOnMaterialAddBySms": boolean
  readonly "notifyOnMaterialAddByPush": boolean
}

export const NotificationSettingsDecoder: decoders.Decoder<NotificationSettings> = (
  decoders.object({
    attributes: decoders.object({
      "notifyOnMessageByEmail": decoders.boolean,
      "notifyOnMessageBySms": decoders.boolean,
      "notifyOnMessageByPush": decoders.boolean,
      "notifyOnLectureStartsByEmail": decoders.boolean,
      "notifyOnLectureStartsBySms": decoders.boolean,
      "notifyOnLectureStartsByPush": decoders.boolean,
      "notifyOnNewsAddByEmail": decoders.boolean,
      "notifyOnNewsAddByPush": decoders.boolean,
      "notifyOnNewsAddBySms": decoders.boolean,
      "notifyOnScoreSaveByEmail": decoders.boolean,
      "notifyOnScoreSaveBySms": decoders.boolean,
      "notifyOnScoreSaveByPush": decoders.boolean,
      "notifyOnMaterialAddByEmail": decoders.boolean,
      "notifyOnMaterialAddBySms": decoders.boolean,
      "notifyOnMaterialAddByPush": decoders.boolean,
    }),
  })
  .transform(obj => {
    return {
      "notifyOnMessageByEmail": obj.attributes["notifyOnMessageByEmail"],
      "notifyOnMessageBySms": obj.attributes["notifyOnMessageBySms"],
      "notifyOnMessageByPush": obj.attributes["notifyOnMessageByPush"],
      "notifyOnLectureStartsByEmail": obj.attributes["notifyOnLectureStartsByEmail"],
      "notifyOnLectureStartsBySms": obj.attributes["notifyOnLectureStartsBySms"],
      "notifyOnLectureStartsByPush": obj.attributes["notifyOnLectureStartsByPush"],
      "notifyOnNewsAddByEmail": obj.attributes["notifyOnNewsAddByEmail"],
      "notifyOnNewsAddByPush": obj.attributes["notifyOnNewsAddByPush"],
      "notifyOnNewsAddBySms": obj.attributes["notifyOnNewsAddBySms"],
      "notifyOnScoreSaveByEmail": obj.attributes["notifyOnScoreSaveByEmail"],
      "notifyOnScoreSaveBySms": obj.attributes["notifyOnScoreSaveBySms"],
      "notifyOnScoreSaveByPush": obj.attributes["notifyOnScoreSaveByPush"],
      "notifyOnMaterialAddByEmail": obj.attributes["notifyOnMaterialAddByEmail"],
      "notifyOnMaterialAddBySms": obj.attributes["notifyOnMaterialAddBySms"],
      "notifyOnMaterialAddByPush": obj.attributes["notifyOnMaterialAddByPush"],
    }
  })
)

export interface NotificationsSaveRequest {
  readonly "notifyOnMessage": NotificationsData
  readonly "notifyOnLectureStarts": NotificationsData
  readonly "notifyOnScoreSave": NotificationsData
  readonly "notifyOnMaterialAdd": NotificationsData
}

export const NotificationsSaveRequestDecoder: decoders.Decoder<NotificationsSaveRequest> = decoders.object({
  "notifyOnMessage": NotificationsDataDecoder,
  "notifyOnLectureStarts": NotificationsDataDecoder,
  "notifyOnScoreSave": NotificationsDataDecoder,
  "notifyOnMaterialAdd": NotificationsDataDecoder,
})

export interface CourseMaterialsSaveRequest {
  readonly "semId": string
  readonly "title": string
  readonly "url"?: null | string
  readonly "fileUid"?: null | string
}

export const CourseMaterialsSaveRequestDecoder: decoders.Decoder<CourseMaterialsSaveRequest> = decoders.object({
  "semId": decoders.string,
  "title": decoders.string,
  "url": optionalNullableDecoder(decoders.nullable(decoders.string)),
  "fileUid": optionalNullableDecoder(decoders.nullable(decoders.string)),
})

export interface ExportFile {
  readonly "id": IDString
  readonly "downloadUrl": string
  readonly "name": string
  readonly "mimeType": string
  readonly "size": number
  readonly "extension": string
}

export const ExportFileDecoder: decoders.Decoder<ExportFile> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "downloadUrl": decoders.string,
      "name": decoders.string,
      "mimeType": decoders.string,
      "size": decoders.number,
      "extension": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "downloadUrl": obj.attributes["downloadUrl"],
      "name": obj.attributes["name"],
      "mimeType": obj.attributes["mimeType"],
      "size": obj.attributes["size"],
      "extension": obj.attributes["extension"],
    }
  })
)

export interface ProfileStatus {
  readonly "active": boolean
  readonly "message": null | string
}

export const ProfileStatusDecoder: decoders.Decoder<ProfileStatus> = (
  decoders.object({
    attributes: decoders.object({
      "active": decoders.boolean,
      "message": decoders.nullable(decoders.string),
    }),
  })
  .transform(obj => {
    return {
      "active": obj.attributes["active"],
      "message": obj.attributes["message"],
    }
  })
)

export interface UserProfileStudent {
  readonly "id": IDString
  readonly "type": "UserProfileStudent"
  readonly "degree": StudentDegree
  readonly "profileStatus"?: ProfileStatus
}

export const UserProfileStudentDecoder: decoders.Decoder<UserProfileStudent> = (
  decoders.object({
    "id": IDStringDecoder,
    "type": decoders.constant("UserProfileStudent" as const),
    attributes: decoders.object({
      "degree": StudentDegreeDecoder,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "profileStatus": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ProfileStatusDecoder)
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "type": obj["type"],
      "degree": obj.attributes["degree"],
      "profileStatus": obj?.relationships?.["profileStatus"]?.data,
    }
  })
)

export interface UserProfileLecturer {
  readonly "id": IDString
  readonly "type": "UserProfileLecturer"
  readonly "degree": LecturerDegree
  readonly "profileStatus"?: ProfileStatus
}

export const UserProfileLecturerDecoder: decoders.Decoder<UserProfileLecturer> = (
  decoders.object({
    "id": IDStringDecoder,
    "type": decoders.constant("UserProfileLecturer" as const),
    attributes: decoders.object({
      "degree": LecturerDegreeDecoder,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "profileStatus": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ProfileStatusDecoder)
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "type": obj["type"],
      "degree": obj.attributes["degree"],
      "profileStatus": obj?.relationships?.["profileStatus"]?.data,
    }
  })
)

export interface UserProfileAdministrator {
  readonly "id": IDString
  readonly "type": "UserProfileAdministrator"
  readonly "degree": AdministratorDegree
  readonly "profileStatus"?: ProfileStatus
}

export const UserProfileAdministratorDecoder: decoders.Decoder<UserProfileAdministrator> = (
  decoders.object({
    "id": IDStringDecoder,
    "type": decoders.constant("UserProfileAdministrator" as const),
    attributes: decoders.object({
      "degree": AdministratorDegreeDecoder,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "profileStatus": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ProfileStatusDecoder)
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "type": obj["type"],
      "degree": obj.attributes["degree"],
      "profileStatus": obj?.relationships?.["profileStatus"]?.data,
    }
  })
)

export type UserProfile = UserProfileAdministrator | UserProfileLecturer | UserProfileStudent

export const UserProfileDecoder: decoders.Decoder<UserProfile> = decoders.either(
  UserProfileAdministratorDecoder,
  UserProfileLecturerDecoder,
  UserProfileStudentDecoder
)

export interface AuthUser {
  readonly "id": IDString
  readonly "uid": IDString
  readonly "firstName": string
  readonly "lastName": string
  readonly "fullName": string
  readonly "avatar": SVGString
  readonly "photoUrl": null | URLString
  readonly "personalNumber": string
  readonly "birthDate": null | string
  readonly "juridicalAddress": string
  readonly "actualAddress": string
  readonly "email": string
  readonly "gender": string
  readonly "mobileNumber": null | string
  readonly "mobileNumber2": null | string
  readonly "homeNumber": null | string
  readonly "profiles": readonly UserProfile[]
}

export const AuthUserDecoder: decoders.Decoder<AuthUser> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "uid": IDStringDecoder,
      "firstName": decoders.string,
      "lastName": decoders.string,
      "fullName": decoders.string,
      "avatar": SVGStringDecoder,
      "photoUrl": decoders.nullable(URLStringDecoder),
      "personalNumber": decoders.string,
      "birthDate": decoders.nullable(decoders.string),
      "juridicalAddress": decoders.string,
      "actualAddress": decoders.string,
      "email": decoders.string,
      "gender": decoders.string,
      "mobileNumber": decoders.nullable(decoders.string),
      "mobileNumber2": decoders.nullable(decoders.string),
      "homeNumber": decoders.nullable(decoders.string),
    }),
    relationships: decoders.object({
      "profiles": decoders.object({
        data: ArrayObjectDecoder(UserProfileDecoder)
      }),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "uid": obj.attributes["uid"],
      "firstName": obj.attributes["firstName"],
      "lastName": obj.attributes["lastName"],
      "fullName": obj.attributes["fullName"],
      "avatar": obj.attributes["avatar"],
      "photoUrl": obj.attributes["photoUrl"],
      "personalNumber": obj.attributes["personalNumber"],
      "birthDate": obj.attributes["birthDate"],
      "juridicalAddress": obj.attributes["juridicalAddress"],
      "actualAddress": obj.attributes["actualAddress"],
      "email": obj.attributes["email"],
      "gender": obj.attributes["gender"],
      "mobileNumber": obj.attributes["mobileNumber"],
      "mobileNumber2": obj.attributes["mobileNumber2"],
      "homeNumber": obj.attributes["homeNumber"],
      "profiles": obj.relationships["profiles"].data,
    }
  })
)

export interface Semester {
  readonly "id": IDString
  readonly "name": string
  readonly "isActive": boolean
}

export const SemesterDecoder: decoders.Decoder<Semester> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
      "isActive": decoders.boolean,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "isActive": obj.attributes["isActive"],
    }
  })
)

export interface Regime {
  readonly "id": IDString
  readonly "name": string
  readonly "message": null | string
  readonly "chooseAllowed": boolean
  readonly "functionalIsLimited": boolean
}

export const RegimeDecoder: decoders.Decoder<Regime> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
      "message": decoders.nullable(decoders.string),
      "chooseAllowed": decoders.boolean,
      "functionalIsLimited": decoders.boolean,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "message": obj.attributes["message"],
      "chooseAllowed": obj.attributes["chooseAllowed"],
      "functionalIsLimited": obj.attributes["functionalIsLimited"],
    }
  })
)

export interface MessageUnreadCount {
  readonly "count": number
}

export const MessageUnreadCountDecoder: decoders.Decoder<MessageUnreadCount> = (
  decoders.object({
    attributes: decoders.object({
      "count": decoders.number,
    }),
  })
  .transform(obj => {
    return {
      "count": obj.attributes["count"],
    }
  })
)

export interface SyllabusFile {
  readonly "title": string
  readonly "url": string
}

export const SyllabusFileDecoder: decoders.Decoder<SyllabusFile> = (
  decoders.object({
    attributes: decoders.object({
      "title": decoders.string,
      "url": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "title": obj.attributes["title"],
      "url": obj.attributes["url"],
    }
  })
)

export interface CourseSyllabusFile {
  readonly "id": IDString
  readonly "type": string
  readonly "createdAt": string
  readonly "downloads": number
  readonly "extension": string
  readonly "iconClass": string
  readonly "isPublic": boolean
  readonly "mimeType": string
  readonly "name": string
  readonly "originalName": string
  readonly "size": number
  readonly "title": string
}

export const CourseSyllabusFileDecoder: decoders.Decoder<CourseSyllabusFile> = (
  decoders.object({
    "id": IDStringDecoder,
    "type": decoders.string,
    attributes: decoders.object({
      "createdAt": decoders.string,
      "downloads": decoders.number,
      "extension": decoders.string,
      "iconClass": decoders.string,
      "isPublic": decoders.boolean,
      "mimeType": decoders.string,
      "name": decoders.string,
      "originalName": decoders.string,
      "size": decoders.number,
      "title": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "type": obj["type"],
      "createdAt": obj.attributes["createdAt"],
      "downloads": obj.attributes["downloads"],
      "extension": obj.attributes["extension"],
      "iconClass": obj.attributes["iconClass"],
      "isPublic": obj.attributes["isPublic"],
      "mimeType": obj.attributes["mimeType"],
      "name": obj.attributes["name"],
      "originalName": obj.attributes["originalName"],
      "size": obj.attributes["size"],
      "title": obj.attributes["title"],
    }
  })
)

export interface LecturerSyllabus {
  readonly "duration": null | string
  readonly "lecturers": string
  readonly "hours": string
  readonly "prerequisites": string
  readonly "methods": string
  readonly "mission": string
  readonly "topics": string
  readonly "outcomes": string
  readonly "evaluation": string
  readonly "resources": string
  readonly "otherResources": string
  readonly "schedule": string
  readonly "knowledge": boolean
  readonly "applying": boolean
  readonly "judgments": boolean
  readonly "communications": boolean
  readonly "learning": boolean
  readonly "values": boolean
  readonly "syllabusFile"?: CourseSyllabusFile
}

export const LecturerSyllabusDecoder: decoders.Decoder<LecturerSyllabus> = (
  decoders.object({
    attributes: decoders.object({
      "duration": decoders.nullable(decoders.string),
      "lecturers": decoders.string,
      "hours": decoders.string,
      "prerequisites": decoders.string,
      "methods": decoders.string,
      "mission": decoders.string,
      "topics": decoders.string,
      "outcomes": decoders.string,
      "evaluation": decoders.string,
      "resources": decoders.string,
      "otherResources": decoders.string,
      "schedule": decoders.string,
      "knowledge": decoders.boolean,
      "applying": decoders.boolean,
      "judgments": decoders.boolean,
      "communications": decoders.boolean,
      "learning": decoders.boolean,
      "values": decoders.boolean,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "syllabusFile": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(CourseSyllabusFileDecoder)
      })),
    })),
  })
  .transform(obj => {
    return {
      "duration": obj.attributes["duration"],
      "lecturers": obj.attributes["lecturers"],
      "hours": obj.attributes["hours"],
      "prerequisites": obj.attributes["prerequisites"],
      "methods": obj.attributes["methods"],
      "mission": obj.attributes["mission"],
      "topics": obj.attributes["topics"],
      "outcomes": obj.attributes["outcomes"],
      "evaluation": obj.attributes["evaluation"],
      "resources": obj.attributes["resources"],
      "otherResources": obj.attributes["otherResources"],
      "schedule": obj.attributes["schedule"],
      "knowledge": obj.attributes["knowledge"],
      "applying": obj.attributes["applying"],
      "judgments": obj.attributes["judgments"],
      "communications": obj.attributes["communications"],
      "learning": obj.attributes["learning"],
      "values": obj.attributes["values"],
      "syllabusFile": obj?.relationships?.["syllabusFile"]?.data,
    }
  })
)

export interface CourseChoiceType {
  readonly "type": ChoiceTypeEnum
}

export const CourseChoiceTypeDecoder: decoders.Decoder<CourseChoiceType> = (
  decoders.object({
    attributes: decoders.object({
      "type": ChoiceTypeEnumDecoder,
    }),
  })
  .transform(obj => {
    return {
      "type": obj.attributes["type"],
    }
  })
)

export interface FinalScore {
  readonly "finalScore": number
  readonly "credits": number
}

export const FinalScoreDecoder: decoders.Decoder<FinalScore> = (
  decoders.object({
    attributes: decoders.object({
      "finalScore": decoders.number,
      "credits": decoders.number,
    }),
  })
  .transform(obj => {
    return {
      "finalScore": obj.attributes["finalScore"],
      "credits": obj.attributes["credits"],
    }
  })
)

export interface Course {
  readonly "id": IDString
  readonly "name": string
  readonly "fullName": string
  readonly "degree": StudentDegree
  readonly "groupName": string
  readonly "code": string
  readonly "programCode": string
  readonly "credits": number
  readonly "isEnabledForChoose": boolean
  readonly "isGeneral": boolean
  readonly "syllabus"?: LecturerSyllabus
  readonly "courseChoiceType"?: CourseChoiceType
}

export const CourseDecoder: decoders.Decoder<Course> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
      "fullName": decoders.string,
      "degree": StudentDegreeDecoder,
      "groupName": decoders.string,
      "code": decoders.string,
      "programCode": decoders.string,
      "credits": decoders.number,
      "isEnabledForChoose": decoders.boolean,
      "isGeneral": decoders.boolean,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "syllabus": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(LecturerSyllabusDecoder)
      })),
      "courseChoiceType": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(CourseChoiceTypeDecoder)
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "fullName": obj.attributes["fullName"],
      "degree": obj.attributes["degree"],
      "groupName": obj.attributes["groupName"],
      "code": obj.attributes["code"],
      "programCode": obj.attributes["programCode"],
      "credits": obj.attributes["credits"],
      "isEnabledForChoose": obj.attributes["isEnabledForChoose"],
      "isGeneral": obj.attributes["isGeneral"],
      "syllabus": obj?.relationships?.["syllabus"]?.data,
      "courseChoiceType": obj?.relationships?.["courseChoiceType"]?.data,
    }
  })
)

export interface CourseScore {
  readonly "criteria": string
  readonly "score": null | number
}

export const CourseScoreDecoder: decoders.Decoder<CourseScore> = (
  decoders.object({
    attributes: decoders.object({
      "criteria": decoders.string,
      "score": decoders.nullable(decoders.number),
    }),
  })
  .transform(obj => {
    return {
      "criteria": obj.attributes["criteria"],
      "score": obj.attributes["score"],
    }
  })
)

export interface ChoiceStatus {
  readonly "canChoose": boolean
  readonly "canRemoveChoice": boolean
  readonly "canRechoose": boolean
  readonly "canRemoveRechoose": boolean
  readonly "isScheduleInConflict": boolean
  readonly "isChosen": boolean
  readonly "chooseError": null | string
  readonly "removeChoiceError": null | string
  readonly "rechooseError": null | string
  readonly "scheduleConflictMessage": null | string
}

export const ChoiceStatusDecoder: decoders.Decoder<ChoiceStatus> = (
  decoders.object({
    attributes: decoders.object({
      "canChoose": decoders.boolean,
      "canRemoveChoice": decoders.boolean,
      "canRechoose": decoders.boolean,
      "canRemoveRechoose": decoders.boolean,
      "isScheduleInConflict": decoders.boolean,
      "isChosen": decoders.boolean,
      "chooseError": decoders.nullable(decoders.string),
      "removeChoiceError": decoders.nullable(decoders.string),
      "rechooseError": decoders.nullable(decoders.string),
      "scheduleConflictMessage": decoders.nullable(decoders.string),
    }),
  })
  .transform(obj => {
    return {
      "canChoose": obj.attributes["canChoose"],
      "canRemoveChoice": obj.attributes["canRemoveChoice"],
      "canRechoose": obj.attributes["canRechoose"],
      "canRemoveRechoose": obj.attributes["canRemoveRechoose"],
      "isScheduleInConflict": obj.attributes["isScheduleInConflict"],
      "isChosen": obj.attributes["isChosen"],
      "chooseError": obj.attributes["chooseError"],
      "removeChoiceError": obj.attributes["removeChoiceError"],
      "rechooseError": obj.attributes["rechooseError"],
      "scheduleConflictMessage": obj.attributes["scheduleConflictMessage"],
    }
  })
)

export interface ManageSyllabus {
  readonly "canEdit": boolean
}

export const ManageSyllabusDecoder: decoders.Decoder<ManageSyllabus> = (
  decoders.object({
    attributes: decoders.object({
      "canEdit": decoders.boolean,
    }),
  })
  .transform(obj => {
    return {
      "canEdit": obj.attributes["canEdit"],
    }
  })
)

export interface CoursePaperCriteria {
  readonly "id": IDString
  readonly "name": string
  readonly "acr": string
  readonly "maxPossible": number
  readonly "type": number
}

export const CoursePaperCriteriaDecoder: decoders.Decoder<CoursePaperCriteria> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
      "acr": decoders.string,
      "maxPossible": decoders.number,
      "type": decoders.number,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "acr": obj.attributes["acr"],
      "maxPossible": obj.attributes["maxPossible"],
      "type": obj.attributes["type"],
    }
  })
)

export interface PaperCriteria {
  readonly "id": IDString
  readonly "fullName": string
  readonly "acr": string
  readonly "max": number
  readonly "min": number
  readonly "canEdit": boolean
  readonly "type": number
}

export const PaperCriteriaDecoder: decoders.Decoder<PaperCriteria> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "fullName": decoders.string,
      "acr": decoders.string,
      "max": decoders.number,
      "min": decoders.number,
      "canEdit": decoders.boolean,
      "type": decoders.number,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "fullName": obj.attributes["fullName"],
      "acr": obj.attributes["acr"],
      "max": obj.attributes["max"],
      "min": obj.attributes["min"],
      "canEdit": obj.attributes["canEdit"],
      "type": obj.attributes["type"],
    }
  })
)

export interface SavePaperCriteria {
  readonly "criteriaId": string
  readonly "min": number
  readonly "max": number
}

export const SavePaperCriteriaDecoder: decoders.Decoder<SavePaperCriteria> = decoders.object({
  "criteriaId": decoders.string,
  "min": decoders.number,
  "max": decoders.number,
})

export interface Paper {
  readonly "id": IDString
  readonly "name": string
  readonly "active": boolean
  readonly "intermediateScoreLimit": number
  readonly "criterions"?: readonly PaperCriteria[]
  readonly "manage"?: ManageSyllabus
}

export const PaperDecoder: decoders.Decoder<Paper> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
      "active": decoders.boolean,
      "intermediateScoreLimit": decoders.number,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "criterions": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(PaperCriteriaDecoder))
      })),
      "manage": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ManageSyllabusDecoder)
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "active": obj.attributes["active"],
      "intermediateScoreLimit": obj.attributes["intermediateScoreLimit"],
      "criterions": obj?.relationships?.["criterions"]?.data,
      "manage": obj?.relationships?.["manage"]?.data,
    }
  })
)

export interface Group {
  readonly "id": IDString
  readonly "name": string
  readonly "maxQuota": number
  readonly "lecturers"?: readonly User[]
  readonly "course"?: Course
  readonly "choiceStatus"?: ChoiceStatus
  readonly "paper"?: Paper
  readonly "choiceCount"?: {
    readonly "count": number
  }
}

export const GroupDecoder: decoders.Decoder<Group> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
      "maxQuota": decoders.number,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "lecturers": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(decoders.lazy(() => UserDecoder)))
      })),
      "course": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(CourseDecoder)
      })),
      "choiceStatus": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ChoiceStatusDecoder)
      })),
      "paper": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(PaperDecoder)
      })),
      "choiceCount": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder((
          decoders.object({
            attributes: decoders.object({
              "count": decoders.number,
            }),
          })
          .transform(obj => {
            return {
              "count": obj.attributes["count"],
            }
          })
        ))
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "maxQuota": obj.attributes["maxQuota"],
      "lecturers": obj?.relationships?.["lecturers"]?.data,
      "course": obj?.relationships?.["course"]?.data,
      "choiceStatus": obj?.relationships?.["choiceStatus"]?.data,
      "paper": obj?.relationships?.["paper"]?.data,
      "choiceCount": obj?.relationships?.["choiceCount"]?.data,
    }
  })
)

export interface GroupSchedule {
  readonly "id": null | IDString
  readonly "day": string
  readonly "date": string
  readonly "startTime": string
  readonly "endTime": string
  readonly "locationName": string
  readonly "info": null | string
  readonly "lectureType": null | string
  readonly "scheduleConflictMessage": null | string
  readonly "lecturers"?: readonly User[]
}

export const GroupScheduleDecoder: decoders.Decoder<GroupSchedule> = (
  decoders.object({
    "id": decoders.nullable(IDStringDecoder),
    attributes: decoders.object({
      "day": decoders.string,
      "date": decoders.string,
      "startTime": decoders.string,
      "endTime": decoders.string,
      "locationName": decoders.string,
      "info": decoders.nullable(decoders.string),
      "lectureType": decoders.nullable(decoders.string),
      "scheduleConflictMessage": decoders.nullable(decoders.string),
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "lecturers": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(decoders.lazy(() => UserDecoder)))
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "day": obj.attributes["day"],
      "date": obj.attributes["date"],
      "startTime": obj.attributes["startTime"],
      "endTime": obj.attributes["endTime"],
      "locationName": obj.attributes["locationName"],
      "info": obj.attributes["info"],
      "lectureType": obj.attributes["lectureType"],
      "scheduleConflictMessage": obj.attributes["scheduleConflictMessage"],
      "lecturers": obj?.relationships?.["lecturers"]?.data,
    }
  })
)

export interface ChoiceAssignmentStatus {
  readonly "programId": null | IDString
  readonly "programName": null | string
  readonly "action": ChoiceAssignmentAction
}

export const ChoiceAssignmentStatusDecoder: decoders.Decoder<ChoiceAssignmentStatus> = (
  decoders.object({
    attributes: decoders.object({
      "programId": decoders.nullable(IDStringDecoder),
      "programName": decoders.nullable(decoders.string),
      "action": ChoiceAssignmentActionDecoder,
    }),
  })
  .transform(obj => {
    return {
      "programId": obj.attributes["programId"],
      "programName": obj.attributes["programName"],
      "action": obj.attributes["action"],
    }
  })
)

export interface Choice {
  readonly "id": IDString
  readonly "credits": number
  readonly "score": number
  readonly "isLatChoice": boolean
  readonly "latScore": string
  readonly "courseName": string
  readonly "courseCode": string
  readonly "courseCredits": number
  readonly "isGeneral": boolean
  readonly "creditType": string
  readonly "status": ChoiceStatusEnum
  readonly "course"?: Course
  readonly "group"?: Group
  readonly "scores"?: readonly CourseScore[]
  readonly "courseChoiceType"?: CourseChoiceType
  readonly "assignmentStatuses"?: readonly ChoiceAssignmentStatus[]
}

export const ChoiceDecoder: decoders.Decoder<Choice> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "credits": decoders.number,
      "score": decoders.number,
      "isLatChoice": decoders.boolean,
      "latScore": decoders.string,
      "courseName": decoders.string,
      "courseCode": decoders.string,
      "courseCredits": decoders.number,
      "isGeneral": decoders.boolean,
      "creditType": decoders.string,
      "status": ChoiceStatusEnumDecoder,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "course": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(CourseDecoder)
      })),
      "group": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(GroupDecoder)
      })),
      "scores": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(CourseScoreDecoder))
      })),
      "courseChoiceType": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(CourseChoiceTypeDecoder)
      })),
      "assignmentStatuses": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(ChoiceAssignmentStatusDecoder))
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "credits": obj.attributes["credits"],
      "score": obj.attributes["score"],
      "isLatChoice": obj.attributes["isLatChoice"],
      "latScore": obj.attributes["latScore"],
      "courseName": obj.attributes["courseName"],
      "courseCode": obj.attributes["courseCode"],
      "courseCredits": obj.attributes["courseCredits"],
      "isGeneral": obj.attributes["isGeneral"],
      "creditType": obj.attributes["creditType"],
      "status": obj.attributes["status"],
      "course": obj?.relationships?.["course"]?.data,
      "group": obj?.relationships?.["group"]?.data,
      "scores": obj?.relationships?.["scores"]?.data,
      "courseChoiceType": obj?.relationships?.["courseChoiceType"]?.data,
      "assignmentStatuses": obj?.relationships?.["assignmentStatuses"]?.data,
    }
  })
)

export interface LibraryBook {
  readonly "id": IDString
  readonly "barcode": string
  readonly "author": string
  readonly "title": string
  readonly "place": string
  readonly "takenAt": string
  readonly "returnedAt": string
  readonly "isOverdue": boolean
}

export const LibraryBookDecoder: decoders.Decoder<LibraryBook> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "barcode": decoders.string,
      "author": decoders.string,
      "title": decoders.string,
      "place": decoders.string,
      "takenAt": decoders.string,
      "returnedAt": decoders.string,
      "isOverdue": decoders.boolean,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "barcode": obj.attributes["barcode"],
      "author": obj.attributes["author"],
      "title": obj.attributes["title"],
      "place": obj.attributes["place"],
      "takenAt": obj.attributes["takenAt"],
      "returnedAt": obj.attributes["returnedAt"],
      "isOverdue": obj.attributes["isOverdue"],
    }
  })
)

export interface LibraryBooksTaken {
  readonly "overdue": number
  readonly "total": number
}

export const LibraryBooksTakenDecoder: decoders.Decoder<LibraryBooksTaken> = (
  decoders.object({
    attributes: decoders.object({
      "overdue": decoders.number,
      "total": decoders.number,
    }),
  })
  .transform(obj => {
    return {
      "overdue": obj.attributes["overdue"],
      "total": obj.attributes["total"],
    }
  })
)

export interface BillingContractObligation {
  readonly "amount": string
  readonly "distributedAmount": string
  readonly "distributedPenalty": string
  readonly "totalAmount": string
  readonly "date": string
}

export const BillingContractObligationDecoder: decoders.Decoder<BillingContractObligation> = (
  decoders.object({
    attributes: decoders.object({
      "amount": decoders.string,
      "distributedAmount": decoders.string,
      "distributedPenalty": decoders.string,
      "totalAmount": decoders.string,
      "date": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "amount": obj.attributes["amount"],
      "distributedAmount": obj.attributes["distributedAmount"],
      "distributedPenalty": obj.attributes["distributedPenalty"],
      "totalAmount": obj.attributes["totalAmount"],
      "date": obj.attributes["date"],
    }
  })
)

export interface BillingContract {
  readonly "name": string
  readonly "type": string
  readonly "obligations": readonly BillingContractObligation[]
}

export const BillingContractDecoder: decoders.Decoder<BillingContract> = (
  decoders.object({
    attributes: decoders.object({
      "name": decoders.string,
      "type": decoders.string,
    }),
    relationships: decoders.object({
      "obligations": decoders.object({
        data: ArrayObjectDecoder(BillingContractObligationDecoder)
      }),
    }),
  })
  .transform(obj => {
    return {
      "name": obj.attributes["name"],
      "type": obj.attributes["type"],
      "obligations": obj.relationships["obligations"].data,
    }
  })
)

export interface BillingSchedule {
  readonly "semesterName": string
  readonly "contracts": readonly BillingContract[]
}

export const BillingScheduleDecoder: decoders.Decoder<BillingSchedule> = (
  decoders.object({
    attributes: decoders.object({
      "semesterName": decoders.string,
    }),
    relationships: decoders.object({
      "contracts": decoders.object({
        data: ArrayObjectDecoder(BillingContractDecoder)
      }),
    }),
  })
  .transform(obj => {
    return {
      "semesterName": obj.attributes["semesterName"],
      "contracts": obj.relationships["contracts"].data,
    }
  })
)

export interface BillingDeposit {
  readonly "facultyName": string
  readonly "amount": string
  readonly "date": string
}

export const BillingDepositDecoder: decoders.Decoder<BillingDeposit> = (
  decoders.object({
    attributes: decoders.object({
      "facultyName": decoders.string,
      "amount": decoders.string,
      "date": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "facultyName": obj.attributes["facultyName"],
      "amount": obj.attributes["amount"],
      "date": obj.attributes["date"],
    }
  })
)

export interface BillingBalance {
  readonly "balance": number
  readonly "updatedAt": null | string
}

export const BillingBalanceDecoder: decoders.Decoder<BillingBalance> = (
  decoders.object({
    attributes: decoders.object({
      "balance": decoders.number,
      "updatedAt": decoders.nullable(decoders.string),
    }),
  })
  .transform(obj => {
    return {
      "balance": obj.attributes["balance"],
      "updatedAt": obj.attributes["updatedAt"],
    }
  })
)

export interface Pagination {
  readonly "total": number
  readonly "count": number
  readonly "perPage": number
  readonly "currentPage": number
  readonly "totalPages": number
  readonly "links": {
    readonly "next": null | string
    readonly "previous": null | string
  }
}

export const PaginationDecoder: decoders.Decoder<Pagination> = decoders.object({
  "total": decoders.number,
  "count": decoders.number,
  "perPage": decoders.number,
  "currentPage": decoders.number,
  "totalPages": decoders.number,
  "links": decoders.object({
    "next": decoders.nullable(decoders.string),
    "previous": decoders.nullable(decoders.string),
  }),
})

export interface AuthLog {
  readonly "id": IDString
  readonly "ip": string
  readonly "userAgent": string
  readonly "createdAt": string
}

export const AuthLogDecoder: decoders.Decoder<AuthLog> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "ip": decoders.string,
      "userAgent": decoders.string,
      "createdAt": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "ip": obj.attributes["ip"],
      "userAgent": obj.attributes["userAgent"],
      "createdAt": obj.attributes["createdAt"],
    }
  })
)

export interface StudentScore {
  readonly "criteriaId": number
  readonly "score": null | number
}

export const StudentScoreDecoder: decoders.Decoder<StudentScore> = (
  decoders.object({
    attributes: decoders.object({
      "criteriaId": decoders.number,
      "score": decoders.nullable(decoders.number),
    }),
  })
  .transform(obj => {
    return {
      "criteriaId": obj.attributes["criteriaId"],
      "score": obj.attributes["score"],
    }
  })
)

export interface PortfolioMentorManage {
  readonly "canSubmitWork": boolean
}

export const PortfolioMentorManageDecoder: decoders.Decoder<PortfolioMentorManage> = (
  decoders.object({
    attributes: decoders.object({
      "canSubmitWork": decoders.boolean,
    }),
  })
  .transform(obj => {
    return {
      "canSubmitWork": obj.attributes["canSubmitWork"],
    }
  })
)

export interface ManageSection {
  readonly "canSubmitWork": boolean
}

export const ManageSectionDecoder: decoders.Decoder<ManageSection> = (
  decoders.object({
    attributes: decoders.object({
      "canSubmitWork": decoders.boolean,
    }),
  })
  .transform(obj => {
    return {
      "canSubmitWork": obj.attributes["canSubmitWork"],
    }
  })
)

export interface ManageWork {
  readonly "canSubmitWork"?: boolean
}

export const ManageWorkDecoder: decoders.Decoder<ManageWork> = (
  decoders.object({
    relationships: optionalNullableDecoder(decoders.object({
      "canSubmitWork": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(decoders.boolean)
      })),
    })),
  })
  .transform(obj => {
    return {
      "canSubmitWork": obj?.relationships?.["canSubmitWork"]?.data,
    }
  })
)

export interface MentorWorkManage {
  readonly "canRejectWork": boolean
  readonly "canSubmitScore": boolean
}

export const MentorWorkManageDecoder: decoders.Decoder<MentorWorkManage> = (
  decoders.object({
    attributes: decoders.object({
      "canRejectWork": decoders.boolean,
      "canSubmitScore": decoders.boolean,
    }),
  })
  .transform(obj => {
    return {
      "canRejectWork": obj.attributes["canRejectWork"],
      "canSubmitScore": obj.attributes["canSubmitScore"],
    }
  })
)

export interface ProfileForMessageView {
  readonly "type": string
  readonly "id": IDString
  readonly "degree": number
  readonly "semester": number
}

export const ProfileForMessageViewDecoder: decoders.Decoder<ProfileForMessageView> = (
  decoders.object({
    "type": decoders.string,
    "id": IDStringDecoder,
    attributes: decoders.object({
      "degree": decoders.number,
      "semester": decoders.number,
    }),
  })
  .transform(obj => {
    return {
      "type": obj["type"],
      "id": obj["id"],
      "degree": obj.attributes["degree"],
      "semester": obj.attributes["semester"],
    }
  })
)

export interface User {
  readonly "id": IDString
  readonly "uid": string
  readonly "email": null | string
  readonly "firstName": string
  readonly "lastName": string
  readonly "fullName": string
  readonly "avatar": string
  readonly "photoUrl": null | string
  readonly "mobileNumber": null | string
  readonly "mobileNumber2": null | string
  readonly "homeNumber": null | string
  readonly "profiles"?: readonly UserProfile[]
  readonly "group"?: Group
  readonly "scores"?: readonly StudentScore[]
  readonly "profileForMessageView"?: ProfileForMessageView
}

export const UserDecoder: decoders.Decoder<User> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "uid": decoders.string,
      "email": decoders.nullable(decoders.string),
      "firstName": decoders.string,
      "lastName": decoders.string,
      "fullName": decoders.string,
      "avatar": decoders.string,
      "photoUrl": decoders.nullable(decoders.string),
      "mobileNumber": decoders.nullable(decoders.string),
      "mobileNumber2": decoders.nullable(decoders.string),
      "homeNumber": decoders.nullable(decoders.string),
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "profiles": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(UserProfileDecoder))
      })),
      "group": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(GroupDecoder)
      })),
      "scores": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(StudentScoreDecoder))
      })),
      "profileForMessageView": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ProfileForMessageViewDecoder)
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "uid": obj.attributes["uid"],
      "email": obj.attributes["email"],
      "firstName": obj.attributes["firstName"],
      "lastName": obj.attributes["lastName"],
      "fullName": obj.attributes["fullName"],
      "avatar": obj.attributes["avatar"],
      "photoUrl": obj.attributes["photoUrl"],
      "mobileNumber": obj.attributes["mobileNumber"],
      "mobileNumber2": obj.attributes["mobileNumber2"],
      "homeNumber": obj.attributes["homeNumber"],
      "profiles": obj?.relationships?.["profiles"]?.data,
      "group": obj?.relationships?.["group"]?.data,
      "scores": obj?.relationships?.["scores"]?.data,
      "profileForMessageView": obj?.relationships?.["profileForMessageView"]?.data,
    }
  })
)

export interface AdministratorUser {
  readonly "id": IDString
  readonly "uid": string
  readonly "email": string
  readonly "personalNumber": string
  readonly "firstName": string
  readonly "lastName": string
  readonly "fullName": string
  readonly "avatar": string
  readonly "photoUrl": null | string
  readonly "mobileNumber": null | string
  readonly "mobileNumber2": null | string
  readonly "homeNumber": null | string
  readonly "manage"?: {
    readonly "canEmulate": boolean
  }
}

export const AdministratorUserDecoder: decoders.Decoder<AdministratorUser> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "uid": decoders.string,
      "email": decoders.string,
      "personalNumber": decoders.string,
      "firstName": decoders.string,
      "lastName": decoders.string,
      "fullName": decoders.string,
      "avatar": decoders.string,
      "photoUrl": decoders.nullable(decoders.string),
      "mobileNumber": decoders.nullable(decoders.string),
      "mobileNumber2": decoders.nullable(decoders.string),
      "homeNumber": decoders.nullable(decoders.string),
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "manage": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder((
          decoders.object({
            attributes: decoders.object({
              "canEmulate": decoders.boolean,
            }),
          })
          .transform(obj => {
            return {
              "canEmulate": obj.attributes["canEmulate"],
            }
          })
        ))
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "uid": obj.attributes["uid"],
      "email": obj.attributes["email"],
      "personalNumber": obj.attributes["personalNumber"],
      "firstName": obj.attributes["firstName"],
      "lastName": obj.attributes["lastName"],
      "fullName": obj.attributes["fullName"],
      "avatar": obj.attributes["avatar"],
      "photoUrl": obj.attributes["photoUrl"],
      "mobileNumber": obj.attributes["mobileNumber"],
      "mobileNumber2": obj.attributes["mobileNumber2"],
      "homeNumber": obj.attributes["homeNumber"],
      "manage": obj?.relationships?.["manage"]?.data,
    }
  })
)

export interface UserState {
  readonly "billingBalance": null | number
  readonly "libraryBalance": null | number
  readonly "newsUnread": null | number
  readonly "messagesUnread": null | number
  readonly "notificationsUnread": null | number
  readonly "rechoose": boolean
  readonly "surveyExists": boolean
  readonly "regime": Regime
}

export const UserStateDecoder: decoders.Decoder<UserState> = (
  decoders.object({
    attributes: decoders.object({
      "billingBalance": decoders.nullable(decoders.number),
      "libraryBalance": decoders.nullable(decoders.number),
      "newsUnread": decoders.nullable(decoders.number),
      "messagesUnread": decoders.nullable(decoders.number),
      "notificationsUnread": decoders.nullable(decoders.number),
      "rechoose": decoders.boolean,
      "surveyExists": decoders.boolean,
    }),
    relationships: decoders.object({
      "regime": decoders.object({
        data: RegimeDecoder
      }),
    }),
  })
  .transform(obj => {
    return {
      "billingBalance": obj.attributes["billingBalance"],
      "libraryBalance": obj.attributes["libraryBalance"],
      "newsUnread": obj.attributes["newsUnread"],
      "messagesUnread": obj.attributes["messagesUnread"],
      "notificationsUnread": obj.attributes["notificationsUnread"],
      "rechoose": obj.attributes["rechoose"],
      "surveyExists": obj.attributes["surveyExists"],
      "regime": obj.relationships["regime"].data,
    }
  })
)

export interface Message {
  readonly "id": IDString
  readonly "subject": string
  readonly "messageType": MessageTypeEnum
  readonly "body": string
  readonly "seen": boolean
  readonly "semId": IDString
  readonly "sentAt": string
  readonly "senderProfileId": null | IDString
  readonly "readAt": null | string
  readonly "sender"?: User
  readonly "receiver"?: User
}

export const MessageDecoder: decoders.Decoder<Message> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "subject": decoders.string,
      "messageType": MessageTypeEnumDecoder,
      "body": decoders.string,
      "seen": decoders.boolean,
      "semId": IDStringDecoder,
      "sentAt": decoders.string,
      "senderProfileId": decoders.nullable(IDStringDecoder),
      "readAt": decoders.nullable(decoders.string),
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "sender": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(UserDecoder)
      })),
      "receiver": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(UserDecoder)
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "subject": obj.attributes["subject"],
      "messageType": obj.attributes["messageType"],
      "body": obj.attributes["body"],
      "seen": obj.attributes["seen"],
      "semId": obj.attributes["semId"],
      "sentAt": obj.attributes["sentAt"],
      "senderProfileId": obj.attributes["senderProfileId"],
      "readAt": obj.attributes["readAt"],
      "sender": obj?.relationships?.["sender"]?.data,
      "receiver": obj?.relationships?.["receiver"]?.data,
    }
  })
)

export interface Hour {
  readonly "id": IDString
  readonly "startTime": string
  readonly "endTime": string
  readonly "times": string
}

export const HourDecoder: decoders.Decoder<Hour> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "startTime": decoders.string,
      "endTime": decoders.string,
      "times": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "startTime": obj.attributes["startTime"],
      "endTime": obj.attributes["endTime"],
      "times": obj.attributes["times"],
    }
  })
)

export interface DuplicateSchedule {
  readonly "id": IDString
  readonly "locationName": string
  readonly "info": null | string
  readonly "lectureTypeName": string
  readonly "lectureFormatName": string
}

export const DuplicateScheduleDecoder: decoders.Decoder<DuplicateSchedule> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "locationName": decoders.string,
      "info": decoders.nullable(decoders.string),
      "lectureTypeName": decoders.string,
      "lectureFormatName": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "locationName": obj.attributes["locationName"],
      "info": obj.attributes["info"],
      "lectureTypeName": obj.attributes["lectureTypeName"],
      "lectureFormatName": obj.attributes["lectureFormatName"],
    }
  })
)

export interface Schedule {
  readonly "id": IDString
  readonly "locationName": null | string
  readonly "info": null | string
  readonly "lectureTypeName": string
  readonly "lectureFormatName": string
  readonly "hour": Hour
  readonly "course": Course
  readonly "group": Group
  readonly "lecturers"?: readonly User[]
  readonly "duplicate"?: DuplicateSchedule
}

export const ScheduleDecoder: decoders.Decoder<Schedule> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "locationName": decoders.nullable(decoders.string),
      "info": decoders.nullable(decoders.string),
      "lectureTypeName": decoders.string,
      "lectureFormatName": decoders.string,
    }),
    relationships: decoders.object({
      "hour": decoders.object({
        data: HourDecoder
      }),
      "course": decoders.object({
        data: CourseDecoder
      }),
      "group": decoders.object({
        data: GroupDecoder
      }),
      "lecturers": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(UserDecoder))
      })),
      "duplicate": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(DuplicateScheduleDecoder)
      })),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "locationName": obj.attributes["locationName"],
      "info": obj.attributes["info"],
      "lectureTypeName": obj.attributes["lectureTypeName"],
      "lectureFormatName": obj.attributes["lectureFormatName"],
      "hour": obj.relationships["hour"].data,
      "course": obj.relationships["course"].data,
      "group": obj.relationships["group"].data,
      "lecturers": obj?.relationships?.["lecturers"]?.data,
      "duplicate": obj?.relationships?.["duplicate"]?.data,
    }
  })
)

export interface ScheduleDay {
  readonly "number": number
  readonly "name": string
  readonly "date": string
  readonly "schedules": readonly Schedule[]
}

export const ScheduleDayDecoder: decoders.Decoder<ScheduleDay> = (
  decoders.object({
    attributes: decoders.object({
      "number": decoders.number,
      "name": decoders.string,
      "date": decoders.string,
    }),
    relationships: decoders.object({
      "schedules": decoders.object({
        data: ArrayObjectDecoder(ScheduleDecoder)
      }),
    }),
  })
  .transform(obj => {
    return {
      "number": obj.attributes["number"],
      "name": obj.attributes["name"],
      "date": obj.attributes["date"],
      "schedules": obj.relationships["schedules"].data,
    }
  })
)

export interface CardSemester {
  readonly "name": string
  readonly "choices"?: readonly Choice[]
}

export const CardSemesterDecoder: decoders.Decoder<CardSemester> = (
  decoders.object({
    attributes: decoders.object({
      "name": decoders.string,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "choices": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(ChoiceDecoder))
      })),
    })),
  })
  .transform(obj => {
    return {
      "name": obj.attributes["name"],
      "choices": obj?.relationships?.["choices"]?.data,
    }
  })
)

export interface CardScoringSystem {
  readonly "name": string
  readonly "range": null | string
  readonly "description": string
}

export const CardScoringSystemDecoder: decoders.Decoder<CardScoringSystem> = (
  decoders.object({
    attributes: decoders.object({
      "name": decoders.string,
      "range": decoders.nullable(decoders.string),
      "description": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "name": obj.attributes["name"],
      "range": obj.attributes["range"],
      "description": obj.attributes["description"],
    }
  })
)

export interface Card {
  readonly "studentName": string
  readonly "personalNumber": string
  readonly "birthDate": string
  readonly "unidocNumber": string
  readonly "facultyName": string
  readonly "majorHasFreeCredits": boolean
  readonly "majorProgramName": null | string
  readonly "majorProgramDirection": null | string
  readonly "minorProgramName": null | string
  readonly "minorProgramDirection": null | string
  readonly "averagePoint": null | number
  readonly "semesters"?: readonly CardSemester[]
  readonly "bachelorScoringSystem"?: readonly CardScoringSystem[]
  readonly "doctorScoringSystem"?: readonly CardScoringSystem[]
}

export const CardDecoder: decoders.Decoder<Card> = (
  decoders.object({
    attributes: decoders.object({
      "studentName": decoders.string,
      "personalNumber": decoders.string,
      "birthDate": decoders.string,
      "unidocNumber": decoders.string,
      "facultyName": decoders.string,
      "majorHasFreeCredits": decoders.boolean,
      "majorProgramName": decoders.nullable(decoders.string),
      "majorProgramDirection": decoders.nullable(decoders.string),
      "minorProgramName": decoders.nullable(decoders.string),
      "minorProgramDirection": decoders.nullable(decoders.string),
      "averagePoint": decoders.nullable(decoders.number),
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "semesters": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(CardSemesterDecoder))
      })),
      "bachelorScoringSystem": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(CardScoringSystemDecoder))
      })),
      "doctorScoringSystem": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(CardScoringSystemDecoder))
      })),
    })),
  })
  .transform(obj => {
    return {
      "studentName": obj.attributes["studentName"],
      "personalNumber": obj.attributes["personalNumber"],
      "birthDate": obj.attributes["birthDate"],
      "unidocNumber": obj.attributes["unidocNumber"],
      "facultyName": obj.attributes["facultyName"],
      "majorHasFreeCredits": obj.attributes["majorHasFreeCredits"],
      "majorProgramName": obj.attributes["majorProgramName"],
      "majorProgramDirection": obj.attributes["majorProgramDirection"],
      "minorProgramName": obj.attributes["minorProgramName"],
      "minorProgramDirection": obj.attributes["minorProgramDirection"],
      "averagePoint": obj.attributes["averagePoint"],
      "semesters": obj?.relationships?.["semesters"]?.data,
      "bachelorScoringSystem": obj?.relationships?.["bachelorScoringSystem"]?.data,
      "doctorScoringSystem": obj?.relationships?.["doctorScoringSystem"]?.data,
    }
  })
)

export interface CalendarItem {
  readonly "id": null | IDString
  readonly "title": string
  readonly "startDate": string
  readonly "eventType": "event" | "holiday" | "schedule"
}

export const CalendarItemDecoder: decoders.Decoder<CalendarItem> = (
  decoders.object({
    "id": decoders.nullable(IDStringDecoder),
    attributes: decoders.object({
      "title": decoders.string,
      "startDate": decoders.string,
      "eventType": decoders.either(
        decoders.constant("event" as const),
        decoders.constant("holiday" as const),
        decoders.constant("schedule" as const)
      ),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "title": obj.attributes["title"],
      "startDate": obj.attributes["startDate"],
      "eventType": obj.attributes["eventType"],
    }
  })
)

export interface StatementFile {
  readonly "id": IDString
  readonly "mediaFile": MediaFile
}

export const StatementFileDecoder: decoders.Decoder<StatementFile> = (
  decoders.object({
    "id": IDStringDecoder,
    relationships: decoders.object({
      "mediaFile": decoders.object({
        data: MediaFileDecoder
      }),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "mediaFile": obj.relationships["mediaFile"].data,
    }
  })
)

export type StatementStatusEnum =
  | "STATUS_PENDING"
  | "STATUS_DECLINED"
  | "STATUS_ACCEPTED"
  | "STATUS_PARTIAL"

export const StatementStatusEnumDecoder: decoders.Decoder<StatementStatusEnum> = (
  decoders.either(decoders.number, decoders.string)
  .transform(key => {
    switch (key) {
      case 1: return "STATUS_PENDING"
      case 2: return "STATUS_DECLINED"
      case 3: return "STATUS_ACCEPTED"
      case 4: return "STATUS_PARTIAL"
    }

    throw new Error(`Could not decode StatementStatusEnum from value: ${key}`)
  })
)

export function StatementStatusEnumEncoder (label: StatementStatusEnum): string | number {
  switch (label) {
    case "STATUS_PENDING": return 1
    case "STATUS_DECLINED": return 2
    case "STATUS_ACCEPTED": return 3
    case "STATUS_PARTIAL": return 4
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Could not decode __UNNAMED_TYPE__ from value: ${label}`)
  }
}

export const StatementStatusEnumValues = [
  "STATUS_PENDING",
  "STATUS_DECLINED",
  "STATUS_ACCEPTED",
  "STATUS_PARTIAL",
] as const

export interface Statement {
  readonly "id": IDString
  readonly "number": string
  readonly "title": string
  readonly "comment": string
  readonly "status": StatementStatusEnum
  readonly "statusText": string
  readonly "createdAt": string
  readonly "body": string
  readonly "files"?: readonly StatementFile[]
}

export const StatementDecoder: decoders.Decoder<Statement> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "number": decoders.string,
      "title": decoders.string,
      "comment": decoders.string,
      "status": StatementStatusEnumDecoder,
      "statusText": decoders.string,
      "createdAt": decoders.string,
      "body": decoders.string,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "files": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(StatementFileDecoder))
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "number": obj.attributes["number"],
      "title": obj.attributes["title"],
      "comment": obj.attributes["comment"],
      "status": obj.attributes["status"],
      "statusText": obj.attributes["statusText"],
      "createdAt": obj.attributes["createdAt"],
      "body": obj.attributes["body"],
      "files": obj?.relationships?.["files"]?.data,
    }
  })
)

export interface StatementTemplate {
  readonly "id": IDString
  readonly "title": string
}

export const StatementTemplateDecoder: decoders.Decoder<StatementTemplate> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "title": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "title": obj.attributes["title"],
    }
  })
)

export interface StatementTemplateView {
  readonly "helpText": string
  readonly "html": string
  readonly "studentFacultyId": number
  readonly "targetUser": string
  readonly "targetUserTitle": string
  readonly "userAddress": null | string
  readonly "userDegree": string
  readonly "userFaculty": string
  readonly "userFacultySchool": null | string
  readonly "userFullName": string
  readonly "userMail": string
  readonly "userMajorProgram": string
  readonly "userPersNum": string
  readonly "userPhone": null | string
  readonly "userProgram": string
  readonly "userSemester": number
  readonly "userSign": string
  readonly "isTypeMobility": boolean
}

export const StatementTemplateViewDecoder: decoders.Decoder<StatementTemplateView> = (
  decoders.object({
    attributes: decoders.object({
      "helpText": decoders.string,
      "html": decoders.string,
      "studentFacultyId": decoders.number,
      "targetUser": decoders.string,
      "targetUserTitle": decoders.string,
      "userAddress": decoders.nullable(decoders.string),
      "userDegree": decoders.string,
      "userFaculty": decoders.string,
      "userFacultySchool": decoders.nullable(decoders.string),
      "userFullName": decoders.string,
      "userMail": decoders.string,
      "userMajorProgram": decoders.string,
      "userPersNum": decoders.string,
      "userPhone": decoders.nullable(decoders.string),
      "userProgram": decoders.string,
      "userSemester": decoders.number,
      "userSign": decoders.string,
      "isTypeMobility": decoders.boolean,
    }),
  })
  .transform(obj => {
    return {
      "helpText": obj.attributes["helpText"],
      "html": obj.attributes["html"],
      "studentFacultyId": obj.attributes["studentFacultyId"],
      "targetUser": obj.attributes["targetUser"],
      "targetUserTitle": obj.attributes["targetUserTitle"],
      "userAddress": obj.attributes["userAddress"],
      "userDegree": obj.attributes["userDegree"],
      "userFaculty": obj.attributes["userFaculty"],
      "userFacultySchool": obj.attributes["userFacultySchool"],
      "userFullName": obj.attributes["userFullName"],
      "userMail": obj.attributes["userMail"],
      "userMajorProgram": obj.attributes["userMajorProgram"],
      "userPersNum": obj.attributes["userPersNum"],
      "userPhone": obj.attributes["userPhone"],
      "userProgram": obj.attributes["userProgram"],
      "userSemester": obj.attributes["userSemester"],
      "userSign": obj.attributes["userSign"],
      "isTypeMobility": obj.attributes["isTypeMobility"],
    }
  })
)

export interface StatementTemplateRequest {
  readonly "id"?: IDString
  readonly "phone"?: string
  readonly "address"?: string
  readonly "year"?: string
  readonly "year2"?: string
  readonly "semester"?: string
  readonly "semester2"?: string
  readonly "place"?: string
  readonly "text"?: string
  readonly "semesterId"?: IDString
  readonly "facultyId"?: IDString
  readonly "programId"?: IDString
}

export const StatementTemplateRequestDecoder: decoders.Decoder<StatementTemplateRequest> = decoders.object({
  "id": optionalNullableDecoder(IDStringDecoder),
  "phone": optionalNullableDecoder(decoders.string),
  "address": optionalNullableDecoder(decoders.string),
  "year": optionalNullableDecoder(decoders.string),
  "year2": optionalNullableDecoder(decoders.string),
  "semester": optionalNullableDecoder(decoders.string),
  "semester2": optionalNullableDecoder(decoders.string),
  "place": optionalNullableDecoder(decoders.string),
  "text": optionalNullableDecoder(decoders.string),
  "semesterId": optionalNullableDecoder(IDStringDecoder),
  "facultyId": optionalNullableDecoder(IDStringDecoder),
  "programId": optionalNullableDecoder(IDStringDecoder),
})

export interface Rating {
  readonly "averagePoint": null | number
}

export const RatingDecoder: decoders.Decoder<Rating> = (
  decoders.object({
    attributes: decoders.object({
      "averagePoint": decoders.nullable(decoders.number),
    }),
  })
  .transform(obj => {
    return {
      "averagePoint": obj.attributes["averagePoint"],
    }
  })
)

export interface CourseSyllabus {
  readonly "duration": null | string
  readonly "lecturers": string
  readonly "hours": string
  readonly "prerequisites": string
  readonly "methods": string
  readonly "mission": string
  readonly "topics": string
  readonly "outcomes": string
  readonly "evaluation": string
  readonly "resources": string
  readonly "otherResources": string
  readonly "schedule": string
  readonly "knowledge": boolean
  readonly "applying": boolean
  readonly "judgments": boolean
  readonly "communications": boolean
  readonly "learning": boolean
  readonly "values": boolean
  readonly "course"?: Course
  readonly "manage"?: ManageSyllabus
  readonly "syllabusFile"?: CourseSyllabusFile
}

export const CourseSyllabusDecoder: decoders.Decoder<CourseSyllabus> = (
  decoders.object({
    attributes: decoders.object({
      "duration": decoders.nullable(decoders.string),
      "lecturers": decoders.string,
      "hours": decoders.string,
      "prerequisites": decoders.string,
      "methods": decoders.string,
      "mission": decoders.string,
      "topics": decoders.string,
      "outcomes": decoders.string,
      "evaluation": decoders.string,
      "resources": decoders.string,
      "otherResources": decoders.string,
      "schedule": decoders.string,
      "knowledge": decoders.boolean,
      "applying": decoders.boolean,
      "judgments": decoders.boolean,
      "communications": decoders.boolean,
      "learning": decoders.boolean,
      "values": decoders.boolean,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "course": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(CourseDecoder)
      })),
      "manage": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ManageSyllabusDecoder)
      })),
      "syllabusFile": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(CourseSyllabusFileDecoder)
      })),
    })),
  })
  .transform(obj => {
    return {
      "duration": obj.attributes["duration"],
      "lecturers": obj.attributes["lecturers"],
      "hours": obj.attributes["hours"],
      "prerequisites": obj.attributes["prerequisites"],
      "methods": obj.attributes["methods"],
      "mission": obj.attributes["mission"],
      "topics": obj.attributes["topics"],
      "outcomes": obj.attributes["outcomes"],
      "evaluation": obj.attributes["evaluation"],
      "resources": obj.attributes["resources"],
      "otherResources": obj.attributes["otherResources"],
      "schedule": obj.attributes["schedule"],
      "knowledge": obj.attributes["knowledge"],
      "applying": obj.attributes["applying"],
      "judgments": obj.attributes["judgments"],
      "communications": obj.attributes["communications"],
      "learning": obj.attributes["learning"],
      "values": obj.attributes["values"],
      "course": obj?.relationships?.["course"]?.data,
      "manage": obj?.relationships?.["manage"]?.data,
      "syllabusFile": obj?.relationships?.["syllabusFile"]?.data,
    }
  })
)

export interface CourseFile {
  readonly "id": IDString
  readonly "url": null | string
  readonly "name": null | string
  readonly "isViewableInWeb": boolean
  readonly "user"?: User
  readonly "manage"?: {
    readonly "canEdit": boolean
  }
  readonly "mediaFile"?: MediaFile
}

export const CourseFileDecoder: decoders.Decoder<CourseFile> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "url": decoders.nullable(decoders.string),
      "name": decoders.nullable(decoders.string),
      "isViewableInWeb": decoders.boolean,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "user": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(UserDecoder)
      })),
      "manage": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder((
          decoders.object({
            attributes: decoders.object({
              "canEdit": decoders.boolean,
            }),
          })
          .transform(obj => {
            return {
              "canEdit": obj.attributes["canEdit"],
            }
          })
        ))
      })),
      "mediaFile": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(MediaFileDecoder)
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "url": obj.attributes["url"],
      "name": obj.attributes["name"],
      "isViewableInWeb": obj.attributes["isViewableInWeb"],
      "user": obj?.relationships?.["user"]?.data,
      "manage": obj?.relationships?.["manage"]?.data,
      "mediaFile": obj?.relationships?.["mediaFile"]?.data,
    }
  })
)

export interface CoursePost {
  readonly "id": IDString
  readonly "body": string
  readonly "number": string
  readonly "commentsCount": number
  readonly "isCommentingEnabled": boolean
  readonly "isVisibleToAll": boolean
  readonly "createdAt": string
  readonly "groups": readonly Group[]
  readonly "author"?: User
}

export const CoursePostDecoder: decoders.Decoder<CoursePost> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "body": decoders.string,
      "number": decoders.string,
      "commentsCount": decoders.number,
      "isCommentingEnabled": decoders.boolean,
      "isVisibleToAll": decoders.boolean,
      "createdAt": decoders.string,
    }),
    relationships: decoders.object({
      "groups": decoders.object({
        data: ArrayObjectDecoder(GroupDecoder)
      }),
      "author": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(UserDecoder)
      })),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "body": obj.attributes["body"],
      "number": obj.attributes["number"],
      "commentsCount": obj.attributes["commentsCount"],
      "isCommentingEnabled": obj.attributes["isCommentingEnabled"],
      "isVisibleToAll": obj.attributes["isVisibleToAll"],
      "createdAt": obj.attributes["createdAt"],
      "groups": obj.relationships["groups"].data,
      "author": obj?.relationships?.["author"]?.data,
    }
  })
)

export interface CoursePostSaveRequest {
  readonly "semId": number
  readonly "visibility": number
  readonly "commenting": number
  readonly "body": string
  readonly "groups"?: readonly number[]
}

export const CoursePostSaveRequestDecoder: decoders.Decoder<CoursePostSaveRequest> = (
  decoders.object({
    "semId": decoders.number,
    "visibility": decoders.number,
    "commenting": decoders.number,
    "body": decoders.string,
    relationships: optionalNullableDecoder(decoders.object({
      "groups": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(decoders.number))
      })),
    })),
  })
  .transform(obj => {
    return {
      "semId": obj["semId"],
      "visibility": obj["visibility"],
      "commenting": obj["commenting"],
      "body": obj["body"],
      "groups": obj?.relationships?.["groups"]?.data,
    }
  })
)

export interface NewsFile {
  readonly "id": IDString
  readonly "mediaFile": MediaFile
}

export const NewsFileDecoder: decoders.Decoder<NewsFile> = (
  decoders.object({
    "id": IDStringDecoder,
    relationships: decoders.object({
      "mediaFile": decoders.object({
        data: MediaFileDecoder
      }),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "mediaFile": obj.relationships["mediaFile"].data,
    }
  })
)

export interface UserViewStatus {
  readonly "isViewed": boolean
}

export const UserViewStatusDecoder: decoders.Decoder<UserViewStatus> = (
  decoders.object({
    attributes: decoders.object({
      "isViewed": decoders.boolean,
    }),
  })
  .transform(obj => {
    return {
      "isViewed": obj.attributes["isViewed"],
    }
  })
)

export interface NotificationData {
  readonly "courseId": string
}

export const NotificationDataDecoder: decoders.Decoder<NotificationData> = decoders.object({
  "courseId": decoders.string,
})

export interface NewsUnreadCount {
  readonly "total": number
  readonly "unread": number
}

export const NewsUnreadCountDecoder: decoders.Decoder<NewsUnreadCount> = (
  decoders.object({
    attributes: decoders.object({
      "total": decoders.number,
      "unread": decoders.number,
    }),
  })
  .transform(obj => {
    return {
      "total": obj.attributes["total"],
      "unread": obj.attributes["unread"],
    }
  })
)

export interface News {
  readonly "id": IDString
  readonly "title": string
  readonly "titleColor": string
  readonly "text": string
  readonly "createdAt": string
  readonly "userViewStatus": UserViewStatus
  readonly "files"?: readonly NewsFile[]
}

export const NewsDecoder: decoders.Decoder<News> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "title": decoders.string,
      "titleColor": decoders.string,
      "text": decoders.string,
      "createdAt": decoders.string,
    }),
    relationships: decoders.object({
      "userViewStatus": decoders.object({
        data: UserViewStatusDecoder
      }),
      "files": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(NewsFileDecoder))
      })),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "title": obj.attributes["title"],
      "titleColor": obj.attributes["titleColor"],
      "text": obj.attributes["text"],
      "createdAt": obj.attributes["createdAt"],
      "userViewStatus": obj.relationships["userViewStatus"].data,
      "files": obj?.relationships?.["files"]?.data,
    }
  })
)

export type PortfolioWorkTypes =
  | "FORM_1"
  | "FORM_2"
  | "FORM_3"
  | "FORM_4"

export const PortfolioWorkTypesDecoder: decoders.Decoder<PortfolioWorkTypes> = (
  decoders.either(decoders.number, decoders.string)
  .transform(key => {
    switch (key) {
      case 1: return "FORM_1"
      case 2: return "FORM_2"
      case 3: return "FORM_3"
      case 4: return "FORM_4"
    }

    throw new Error(`Could not decode PortfolioWorkTypes from value: ${key}`)
  })
)

export function PortfolioWorkTypesEncoder (label: PortfolioWorkTypes): string | number {
  switch (label) {
    case "FORM_1": return 1
    case "FORM_2": return 2
    case "FORM_3": return 3
    case "FORM_4": return 4
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Could not decode __UNNAMED_TYPE__ from value: ${label}`)
  }
}

export const PortfolioWorkTypesValues = [
  "FORM_1",
  "FORM_2",
  "FORM_3",
  "FORM_4",
] as const

export interface PortfolioCompetenciesCriteria {
  readonly "id": IDString
  readonly "name": string
  readonly "description": string
  readonly "min": number
  readonly "max": number
}

export const PortfolioCompetenciesCriteriaDecoder: decoders.Decoder<PortfolioCompetenciesCriteria> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
      "description": decoders.string,
      "min": decoders.number,
      "max": decoders.number,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "description": obj.attributes["description"],
      "min": obj.attributes["min"],
      "max": obj.attributes["max"],
    }
  })
)

export interface PortfolioSectionCompetency {
  readonly "id": IDString
  readonly "name": string
  readonly "description": string
  readonly "criterias"?: readonly PortfolioCompetenciesCriteria[]
}

export const PortfolioSectionCompetencyDecoder: decoders.Decoder<PortfolioSectionCompetency> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
      "description": decoders.string,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "criterias": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(PortfolioCompetenciesCriteriaDecoder))
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "description": obj.attributes["description"],
      "criterias": obj?.relationships?.["criterias"]?.data,
    }
  })
)

export interface Section {
  readonly "id": IDString
  readonly "name": string
  readonly "workType": PortfolioWorkTypes
  readonly "workTitle": string
  readonly "manage"?: ManageSection
  readonly "competencies"?: readonly PortfolioSectionCompetency[]
}

export const SectionDecoder: decoders.Decoder<Section> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
      "workType": PortfolioWorkTypesDecoder,
      "workTitle": decoders.string,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "manage": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ManageSectionDecoder)
      })),
      "competencies": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(PortfolioSectionCompetencyDecoder))
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "workType": obj.attributes["workType"],
      "workTitle": obj.attributes["workTitle"],
      "manage": obj?.relationships?.["manage"]?.data,
      "competencies": obj?.relationships?.["competencies"]?.data,
    }
  })
)

export type PortfolioWorkStatus =
  | "STATUS_PENDING"
  | "STATUS_EVALUATED"
  | "STATUS_REJECTED"

export const PortfolioWorkStatusDecoder: decoders.Decoder<PortfolioWorkStatus> = (
  decoders.either(decoders.number, decoders.string)
  .transform(key => {
    switch (key) {
      case 0: return "STATUS_PENDING"
      case 1: return "STATUS_EVALUATED"
      case 2: return "STATUS_REJECTED"
    }

    throw new Error(`Could not decode PortfolioWorkStatus from value: ${key}`)
  })
)

export function PortfolioWorkStatusEncoder (label: PortfolioWorkStatus): string | number {
  switch (label) {
    case "STATUS_PENDING": return 0
    case "STATUS_EVALUATED": return 1
    case "STATUS_REJECTED": return 2
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Could not decode __UNNAMED_TYPE__ from value: ${label}`)
  }
}

export const PortfolioWorkStatusValues = [
  "STATUS_PENDING",
  "STATUS_EVALUATED",
  "STATUS_REJECTED",
] as const

export interface StudentPortfolioUserWork {
  readonly "id": IDString
  readonly "text": string
  readonly "status": PortfolioWorkStatus
  readonly "feedback"?: string
  readonly "student"?: User
  readonly "mentor"?: User
  readonly "competencies"?: readonly PortfolioSectionCompetency[]
  readonly "mediaFiles"?: readonly MediaFile[]
}

export const StudentPortfolioUserWorkDecoder: decoders.Decoder<StudentPortfolioUserWork> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "text": decoders.string,
      "status": PortfolioWorkStatusDecoder,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "feedback": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(decoders.string)
      })),
      "student": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(UserDecoder)
      })),
      "mentor": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(UserDecoder)
      })),
      "competencies": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(PortfolioSectionCompetencyDecoder))
      })),
      "mediaFiles": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(MediaFileDecoder))
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "text": obj.attributes["text"],
      "status": obj.attributes["status"],
      "feedback": obj?.relationships?.["feedback"]?.data,
      "student": obj?.relationships?.["student"]?.data,
      "mentor": obj?.relationships?.["mentor"]?.data,
      "competencies": obj?.relationships?.["competencies"]?.data,
      "mediaFiles": obj?.relationships?.["mediaFiles"]?.data,
    }
  })
)

export interface LecturerPortfolioUserCase {
  readonly "id": IDString
  readonly "text": string
  readonly "status": PortfolioWorkStatus
  readonly "feedback"?: string
  readonly "student"?: User
  readonly "mentor"?: User
  readonly "competencies"?: readonly PortfolioSectionCompetency[]
  readonly "manage"?: MentorWorkManage
  readonly "mediaFiles"?: readonly MediaFile[]
}

export const LecturerPortfolioUserCaseDecoder: decoders.Decoder<LecturerPortfolioUserCase> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "text": decoders.string,
      "status": PortfolioWorkStatusDecoder,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "feedback": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(decoders.string)
      })),
      "student": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(UserDecoder)
      })),
      "mentor": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(UserDecoder)
      })),
      "competencies": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(PortfolioSectionCompetencyDecoder))
      })),
      "manage": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(MentorWorkManageDecoder)
      })),
      "mediaFiles": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(MediaFileDecoder))
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "text": obj.attributes["text"],
      "status": obj.attributes["status"],
      "feedback": obj?.relationships?.["feedback"]?.data,
      "student": obj?.relationships?.["student"]?.data,
      "mentor": obj?.relationships?.["mentor"]?.data,
      "competencies": obj?.relationships?.["competencies"]?.data,
      "manage": obj?.relationships?.["manage"]?.data,
      "mediaFiles": obj?.relationships?.["mediaFiles"]?.data,
    }
  })
)

export type NotificationType = "ScoreSaveNotification" | "MaterialAddNotification" | "LectureStartsNotification" | "NewsAddNotification"

export const NotificationTypeDecoder: decoders.Decoder<NotificationType> = decoders.either(
  decoders.constant("ScoreSaveNotification" as const),
  decoders.constant("MaterialAddNotification" as const),
  decoders.constant("LectureStartsNotification" as const),
  decoders.constant("NewsAddNotification" as const)
)

export interface Notification {
  readonly "id": IDString
  readonly "type": NotificationType
  readonly "title": string
  readonly "text": string
  readonly "createdAt": string
  readonly "readAt": null | string
  readonly "data": {
    readonly "courseId"?: IDString
    readonly "newsId"?: IDString
  }
}

export const NotificationDecoder: decoders.Decoder<Notification> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "type": NotificationTypeDecoder,
      "title": decoders.string,
      "text": decoders.string,
      "createdAt": decoders.string,
      "readAt": decoders.nullable(decoders.string),
      "data": decoders.object({
        "courseId": optionalNullableDecoder(IDStringDecoder),
        "newsId": optionalNullableDecoder(IDStringDecoder),
      }),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "type": obj.attributes["type"],
      "title": obj.attributes["title"],
      "text": obj.attributes["text"],
      "createdAt": obj.attributes["createdAt"],
      "readAt": obj.attributes["readAt"],
      "data": obj.attributes["data"],
    }
  })
)

export interface Faq {
  readonly "id": IDString
  readonly "question": string
  readonly "answer": string
}

export const FaqDecoder: decoders.Decoder<Faq> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "question": decoders.string,
      "answer": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "question": obj.attributes["question"],
      "answer": obj.attributes["answer"],
    }
  })
)

export interface Program {
  readonly "id": IDString
  readonly "name": string
  readonly "code": string
  readonly "description": string
  readonly "minorDescription": string
  readonly "direction": string
  readonly "registrationRequirements": null | string
  readonly "totalCredits": number
  readonly "minorTotalCredits": number
  readonly "hasFreeCredits": boolean
  readonly "faculty"?: {
    readonly "id": IDString
    readonly "name": string
  }
}

export const ProgramDecoder: decoders.Decoder<Program> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
      "code": decoders.string,
      "description": decoders.string,
      "minorDescription": decoders.string,
      "direction": decoders.string,
      "registrationRequirements": decoders.nullable(decoders.string),
      "totalCredits": decoders.number,
      "minorTotalCredits": decoders.number,
      "hasFreeCredits": decoders.boolean,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "faculty": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder((
          decoders.object({
            "id": IDStringDecoder,
            attributes: decoders.object({
              "name": decoders.string,
            }),
          })
          .transform(obj => {
            return {
              "id": obj["id"],
              "name": obj.attributes["name"],
            }
          })
        ))
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "code": obj.attributes["code"],
      "description": obj.attributes["description"],
      "minorDescription": obj.attributes["minorDescription"],
      "direction": obj.attributes["direction"],
      "registrationRequirements": obj.attributes["registrationRequirements"],
      "totalCredits": obj.attributes["totalCredits"],
      "minorTotalCredits": obj.attributes["minorTotalCredits"],
      "hasFreeCredits": obj.attributes["hasFreeCredits"],
      "faculty": obj?.relationships?.["faculty"]?.data,
    }
  })
)

export interface RegProg {
  readonly "id": IDString
  readonly "program": Program
}

export const RegProgDecoder: decoders.Decoder<RegProg> = (
  decoders.object({
    "id": IDStringDecoder,
    relationships: decoders.object({
      "program": decoders.object({
        data: ProgramDecoder
      }),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "program": obj.relationships["program"].data,
    }
  })
)

export interface FacultySchool {
  readonly "id": IDString
  readonly "name": string
}

export const FacultySchoolDecoder: decoders.Decoder<FacultySchool> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
    }
  })
)

export interface Faculty {
  readonly "id": IDString
  readonly "name": string
  readonly "programs"?: readonly Program[]
}

export const FacultyDecoder: decoders.Decoder<Faculty> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "programs": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(ProgramDecoder))
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "programs": obj?.relationships?.["programs"]?.data,
    }
  })
)

export interface StudentProgramState {
  readonly "id": IDString
  readonly "customProgramId": IDString
  readonly "minorFacultyId": IDString
  readonly "minorProgramId": IDString
  readonly "canChooseMajor": boolean
  readonly "canChooseMinor": boolean
  readonly "faculty": Faculty
  readonly "customProgram": Program
  readonly "facultySchool"?: FacultySchool
}

export const StudentProgramStateDecoder: decoders.Decoder<StudentProgramState> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "customProgramId": IDStringDecoder,
      "minorFacultyId": IDStringDecoder,
      "minorProgramId": IDStringDecoder,
      "canChooseMajor": decoders.boolean,
      "canChooseMinor": decoders.boolean,
    }),
    relationships: decoders.object({
      "faculty": decoders.object({
        data: FacultyDecoder
      }),
      "customProgram": decoders.object({
        data: ProgramDecoder
      }),
      "facultySchool": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(FacultySchoolDecoder)
      })),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "customProgramId": obj.attributes["customProgramId"],
      "minorFacultyId": obj.attributes["minorFacultyId"],
      "minorProgramId": obj.attributes["minorProgramId"],
      "canChooseMajor": obj.attributes["canChooseMajor"],
      "canChooseMinor": obj.attributes["canChooseMinor"],
      "faculty": obj.relationships["faculty"].data,
      "customProgram": obj.relationships["customProgram"].data,
      "facultySchool": obj?.relationships?.["facultySchool"]?.data,
    }
  })
)

export interface LecturerUser {
  readonly "id": IDString
  readonly "uid": string
  readonly "email": null | string
  readonly "firstName": string
  readonly "lastName": string
  readonly "fullName": string
  readonly "avatar": string
  readonly "photoUrl": null | string
  readonly "gender": null | string
  readonly "profileId": number
  readonly "groups"?: readonly Group[]
  readonly "group"?: Group
  readonly "scores"?: readonly StudentScore[]
  readonly "finalScore"?: FinalScore
  readonly "profilePrograms"?: StudentProgramState
}

export const LecturerUserDecoder: decoders.Decoder<LecturerUser> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "uid": decoders.string,
      "email": decoders.nullable(decoders.string),
      "firstName": decoders.string,
      "lastName": decoders.string,
      "fullName": decoders.string,
      "avatar": decoders.string,
      "photoUrl": decoders.nullable(decoders.string),
      "gender": decoders.nullable(decoders.string),
      "profileId": decoders.number,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "groups": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(GroupDecoder))
      })),
      "group": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(GroupDecoder)
      })),
      "scores": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(StudentScoreDecoder))
      })),
      "finalScore": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(FinalScoreDecoder)
      })),
      "profilePrograms": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(StudentProgramStateDecoder)
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "uid": obj.attributes["uid"],
      "email": obj.attributes["email"],
      "firstName": obj.attributes["firstName"],
      "lastName": obj.attributes["lastName"],
      "fullName": obj.attributes["fullName"],
      "avatar": obj.attributes["avatar"],
      "photoUrl": obj.attributes["photoUrl"],
      "gender": obj.attributes["gender"],
      "profileId": obj.attributes["profileId"],
      "groups": obj?.relationships?.["groups"]?.data,
      "group": obj?.relationships?.["group"]?.data,
      "scores": obj?.relationships?.["scores"]?.data,
      "finalScore": obj?.relationships?.["finalScore"]?.data,
      "profilePrograms": obj?.relationships?.["profilePrograms"]?.data,
    }
  })
)

export interface Recommendation {
  readonly "id": IDString
  readonly "text": string
  readonly "textFragment": string
  readonly "createdAt": string
  readonly "author": User
}

export const RecommendationDecoder: decoders.Decoder<Recommendation> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "text": decoders.string,
      "textFragment": decoders.string,
      "createdAt": decoders.string,
    }),
    relationships: decoders.object({
      "author": decoders.object({
        data: UserDecoder
      }),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "text": obj.attributes["text"],
      "textFragment": obj.attributes["textFragment"],
      "createdAt": obj.attributes["createdAt"],
      "author": obj.relationships["author"].data,
    }
  })
)

export interface RecommendationLecturerSide {
  readonly "id": IDString
  readonly "text": string
  readonly "textFragment": string
  readonly "createdAt": string
}

export const RecommendationLecturerSideDecoder: decoders.Decoder<RecommendationLecturerSide> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "text": decoders.string,
      "textFragment": decoders.string,
      "createdAt": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "text": obj.attributes["text"],
      "textFragment": obj.attributes["textFragment"],
      "createdAt": obj.attributes["createdAt"],
    }
  })
)

export interface RecommendationStudentInfo {
  readonly "id": IDString
  readonly "fullName": string
  readonly "profilePrograms": StudentProgramState
}

export const RecommendationStudentInfoDecoder: decoders.Decoder<RecommendationStudentInfo> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "fullName": decoders.string,
    }),
    relationships: decoders.object({
      "profilePrograms": decoders.object({
        data: StudentProgramStateDecoder
      }),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "fullName": obj.attributes["fullName"],
      "profilePrograms": obj.relationships["profilePrograms"].data,
    }
  })
)

export interface CoursePostComment {
  readonly "id": IDString
  readonly "body": string
  readonly "parentId": null | IDString
  readonly "isParent": boolean
  readonly "isPostAuthor": boolean
  readonly "createdAt": string
  readonly "children"?: readonly CoursePostComment[]
  readonly "author"?: User
  readonly "authorGroup"?: Group
}

export const CoursePostCommentDecoder: decoders.Decoder<CoursePostComment> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "body": decoders.string,
      "parentId": decoders.nullable(IDStringDecoder),
      "isParent": decoders.boolean,
      "isPostAuthor": decoders.boolean,
      "createdAt": decoders.string,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "children": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(decoders.lazy(() => CoursePostCommentDecoder)))
      })),
      "author": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(UserDecoder)
      })),
      "authorGroup": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(GroupDecoder)
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "body": obj.attributes["body"],
      "parentId": obj.attributes["parentId"],
      "isParent": obj.attributes["isParent"],
      "isPostAuthor": obj.attributes["isPostAuthor"],
      "createdAt": obj.attributes["createdAt"],
      "children": obj?.relationships?.["children"]?.data,
      "author": obj?.relationships?.["author"]?.data,
      "authorGroup": obj?.relationships?.["authorGroup"]?.data,
    }
  })
)

export interface Resume {
  readonly "id": IDString
  readonly "personalTabVisible": boolean
  readonly "educationTabVisible": boolean
  readonly "workTabVisible": boolean
  readonly "trainingsTabVisible": boolean
  readonly "skillsTabVisible": boolean
  readonly "additionalTabVisible": boolean
  readonly "summaryTabVisible": boolean
}

export const ResumeDecoder: decoders.Decoder<Resume> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "personalTabVisible": decoders.boolean,
      "educationTabVisible": decoders.boolean,
      "workTabVisible": decoders.boolean,
      "trainingsTabVisible": decoders.boolean,
      "skillsTabVisible": decoders.boolean,
      "additionalTabVisible": decoders.boolean,
      "summaryTabVisible": decoders.boolean,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "personalTabVisible": obj.attributes["personalTabVisible"],
      "educationTabVisible": obj.attributes["educationTabVisible"],
      "workTabVisible": obj.attributes["workTabVisible"],
      "trainingsTabVisible": obj.attributes["trainingsTabVisible"],
      "skillsTabVisible": obj.attributes["skillsTabVisible"],
      "additionalTabVisible": obj.attributes["additionalTabVisible"],
      "summaryTabVisible": obj.attributes["summaryTabVisible"],
    }
  })
)

export interface resumePhoto {
  readonly "id": IDString
  readonly "expiresAt": null | string
  readonly "url": null | string
  readonly "mediaFile": MediaFile
}

export const resumePhotoDecoder: decoders.Decoder<resumePhoto> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "expiresAt": decoders.nullable(decoders.string),
      "url": decoders.nullable(decoders.string),
    }),
    relationships: decoders.object({
      "mediaFile": decoders.object({
        data: MediaFileDecoder
      }),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "expiresAt": obj.attributes["expiresAt"],
      "url": obj.attributes["url"],
      "mediaFile": obj.relationships["mediaFile"].data,
    }
  })
)

export interface ResumePersonal {
  readonly "id": IDString
  readonly "address": string
  readonly "homePhone": string
  readonly "mobilePhone": string
  readonly "photoTitle": string
  readonly "photoUrl": string
  readonly "resumePhoto"?: resumePhoto
}

export const ResumePersonalDecoder: decoders.Decoder<ResumePersonal> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "address": decoders.string,
      "homePhone": decoders.string,
      "mobilePhone": decoders.string,
      "photoTitle": decoders.string,
      "photoUrl": decoders.string,
    }),
    relationships: optionalNullableDecoder(decoders.object({
      "resumePhoto": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(resumePhotoDecoder)
      })),
    })),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "address": obj.attributes["address"],
      "homePhone": obj.attributes["homePhone"],
      "mobilePhone": obj.attributes["mobilePhone"],
      "photoTitle": obj.attributes["photoTitle"],
      "photoUrl": obj.attributes["photoUrl"],
      "resumePhoto": obj?.relationships?.["resumePhoto"]?.data,
    }
  })
)

export interface ResumeWork {
  readonly "id": IDString
  readonly "companyName": string
  readonly "position": string
  readonly "isCurrent": boolean
  readonly "startDate": string
  readonly "endDate": null | string
  readonly "commitment": string
  readonly "companyAddress": string
  readonly "companySector": string
}

export const ResumeWorkDecoder: decoders.Decoder<ResumeWork> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "companyName": decoders.string,
      "position": decoders.string,
      "isCurrent": decoders.boolean,
      "startDate": decoders.string,
      "endDate": decoders.nullable(decoders.string),
      "commitment": decoders.string,
      "companyAddress": decoders.string,
      "companySector": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "companyName": obj.attributes["companyName"],
      "position": obj.attributes["position"],
      "isCurrent": obj.attributes["isCurrent"],
      "startDate": obj.attributes["startDate"],
      "endDate": obj.attributes["endDate"],
      "commitment": obj.attributes["commitment"],
      "companyAddress": obj.attributes["companyAddress"],
      "companySector": obj.attributes["companySector"],
    }
  })
)

export interface ResumeDocument {
  readonly "id": null | IDString
  readonly "name": Stringable
  readonly "url": Stringable
  readonly "type": null | ResumeDocumentType
}

export const ResumeDocumentDecoder: decoders.Decoder<ResumeDocument> = (
  decoders.object({
    "id": decoders.nullable(IDStringDecoder),
    attributes: decoders.object({
      "name": StringableDecoder,
      "url": StringableDecoder,
      "type": decoders.nullable(ResumeDocumentTypeDecoder),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "url": obj.attributes["url"],
      "type": obj.attributes["type"],
    }
  })
)

export interface ResumeTrainingSchedule {
  readonly "id": null | IDString
  readonly "startHour": null | string
  readonly "endHour": null | string
}

export const ResumeTrainingScheduleDecoder: decoders.Decoder<ResumeTrainingSchedule> = (
  decoders.object({
    "id": decoders.nullable(IDStringDecoder),
    attributes: decoders.object({
      "startHour": decoders.nullable(decoders.string),
      "endHour": decoders.nullable(decoders.string),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "startHour": obj.attributes["startHour"],
      "endHour": obj.attributes["endHour"],
    }
  })
)

export interface ResumeTraining {
  readonly "id": IDString
  readonly "name": string
  readonly "companyName": string
  readonly "isCurrent": boolean
  readonly "startDate": string
  readonly "endDate": null | string
  readonly "skills": string
  readonly "countryId": IDString
  readonly "cityId": IDString
  readonly "document": ResumeDocument
  readonly "schedule": readonly ResumeTrainingSchedule[]
  readonly "trainingFile"?: MediaFile
}

export const ResumeTrainingDecoder: decoders.Decoder<ResumeTraining> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
      "companyName": decoders.string,
      "isCurrent": decoders.boolean,
      "startDate": decoders.string,
      "endDate": decoders.nullable(decoders.string),
      "skills": decoders.string,
      "countryId": IDStringDecoder,
      "cityId": IDStringDecoder,
    }),
    relationships: decoders.object({
      "document": decoders.object({
        data: ResumeDocumentDecoder
      }),
      "schedule": decoders.object({
        data: ArrayObjectDecoder(ResumeTrainingScheduleDecoder)
      }),
      "trainingFile": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(MediaFileDecoder)
      })),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "companyName": obj.attributes["companyName"],
      "isCurrent": obj.attributes["isCurrent"],
      "startDate": obj.attributes["startDate"],
      "endDate": obj.attributes["endDate"],
      "skills": obj.attributes["skills"],
      "countryId": obj.attributes["countryId"],
      "cityId": obj.attributes["cityId"],
      "document": obj.relationships["document"].data,
      "schedule": obj.relationships["schedule"].data,
      "trainingFile": obj?.relationships?.["trainingFile"]?.data,
    }
  })
)

export interface ResumeSkillCommunication {
  readonly "id": IDString
  readonly "value": Stringable
}

export const ResumeSkillCommunicationDecoder: decoders.Decoder<ResumeSkillCommunication> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "value": StringableDecoder,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "value": obj.attributes["value"],
    }
  })
)

export interface ResumeSkillComputer {
  readonly "id": IDString
  readonly "value": Stringable
}

export const ResumeSkillComputerDecoder: decoders.Decoder<ResumeSkillComputer> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "value": StringableDecoder,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "value": obj.attributes["value"],
    }
  })
)

export interface ResumeSkillLang {
  readonly "id": IDString
  readonly "name": null | string
  readonly "hasCertificate": boolean
  readonly "isNative": boolean
  readonly "understandingLevel": null | ResumeLanguageLevel
  readonly "speakingLevel": null | ResumeLanguageLevel
  readonly "readingLevel": null | ResumeLanguageLevel
}

export const ResumeSkillLangDecoder: decoders.Decoder<ResumeSkillLang> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.nullable(decoders.string),
      "hasCertificate": decoders.boolean,
      "isNative": decoders.boolean,
      "understandingLevel": decoders.nullable(ResumeLanguageLevelDecoder),
      "speakingLevel": decoders.nullable(ResumeLanguageLevelDecoder),
      "readingLevel": decoders.nullable(ResumeLanguageLevelDecoder),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "hasCertificate": obj.attributes["hasCertificate"],
      "isNative": obj.attributes["isNative"],
      "understandingLevel": obj.attributes["understandingLevel"],
      "speakingLevel": obj.attributes["speakingLevel"],
      "readingLevel": obj.attributes["readingLevel"],
    }
  })
)

export interface ResumeSkillOrganisational {
  readonly "id": IDString
  readonly "value": Stringable
}

export const ResumeSkillOrganisationalDecoder: decoders.Decoder<ResumeSkillOrganisational> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "value": StringableDecoder,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "value": obj.attributes["value"],
    }
  })
)

export interface ResumeSkillOther {
  readonly "id": IDString
  readonly "value": Stringable
}

export const ResumeSkillOtherDecoder: decoders.Decoder<ResumeSkillOther> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "value": StringableDecoder,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "value": obj.attributes["value"],
    }
  })
)

export interface ResumeSkill {
  readonly "id": IDString
  readonly "hasDriverLicence": boolean
  readonly "communicationSkills": readonly ResumeSkillCommunication[]
  readonly "computerSkills": readonly ResumeSkillComputer[]
  readonly "languageSkills": readonly ResumeSkillLang[]
  readonly "organisationalSkills": readonly ResumeSkillOrganisational[]
  readonly "otherSkills": readonly ResumeSkillOther[]
}

export const ResumeSkillDecoder: decoders.Decoder<ResumeSkill> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "hasDriverLicence": decoders.boolean,
    }),
    relationships: decoders.object({
      "communicationSkills": decoders.object({
        data: ArrayObjectDecoder(ResumeSkillCommunicationDecoder)
      }),
      "computerSkills": decoders.object({
        data: ArrayObjectDecoder(ResumeSkillComputerDecoder)
      }),
      "languageSkills": decoders.object({
        data: ArrayObjectDecoder(ResumeSkillLangDecoder)
      }),
      "organisationalSkills": decoders.object({
        data: ArrayObjectDecoder(ResumeSkillOrganisationalDecoder)
      }),
      "otherSkills": decoders.object({
        data: ArrayObjectDecoder(ResumeSkillOtherDecoder)
      }),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "hasDriverLicence": obj.attributes["hasDriverLicence"],
      "communicationSkills": obj.relationships["communicationSkills"].data,
      "computerSkills": obj.relationships["computerSkills"].data,
      "languageSkills": obj.relationships["languageSkills"].data,
      "organisationalSkills": obj.relationships["organisationalSkills"].data,
      "otherSkills": obj.relationships["otherSkills"].data,
    }
  })
)

export interface ResumeEducation {
  readonly "id": IDString
  readonly "isCurrent": boolean
  readonly "startDate": string
  readonly "endDate": null | string
  readonly "qualification": string
  readonly "universityId": IDString
  readonly "countryId": IDString
  readonly "cityId": IDString
  readonly "document": ResumeDocument
  readonly "educationFile"?: MediaFile
}

export const ResumeEducationDecoder: decoders.Decoder<ResumeEducation> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "isCurrent": decoders.boolean,
      "startDate": decoders.string,
      "endDate": decoders.nullable(decoders.string),
      "qualification": decoders.string,
      "universityId": IDStringDecoder,
      "countryId": IDStringDecoder,
      "cityId": IDStringDecoder,
    }),
    relationships: decoders.object({
      "document": decoders.object({
        data: ResumeDocumentDecoder
      }),
      "educationFile": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(MediaFileDecoder)
      })),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "isCurrent": obj.attributes["isCurrent"],
      "startDate": obj.attributes["startDate"],
      "endDate": obj.attributes["endDate"],
      "qualification": obj.attributes["qualification"],
      "universityId": obj.attributes["universityId"],
      "countryId": obj.attributes["countryId"],
      "cityId": obj.attributes["cityId"],
      "document": obj.relationships["document"].data,
      "educationFile": obj?.relationships?.["educationFile"]?.data,
    }
  })
)

export interface ResumeAdditionalFellowship {
  readonly "id": IDString
  readonly "type": Stringable
  readonly "organisationName": Stringable
  readonly "cofinansist": Stringable
  readonly "startDate": Stringable
  readonly "endDate": Stringable
}

export const ResumeAdditionalFellowshipDecoder: decoders.Decoder<ResumeAdditionalFellowship> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "type": StringableDecoder,
      "organisationName": StringableDecoder,
      "cofinansist": StringableDecoder,
      "startDate": StringableDecoder,
      "endDate": StringableDecoder,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "type": obj.attributes["type"],
      "organisationName": obj.attributes["organisationName"],
      "cofinansist": obj.attributes["cofinansist"],
      "startDate": obj.attributes["startDate"],
      "endDate": obj.attributes["endDate"],
    }
  })
)

export interface ResumeAdditionalPublication {
  readonly "id": IDString
  readonly "type": Stringable
  readonly "name": Stringable
  readonly "publisherName": Stringable
  readonly "date": Stringable
  readonly "url": Stringable
}

export const ResumeAdditionalPublicationDecoder: decoders.Decoder<ResumeAdditionalPublication> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "type": StringableDecoder,
      "name": StringableDecoder,
      "publisherName": StringableDecoder,
      "date": StringableDecoder,
      "url": StringableDecoder,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "type": obj.attributes["type"],
      "name": obj.attributes["name"],
      "publisherName": obj.attributes["publisherName"],
      "date": obj.attributes["date"],
      "url": obj.attributes["url"],
    }
  })
)

export interface ResumeAdditionalSubject {
  readonly "id": IDString
  readonly "course": Stringable
}

export const ResumeAdditionalSubjectDecoder: decoders.Decoder<ResumeAdditionalSubject> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "course": StringableDecoder,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "course": obj.attributes["course"],
    }
  })
)

export interface ResumeAdditionalVideolection {
  readonly "id": IDString
  readonly "name": Stringable
  readonly "url": Stringable
}

export const ResumeAdditionalVideolectionDecoder: decoders.Decoder<ResumeAdditionalVideolection> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": StringableDecoder,
      "url": StringableDecoder,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "url": obj.attributes["url"],
    }
  })
)

export interface ResumeAdditional {
  readonly "id": IDString
  readonly "info": null | string
  readonly "fellowships": readonly ResumeAdditionalFellowship[]
  readonly "publications": readonly ResumeAdditionalPublication[]
  readonly "subjects": readonly ResumeAdditionalSubject[]
  readonly "videoLectures": readonly ResumeAdditionalVideolection[]
}

export const ResumeAdditionalDecoder: decoders.Decoder<ResumeAdditional> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "info": decoders.nullable(decoders.string),
    }),
    relationships: decoders.object({
      "fellowships": decoders.object({
        data: ArrayObjectDecoder(ResumeAdditionalFellowshipDecoder)
      }),
      "publications": decoders.object({
        data: ArrayObjectDecoder(ResumeAdditionalPublicationDecoder)
      }),
      "subjects": decoders.object({
        data: ArrayObjectDecoder(ResumeAdditionalSubjectDecoder)
      }),
      "videoLectures": decoders.object({
        data: ArrayObjectDecoder(ResumeAdditionalVideolectionDecoder)
      }),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "info": obj.attributes["info"],
      "fellowships": obj.relationships["fellowships"].data,
      "publications": obj.relationships["publications"].data,
      "subjects": obj.relationships["subjects"].data,
      "videoLectures": obj.relationships["videoLectures"].data,
    }
  })
)

export interface University {
  readonly "id": IDString
  readonly "name": string
}

export const UniversityDecoder: decoders.Decoder<University> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
    }
  })
)

export interface TestType {
  readonly "id": IDString
  readonly "name": string
}

export const TestTypeDecoder: decoders.Decoder<TestType> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
    }
  })
)

export interface ResumeLanguage {
  readonly "id": IDString
  readonly "name": string
}

export const ResumeLanguageDecoder: decoders.Decoder<ResumeLanguage> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
    }
  })
)

export interface ResumePublicationType {
  readonly "id": IDString
  readonly "name": string
}

export const ResumePublicationTypeDecoder: decoders.Decoder<ResumePublicationType> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
    }
  })
)

export interface ResumeGrantType {
  readonly "id": IDString
  readonly "name": string
}

export const ResumeGrantTypeDecoder: decoders.Decoder<ResumeGrantType> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
    }
  })
)

export interface ResumeCity {
  readonly "id": IDString
  readonly "name": string
}

export const ResumeCityDecoder: decoders.Decoder<ResumeCity> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
    }
  })
)

export interface RegistrationStatus {
  readonly "id": null | IDString
  readonly "enabled": boolean
  readonly "startDate": null | string
  readonly "dateEnd": null | string
}

export const RegistrationStatusDecoder: decoders.Decoder<RegistrationStatus> = (
  decoders.object({
    "id": decoders.nullable(IDStringDecoder),
    attributes: decoders.object({
      "enabled": decoders.boolean,
      "startDate": decoders.nullable(decoders.string),
      "dateEnd": decoders.nullable(decoders.string),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "enabled": obj.attributes["enabled"],
      "startDate": obj.attributes["startDate"],
      "dateEnd": obj.attributes["dateEnd"],
    }
  })
)

export interface MasterRegistrationStep1 {
  readonly "pers_num": string
  readonly "birth_date": null | string
  readonly "firstname": string
  readonly "lastname": string
  readonly "firstname_en": string
  readonly "lastname_en": string
  readonly "personal_email": string
  readonly "persdoc_exp_date": null | string
  readonly "persdoc_uid": string
  readonly "gender": string
  readonly "citizenship": string
  readonly "photo_uid": string
  readonly "army_status_doc_uid": null | string
  readonly "legal_address": string
  readonly "actual_address": string
  readonly "mobile_phone1": string
  readonly "mobile_phone2": null | string
  readonly "home_phone": null | string
  readonly "high_school": string
  readonly "high_school_doc_type": string
  readonly "high_school_finish_date": null | string
  readonly "high_school_doc_num": string
  readonly "high_school_doc_date": null | string
  readonly "high_school_doc_uid": string
  readonly "major_degree": string
  readonly "minor_degree": string
  readonly "gpa": null | string
  readonly "exam_identity_code": string
  readonly "exam_card_uid": string
  readonly "has_limited_abilities": string
  readonly "limited_abilities_text": null | string
}

export const MasterRegistrationStep1Decoder: decoders.Decoder<MasterRegistrationStep1> = (
  decoders.object({
    attributes: decoders.object({
      "pers_num": decoders.string,
      "birth_date": decoders.nullable(decoders.string),
      "firstname": decoders.string,
      "lastname": decoders.string,
      "firstname_en": decoders.string,
      "lastname_en": decoders.string,
      "personal_email": decoders.string,
      "persdoc_exp_date": decoders.nullable(decoders.string),
      "persdoc_uid": decoders.string,
      "gender": decoders.string,
      "citizenship": decoders.string,
      "photo_uid": decoders.string,
      "army_status_doc_uid": decoders.nullable(decoders.string),
      "legal_address": decoders.string,
      "actual_address": decoders.string,
      "mobile_phone1": decoders.string,
      "mobile_phone2": decoders.nullable(decoders.string),
      "home_phone": decoders.nullable(decoders.string),
      "high_school": decoders.string,
      "high_school_doc_type": decoders.string,
      "high_school_finish_date": decoders.nullable(decoders.string),
      "high_school_doc_num": decoders.string,
      "high_school_doc_date": decoders.nullable(decoders.string),
      "high_school_doc_uid": decoders.string,
      "major_degree": decoders.string,
      "minor_degree": decoders.string,
      "gpa": decoders.nullable(decoders.string),
      "exam_identity_code": decoders.string,
      "exam_card_uid": decoders.string,
      "has_limited_abilities": decoders.string,
      "limited_abilities_text": decoders.nullable(decoders.string),
    }),
  })
  .transform(obj => {
    return {
      "pers_num": obj.attributes["pers_num"],
      "birth_date": obj.attributes["birth_date"],
      "firstname": obj.attributes["firstname"],
      "lastname": obj.attributes["lastname"],
      "firstname_en": obj.attributes["firstname_en"],
      "lastname_en": obj.attributes["lastname_en"],
      "personal_email": obj.attributes["personal_email"],
      "persdoc_exp_date": obj.attributes["persdoc_exp_date"],
      "persdoc_uid": obj.attributes["persdoc_uid"],
      "gender": obj.attributes["gender"],
      "citizenship": obj.attributes["citizenship"],
      "photo_uid": obj.attributes["photo_uid"],
      "army_status_doc_uid": obj.attributes["army_status_doc_uid"],
      "legal_address": obj.attributes["legal_address"],
      "actual_address": obj.attributes["actual_address"],
      "mobile_phone1": obj.attributes["mobile_phone1"],
      "mobile_phone2": obj.attributes["mobile_phone2"],
      "home_phone": obj.attributes["home_phone"],
      "high_school": obj.attributes["high_school"],
      "high_school_doc_type": obj.attributes["high_school_doc_type"],
      "high_school_finish_date": obj.attributes["high_school_finish_date"],
      "high_school_doc_num": obj.attributes["high_school_doc_num"],
      "high_school_doc_date": obj.attributes["high_school_doc_date"],
      "high_school_doc_uid": obj.attributes["high_school_doc_uid"],
      "major_degree": obj.attributes["major_degree"],
      "minor_degree": obj.attributes["minor_degree"],
      "gpa": obj.attributes["gpa"],
      "exam_identity_code": obj.attributes["exam_identity_code"],
      "exam_card_uid": obj.attributes["exam_card_uid"],
      "has_limited_abilities": obj.attributes["has_limited_abilities"],
      "limited_abilities_text": obj.attributes["limited_abilities_text"],
    }
  })
)

export interface MasterRegistrationStep2Item {
  readonly "faculty": string
  readonly "program": string
  readonly "captions": null | readonly string[]
  readonly "files_uid": null | readonly string[]
}

export const MasterRegistrationStep2ItemDecoder: decoders.Decoder<MasterRegistrationStep2Item> = (
  decoders.object({
    attributes: decoders.object({
      "faculty": decoders.string,
      "program": decoders.string,
      "captions": decoders.nullable(ArrayObjectDecoder(decoders.string)),
      "files_uid": decoders.nullable(ArrayObjectDecoder(decoders.string)),
    }),
  })
  .transform(obj => {
    return {
      "faculty": obj.attributes["faculty"],
      "program": obj.attributes["program"],
      "captions": obj.attributes["captions"],
      "files_uid": obj.attributes["files_uid"],
    }
  })
)

export interface MasterRegistrationStep2 {
  readonly "testtype": string
  readonly "items": readonly MasterRegistrationStep2Item[]
}

export const MasterRegistrationStep2Decoder: decoders.Decoder<MasterRegistrationStep2> = (
  decoders.object({
    attributes: decoders.object({
      "testtype": decoders.string,
      "items": ArrayObjectDecoder(MasterRegistrationStep2ItemDecoder),
    }),
  })
  .transform(obj => {
    return {
      "testtype": obj.attributes["testtype"],
      "items": obj.attributes["items"],
    }
  })
)

export interface RegistrationStep3 {
  readonly "agreement": boolean
}

export const RegistrationStep3Decoder: decoders.Decoder<RegistrationStep3> = (
  decoders.object({
    attributes: decoders.object({
      "agreement": decoders.boolean,
    }),
  })
  .transform(obj => {
    return {
      "agreement": obj.attributes["agreement"],
    }
  })
)

export interface LecturerRegistrationStep1 {
  readonly "pers_num": string
  readonly "birth_date": null | string
  readonly "firstname": string
  readonly "lastname": string
  readonly "firstname_en": string
  readonly "lastname_en": string
  readonly "personal_email": string
  readonly "persdoc_exp_date": null | string
  readonly "persdoc_uid": string
  readonly "gender": string
  readonly "citizenship": string
  readonly "photo_uid": string
  readonly "army_status_doc_uid": null | string
  readonly "legal_address": string
  readonly "actual_address": string
  readonly "mobile_phone1": string
  readonly "mobile_phone2": null | string
  readonly "home_phone": null | string
  readonly "high_school": string
  readonly "high_school_doc_type": string
  readonly "high_school_finish_date": null | string
  readonly "high_school_doc_num": string
  readonly "high_school_doc_date": null | string
  readonly "high_school_doc_uid": string
  readonly "national_exam_id": string
  readonly "exam_pass_doc_uid": string
  readonly "lecturer_certificate_uid": null | string
  readonly "has_limited_abilities": string
  readonly "limited_abilities_text": null | string
}

export const LecturerRegistrationStep1Decoder: decoders.Decoder<LecturerRegistrationStep1> = (
  decoders.object({
    attributes: decoders.object({
      "pers_num": decoders.string,
      "birth_date": decoders.nullable(decoders.string),
      "firstname": decoders.string,
      "lastname": decoders.string,
      "firstname_en": decoders.string,
      "lastname_en": decoders.string,
      "personal_email": decoders.string,
      "persdoc_exp_date": decoders.nullable(decoders.string),
      "persdoc_uid": decoders.string,
      "gender": decoders.string,
      "citizenship": decoders.string,
      "photo_uid": decoders.string,
      "army_status_doc_uid": decoders.nullable(decoders.string),
      "legal_address": decoders.string,
      "actual_address": decoders.string,
      "mobile_phone1": decoders.string,
      "mobile_phone2": decoders.nullable(decoders.string),
      "home_phone": decoders.nullable(decoders.string),
      "high_school": decoders.string,
      "high_school_doc_type": decoders.string,
      "high_school_finish_date": decoders.nullable(decoders.string),
      "high_school_doc_num": decoders.string,
      "high_school_doc_date": decoders.nullable(decoders.string),
      "high_school_doc_uid": decoders.string,
      "national_exam_id": decoders.string,
      "exam_pass_doc_uid": decoders.string,
      "lecturer_certificate_uid": decoders.nullable(decoders.string),
      "has_limited_abilities": decoders.string,
      "limited_abilities_text": decoders.nullable(decoders.string),
    }),
  })
  .transform(obj => {
    return {
      "pers_num": obj.attributes["pers_num"],
      "birth_date": obj.attributes["birth_date"],
      "firstname": obj.attributes["firstname"],
      "lastname": obj.attributes["lastname"],
      "firstname_en": obj.attributes["firstname_en"],
      "lastname_en": obj.attributes["lastname_en"],
      "personal_email": obj.attributes["personal_email"],
      "persdoc_exp_date": obj.attributes["persdoc_exp_date"],
      "persdoc_uid": obj.attributes["persdoc_uid"],
      "gender": obj.attributes["gender"],
      "citizenship": obj.attributes["citizenship"],
      "photo_uid": obj.attributes["photo_uid"],
      "army_status_doc_uid": obj.attributes["army_status_doc_uid"],
      "legal_address": obj.attributes["legal_address"],
      "actual_address": obj.attributes["actual_address"],
      "mobile_phone1": obj.attributes["mobile_phone1"],
      "mobile_phone2": obj.attributes["mobile_phone2"],
      "home_phone": obj.attributes["home_phone"],
      "high_school": obj.attributes["high_school"],
      "high_school_doc_type": obj.attributes["high_school_doc_type"],
      "high_school_finish_date": obj.attributes["high_school_finish_date"],
      "high_school_doc_num": obj.attributes["high_school_doc_num"],
      "high_school_doc_date": obj.attributes["high_school_doc_date"],
      "high_school_doc_uid": obj.attributes["high_school_doc_uid"],
      "national_exam_id": obj.attributes["national_exam_id"],
      "exam_pass_doc_uid": obj.attributes["exam_pass_doc_uid"],
      "lecturer_certificate_uid": obj.attributes["lecturer_certificate_uid"],
      "has_limited_abilities": obj.attributes["has_limited_abilities"],
      "limited_abilities_text": obj.attributes["limited_abilities_text"],
    }
  })
)

export interface LecturerRegistrationStep2 {
  readonly "cv_uid": string
  readonly "exam_course": string
}

export const LecturerRegistrationStep2Decoder: decoders.Decoder<LecturerRegistrationStep2> = (
  decoders.object({
    attributes: decoders.object({
      "cv_uid": decoders.string,
      "exam_course": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "cv_uid": obj.attributes["cv_uid"],
      "exam_course": obj.attributes["exam_course"],
    }
  })
)

export interface ExamCourse {
  readonly "id": IDString
  readonly "name": string
  readonly "program": Program
}

export const ExamCourseDecoder: decoders.Decoder<ExamCourse> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
    }),
    relationships: decoders.object({
      "program": decoders.object({
        data: ProgramDecoder
      }),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
      "program": obj.relationships["program"].data,
    }
  })
)

export interface UpdateResumeWorkRequest {
  readonly "startDate": string
  readonly "endDate": null | string
  readonly "isCurrent": boolean
  readonly "position": string
  readonly "commitment": string
  readonly "companyName": string
  readonly "companyAddress": string
  readonly "companySector": string
}

export const UpdateResumeWorkRequestDecoder: decoders.Decoder<UpdateResumeWorkRequest> = (
  decoders.object({
    attributes: decoders.object({
      "startDate": decoders.string,
      "endDate": decoders.nullable(decoders.string),
      "isCurrent": decoders.boolean,
      "position": decoders.string,
      "commitment": decoders.string,
      "companyName": decoders.string,
      "companyAddress": decoders.string,
      "companySector": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "startDate": obj.attributes["startDate"],
      "endDate": obj.attributes["endDate"],
      "isCurrent": obj.attributes["isCurrent"],
      "position": obj.attributes["position"],
      "commitment": obj.attributes["commitment"],
      "companyName": obj.attributes["companyName"],
      "companyAddress": obj.attributes["companyAddress"],
      "companySector": obj.attributes["companySector"],
    }
  })
)

export interface ResumeTrainingScheduleRequest {
  readonly "startHour": string
  readonly "endHour": string
}

export const ResumeTrainingScheduleRequestDecoder: decoders.Decoder<ResumeTrainingScheduleRequest> = decoders.object({
  "startHour": decoders.string,
  "endHour": decoders.string,
})

export interface ResumeDocumentRequest {
  readonly "type": null | EnumValue
  readonly "fileUid": null | string
}

export const ResumeDocumentRequestDecoder: decoders.Decoder<ResumeDocumentRequest> = (
  decoders.object({
    "type": decoders.nullable(EnumValueDecoder),
    attributes: decoders.object({
      "fileUid": decoders.nullable(decoders.string),
    }),
  })
  .transform(obj => {
    return {
      "type": obj["type"],
      "fileUid": obj.attributes["fileUid"],
    }
  })
)

export interface UpdateResumeTrainingRequest {
  readonly "startDate": string
  readonly "endDate": null | string
  readonly "isCurrent": boolean
  readonly "name": string
  readonly "companyName": string
  readonly "skills": string
  readonly "countryId": IDString
  readonly "cityId": IDString
  readonly "document"?: ResumeDocumentRequest
  readonly "schedule"?: readonly ResumeTrainingScheduleRequest[]
}

export const UpdateResumeTrainingRequestDecoder: decoders.Decoder<UpdateResumeTrainingRequest> = decoders.object({
  "startDate": decoders.string,
  "endDate": decoders.nullable(decoders.string),
  "isCurrent": decoders.boolean,
  "name": decoders.string,
  "companyName": decoders.string,
  "skills": decoders.string,
  "countryId": IDStringDecoder,
  "cityId": IDStringDecoder,
  "document": optionalNullableDecoder(ResumeDocumentRequestDecoder),
  "schedule": optionalNullableDecoder(ArrayObjectDecoder(ResumeTrainingScheduleRequestDecoder)),
})

export interface UpdateResumeSkillLanguageRequest {
  readonly "name": null | string
  readonly "hasCertificate": boolean
  readonly "isNative": boolean
  readonly "understandingLevel": null | EnumValue
  readonly "speakingLevel": null | EnumValue
  readonly "readingLevel": null | EnumValue
}

export const UpdateResumeSkillLanguageRequestDecoder: decoders.Decoder<UpdateResumeSkillLanguageRequest> = decoders.object({
  "name": decoders.nullable(decoders.string),
  "hasCertificate": decoders.boolean,
  "isNative": decoders.boolean,
  "understandingLevel": decoders.nullable(EnumValueDecoder),
  "speakingLevel": decoders.nullable(EnumValueDecoder),
  "readingLevel": decoders.nullable(EnumValueDecoder),
})

export interface UpdateResumeSkillCommunicationRequest {
  readonly "value": null | string
}

export const UpdateResumeSkillCommunicationRequestDecoder: decoders.Decoder<UpdateResumeSkillCommunicationRequest> = decoders.object({
  "value": decoders.nullable(decoders.string),
})

export interface UpdateResumeSkillComputerRequest {
  readonly "value": null | string
}

export const UpdateResumeSkillComputerRequestDecoder: decoders.Decoder<UpdateResumeSkillComputerRequest> = decoders.object({
  "value": decoders.nullable(decoders.string),
})

export interface UpdateResumeSkillOrganisationalRequest {
  readonly "value": null | string
}

export const UpdateResumeSkillOrganisationalRequestDecoder: decoders.Decoder<UpdateResumeSkillOrganisationalRequest> = decoders.object({
  "value": decoders.nullable(decoders.string),
})

export interface UpdateResumeSkillOtherRequest {
  readonly "value": null | string
}

export const UpdateResumeSkillOtherRequestDecoder: decoders.Decoder<UpdateResumeSkillOtherRequest> = decoders.object({
  "value": decoders.nullable(decoders.string),
})

export interface UpdateResumeEducationRequest {
  readonly "startDate": string
  readonly "endDate": null | string
  readonly "isCurrent": boolean
  readonly "countryId": IDString
  readonly "cityId": IDString
  readonly "universityId": IDString
  readonly "qualification": string
  readonly "document"?: ResumeDocumentRequest
}

export const UpdateResumeEducationRequestDecoder: decoders.Decoder<UpdateResumeEducationRequest> = (
  decoders.object({
    "startDate": decoders.string,
    "endDate": decoders.nullable(decoders.string),
    "isCurrent": decoders.boolean,
    "countryId": IDStringDecoder,
    "cityId": IDStringDecoder,
    "universityId": IDStringDecoder,
    "qualification": decoders.string,
    relationships: optionalNullableDecoder(decoders.object({
      "document": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ResumeDocumentRequestDecoder)
      })),
    })),
  })
  .transform(obj => {
    return {
      "startDate": obj["startDate"],
      "endDate": obj["endDate"],
      "isCurrent": obj["isCurrent"],
      "countryId": obj["countryId"],
      "cityId": obj["cityId"],
      "universityId": obj["universityId"],
      "qualification": obj["qualification"],
      "document": obj?.relationships?.["document"]?.data,
    }
  })
)

export interface UpdateResumeAdditionalPublicationRequest {
  readonly "name": string
  readonly "publisherName": string
  readonly "type": string
  readonly "date": string
  readonly "url": string
}

export const UpdateResumeAdditionalPublicationRequestDecoder: decoders.Decoder<UpdateResumeAdditionalPublicationRequest> = decoders.object({
  "name": decoders.string,
  "publisherName": decoders.string,
  "type": decoders.string,
  "date": decoders.string,
  "url": decoders.string,
})

export interface UpdateResumeAdditionalFellowshipRequest {
  readonly "type": string
  readonly "organisationName": string
  readonly "startDate": string
  readonly "endDate": string
  readonly "cofinansist": string
}

export const UpdateResumeAdditionalFellowshipRequestDecoder: decoders.Decoder<UpdateResumeAdditionalFellowshipRequest> = decoders.object({
  "type": decoders.string,
  "organisationName": decoders.string,
  "startDate": decoders.string,
  "endDate": decoders.string,
  "cofinansist": decoders.string,
})

export interface UpdateResumeAdditionalSubjectRequest {
  readonly "course": string
}

export const UpdateResumeAdditionalSubjectRequestDecoder: decoders.Decoder<UpdateResumeAdditionalSubjectRequest> = decoders.object({
  "course": decoders.string,
})

export interface UpdateResumeAdditionalVideoLectureRequest {
  readonly "name": string
  readonly "url": string
}

export const UpdateResumeAdditionalVideoLectureRequestDecoder: decoders.Decoder<UpdateResumeAdditionalVideoLectureRequest> = decoders.object({
  "name": decoders.string,
  "url": decoders.string,
})

export interface ChoiceType {
  readonly "credits": number
  readonly "type": ChoiceTypeEnum
}

export const ChoiceTypeDecoder: decoders.Decoder<ChoiceType> = (
  decoders.object({
    attributes: decoders.object({
      "credits": decoders.number,
      "type": ChoiceTypeEnumDecoder,
    }),
  })
  .transform(obj => {
    return {
      "credits": obj.attributes["credits"],
      "type": obj.attributes["type"],
    }
  })
)

export interface AvailableChoiceSummary {
  readonly "creditsAll": number
  readonly "creditsCurrent": number
  readonly "creditsGeneral": number
  readonly "choices": readonly Choice[]
  readonly "choiceTypes"?: readonly ChoiceType[]
}

export const AvailableChoiceSummaryDecoder: decoders.Decoder<AvailableChoiceSummary> = (
  decoders.object({
    attributes: decoders.object({
      "creditsAll": decoders.number,
      "creditsCurrent": decoders.number,
      "creditsGeneral": decoders.number,
    }),
    relationships: decoders.object({
      "choices": decoders.object({
        data: ArrayObjectDecoder(ChoiceDecoder)
      }),
      "choiceTypes": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(ChoiceTypeDecoder))
      })),
    }),
  })
  .transform(obj => {
    return {
      "creditsAll": obj.attributes["creditsAll"],
      "creditsCurrent": obj.attributes["creditsCurrent"],
      "creditsGeneral": obj.attributes["creditsGeneral"],
      "choices": obj.relationships["choices"].data,
      "choiceTypes": obj?.relationships?.["choiceTypes"]?.data,
    }
  })
)

export interface AvailableCourseTab {
  readonly "title": string
  readonly "programId": null | IDString
  readonly "tooltip": string
  readonly "type": AvailableCourseTabType
}

export const AvailableCourseTabDecoder: decoders.Decoder<AvailableCourseTab> = (
  decoders.object({
    attributes: decoders.object({
      "title": decoders.string,
      "programId": decoders.nullable(IDStringDecoder),
      "tooltip": decoders.string,
      "type": AvailableCourseTabTypeDecoder,
    }),
  })
  .transform(obj => {
    return {
      "title": obj.attributes["title"],
      "programId": obj.attributes["programId"],
      "tooltip": obj.attributes["tooltip"],
      "type": obj.attributes["type"],
    }
  })
)

export interface AvailableCourseSummary {
  readonly "courses": readonly Course[]
  readonly "choiceTypes"?: readonly ChoiceType[]
}

export const AvailableCourseSummaryDecoder: decoders.Decoder<AvailableCourseSummary> = (
  decoders.object({
    relationships: decoders.object({
      "courses": decoders.object({
        data: ArrayObjectDecoder(CourseDecoder)
      }),
      "choiceTypes": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(ChoiceTypeDecoder))
      })),
    }),
  })
  .transform(obj => {
    return {
      "courses": obj.relationships["courses"].data,
      "choiceTypes": obj?.relationships?.["choiceTypes"]?.data,
    }
  })
)

export interface MetaData {
  readonly "environment": string
  readonly "revision": string
  readonly "updatedAt": string
}

export const MetaDataDecoder: decoders.Decoder<MetaData> = decoders.object({
  "environment": decoders.string,
  "revision": decoders.string,
  "updatedAt": decoders.string,
})

export interface Country {
  readonly "id": IDString
  readonly "name": string
}

export const CountryDecoder: decoders.Decoder<Country> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
    }
  })
)

export interface Address {
  readonly "id": null | IDString
  readonly "result": string
}

export const AddressDecoder: decoders.Decoder<Address> = (
  decoders.object({
    "id": decoders.nullable(IDStringDecoder),
    attributes: decoders.object({
      "result": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "result": obj.attributes["result"],
    }
  })
)

export interface FeedbackSubject {
  readonly "id": IDString
  readonly "name": string
}

export const FeedbackSubjectDecoder: decoders.Decoder<FeedbackSubject> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "name": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "name": obj.attributes["name"],
    }
  })
)

export interface UserAnswerCriteria {
  readonly "criteriaId": IDString
  readonly "value"?: null | string | number
}

export const UserAnswerCriteriaDecoder: decoders.Decoder<UserAnswerCriteria> = decoders.object({
  "criteriaId": IDStringDecoder,
  "value": optionalNullableDecoder(decoders.nullable(decoders.either(
    decoders.string,
    decoders.number
  ))),
})

export interface UserAnswerOption {
  readonly "optionId": IDString
  readonly "value"?: null | string
  readonly "criterias"?: readonly UserAnswerCriteria[]
}

export const UserAnswerOptionDecoder: decoders.Decoder<UserAnswerOption> = decoders.object({
  "optionId": IDStringDecoder,
  "value": optionalNullableDecoder(decoders.nullable(decoders.string)),
  "criterias": optionalNullableDecoder(ArrayObjectDecoder(UserAnswerCriteriaDecoder)),
})

export interface UserAnswer {
  readonly "answer": readonly UserAnswerOption[]
}

export const UserAnswerDecoder: decoders.Decoder<UserAnswer> = (
  decoders.object({
    attributes: decoders.object({
      "answer": ArrayObjectDecoder(UserAnswerOptionDecoder),
    }),
  })
  .transform(obj => {
    return {
      "answer": obj.attributes["answer"],
    }
  })
)

export type SurveyQuestionType =
  | "TEXT"
  | "CHECKBOXES"
  | "CHECKBOXES_OTHER"
  | "RADIOS"
  | "RADIOS_OTHER"
  | "MULTI_CHECKBOXES"
  | "MULTI_RADIOS"
  | "COURSES_CHECKBOXES"
  | "COURSES_RADIOS"
  | "COURSES_TEXTS"
  | "COURSES_DROPDOWNS"

export const SurveyQuestionTypeDecoder: decoders.Decoder<SurveyQuestionType> = (
  decoders.either(decoders.number, decoders.string)
  .transform(key => {
    switch (key) {
      case 1: return "TEXT"
      case 2: return "CHECKBOXES"
      case 3: return "CHECKBOXES_OTHER"
      case 4: return "RADIOS"
      case 5: return "RADIOS_OTHER"
      case 6: return "MULTI_CHECKBOXES"
      case 7: return "MULTI_RADIOS"
      case 11: return "COURSES_CHECKBOXES"
      case 12: return "COURSES_RADIOS"
      case 13: return "COURSES_TEXTS"
      case 14: return "COURSES_DROPDOWNS"
    }

    throw new Error(`Could not decode SurveyQuestionType from value: ${key}`)
  })
)

export function SurveyQuestionTypeEncoder (label: SurveyQuestionType): string | number {
  switch (label) {
    case "TEXT": return 1
    case "CHECKBOXES": return 2
    case "CHECKBOXES_OTHER": return 3
    case "RADIOS": return 4
    case "RADIOS_OTHER": return 5
    case "MULTI_CHECKBOXES": return 6
    case "MULTI_RADIOS": return 7
    case "COURSES_CHECKBOXES": return 11
    case "COURSES_RADIOS": return 12
    case "COURSES_TEXTS": return 13
    case "COURSES_DROPDOWNS": return 14
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Could not decode __UNNAMED_TYPE__ from value: ${label}`)
  }
}

export const SurveyQuestionTypeValues = [
  "TEXT",
  "CHECKBOXES",
  "CHECKBOXES_OTHER",
  "RADIOS",
  "RADIOS_OTHER",
  "MULTI_CHECKBOXES",
  "MULTI_RADIOS",
  "COURSES_CHECKBOXES",
  "COURSES_RADIOS",
  "COURSES_TEXTS",
  "COURSES_DROPDOWNS",
] as const

export interface SurveyProgress {
  readonly "questionsAnswered": number
  readonly "progress": number
}

export const SurveyProgressDecoder: decoders.Decoder<SurveyProgress> = (
  decoders.object({
    attributes: decoders.object({
      "questionsAnswered": decoders.number,
      "progress": decoders.number,
    }),
  })
  .transform(obj => {
    return {
      "questionsAnswered": obj.attributes["questionsAnswered"],
      "progress": obj.attributes["progress"],
    }
  })
)

export interface Survey {
  readonly "id": IDString
  readonly "title": string
  readonly "text": string
  readonly "finishText": string
  readonly "perPage": number
  readonly "intensity": boolean
  readonly "intensityNum": number
  readonly "questionsCount": number
  readonly "SurveyProgress": SurveyProgress
}

export const SurveyDecoder: decoders.Decoder<Survey> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "title": decoders.string,
      "text": decoders.string,
      "finishText": decoders.string,
      "perPage": decoders.number,
      "intensity": decoders.boolean,
      "intensityNum": decoders.number,
      "questionsCount": decoders.number,
    }),
    relationships: decoders.object({
      "SurveyProgress": decoders.object({
        data: SurveyProgressDecoder
      }),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "title": obj.attributes["title"],
      "text": obj.attributes["text"],
      "finishText": obj.attributes["finishText"],
      "perPage": obj.attributes["perPage"],
      "intensity": obj.attributes["intensity"],
      "intensityNum": obj.attributes["intensityNum"],
      "questionsCount": obj.attributes["questionsCount"],
      "SurveyProgress": obj.relationships["SurveyProgress"].data,
    }
  })
)

export interface SurveyQuestionOption {
  readonly "id": null | IDString
  readonly "type": string
  readonly "title": string
  readonly "customAllowed": boolean
  readonly "bcolor": null | string
  readonly "fcolor": null | string
}

export const SurveyQuestionOptionDecoder: decoders.Decoder<SurveyQuestionOption> = (
  decoders.object({
    "id": decoders.nullable(IDStringDecoder),
    "type": decoders.string,
    attributes: decoders.object({
      "title": decoders.string,
      "customAllowed": decoders.boolean,
      "bcolor": decoders.nullable(decoders.string),
      "fcolor": decoders.nullable(decoders.string),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "type": obj["type"],
      "title": obj.attributes["title"],
      "customAllowed": obj.attributes["customAllowed"],
      "bcolor": obj.attributes["bcolor"],
      "fcolor": obj.attributes["fcolor"],
    }
  })
)

export interface SurveyQuestionCriteria {
  readonly "id": IDString
  readonly "title": string
}

export const SurveyQuestionCriteriaDecoder: decoders.Decoder<SurveyQuestionCriteria> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "title": decoders.string,
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "title": obj.attributes["title"],
    }
  })
)

export interface SurveyQuestion {
  readonly "id": IDString
  readonly "type": SurveyQuestionType
  readonly "bcolor": null | string
  readonly "fcolor": null | string
  readonly "title": string
  readonly "required": boolean
  readonly "options": readonly SurveyQuestionOption[]
  readonly "criterias"?: readonly SurveyQuestionCriteria[]
  readonly "userAnswer"?: UserAnswer
}

export const SurveyQuestionDecoder: decoders.Decoder<SurveyQuestion> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "type": SurveyQuestionTypeDecoder,
      "bcolor": decoders.nullable(decoders.string),
      "fcolor": decoders.nullable(decoders.string),
      "title": decoders.string,
      "required": decoders.boolean,
    }),
    relationships: decoders.object({
      "options": decoders.object({
        data: ArrayObjectDecoder(SurveyQuestionOptionDecoder)
      }),
      "criterias": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(SurveyQuestionCriteriaDecoder))
      })),
      "userAnswer": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(UserAnswerDecoder)
      })),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "type": obj.attributes["type"],
      "bcolor": obj.attributes["bcolor"],
      "fcolor": obj.attributes["fcolor"],
      "title": obj.attributes["title"],
      "required": obj.attributes["required"],
      "options": obj.relationships["options"].data,
      "criterias": obj?.relationships?.["criterias"]?.data,
      "userAnswer": obj?.relationships?.["userAnswer"]?.data,
    }
  })
)

export interface QuestionAnswerRequest {
  readonly "questionId": number
  readonly "answers": null | readonly UserAnswerOption[]
}

export const QuestionAnswerRequestDecoder: decoders.Decoder<QuestionAnswerRequest> = decoders.object({
  "questionId": decoders.number,
  "answers": decoders.nullable(ArrayObjectDecoder(UserAnswerOptionDecoder)),
})

export interface Feedback {
  readonly "id": IDString
  readonly "text": string
  readonly "number": string
  readonly "status": number
  readonly "date": string
  readonly "subjectId": number
  readonly "comment": string
  readonly "commentDate": null | string
  readonly "subject": FeedbackSubject
  readonly "mediaFiles"?: readonly MediaFile[]
}

export const FeedbackDecoder: decoders.Decoder<Feedback> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "text": decoders.string,
      "number": decoders.string,
      "status": decoders.number,
      "date": decoders.string,
      "subjectId": decoders.number,
      "comment": decoders.string,
      "commentDate": decoders.nullable(decoders.string),
    }),
    relationships: decoders.object({
      "subject": decoders.object({
        data: FeedbackSubjectDecoder
      }),
      "mediaFiles": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ArrayObjectDecoder(MediaFileDecoder))
      })),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "text": obj.attributes["text"],
      "number": obj.attributes["number"],
      "status": obj.attributes["status"],
      "date": obj.attributes["date"],
      "subjectId": obj.attributes["subjectId"],
      "comment": obj.attributes["comment"],
      "commentDate": obj.attributes["commentDate"],
      "subject": obj.relationships["subject"].data,
      "mediaFiles": obj?.relationships?.["mediaFiles"]?.data,
    }
  })
)

export interface Registration {
  readonly "id": IDString
  readonly "firstname": string
  readonly "lastname": string
  readonly "mail": string
  readonly "regProgs": readonly RegProg[]
  readonly "testType"?: TestType
  readonly "examCourse"?: ExamCourse
}

export const RegistrationDecoder: decoders.Decoder<Registration> = (
  decoders.object({
    "id": IDStringDecoder,
    attributes: decoders.object({
      "firstname": decoders.string,
      "lastname": decoders.string,
      "mail": decoders.string,
    }),
    relationships: decoders.object({
      "regProgs": decoders.object({
        data: ArrayObjectDecoder(RegProgDecoder)
      }),
      "testType": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(TestTypeDecoder)
      })),
      "examCourse": optionalNullableDecoder(decoders.object({
        data: optionalNullableDecoder(ExamCourseDecoder)
      })),
    }),
  })
  .transform(obj => {
    return {
      "id": obj["id"],
      "firstname": obj.attributes["firstname"],
      "lastname": obj.attributes["lastname"],
      "mail": obj.attributes["mail"],
      "regProgs": obj.relationships["regProgs"].data,
      "testType": obj?.relationships?.["testType"]?.data,
      "examCourse": obj?.relationships?.["examCourse"]?.data,
    }
  })
)
