import type * as Types from 'src/api/types'
import { observableValue, useObservableValue } from 'src/helpers/observable'

export interface UndeterminedAuthState {
  readonly state: 'Undetermined'
  readonly user?: undefined
  readonly profile?: undefined
}
export interface UnauthenticatedAuthState {
  readonly state: 'Unauthenticated'
  readonly user?: undefined
  readonly profile?: undefined
}
export interface AuthenticatedAuthState {
  readonly state: 'Authenticated'
  readonly user: Types.AuthUser
  readonly profile: Types.UserProfile
}

export type State = UndeterminedAuthState | UnauthenticatedAuthState | AuthenticatedAuthState

export const authObservableValue = observableValue<State>({
  state: 'Undetermined',
})

export function useAuth(): State {
  return useObservableValue(authObservableValue)
}
