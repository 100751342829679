import React from 'react'
import * as Api from 'src/api'
import { useLocale } from '../locale/locale'
import { useAuth } from './auth'
import { refreshTokenStorage } from './refreshTokenStorage'

export interface AuthenticatedHeaders {
  readonly 'Accept-Language': Api.LanguageString
  readonly Authorization: string
  readonly 'Profile-ID': string
}

export function useAuthenticatedHeaders(): AuthenticatedHeaders {
  const locale = useLocale()
  const auth = useAuth()

  return React.useMemo(() => {
    return {
      get 'Accept-Language'() {
        return locale
      },
      get Authorization() {
        return Api.getAuthorization(refreshTokenStorage.getValue()!.authObject)
      },
      get 'Profile-ID'() {
        return refreshTokenStorage.getValue()!.profileId
      },
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, auth.state, auth.profile?.id, auth.user?.id])
}

export interface AnyHeaders {
  readonly 'Accept-Language': Api.LanguageString
  readonly Authorization?: string
  readonly 'Profile-ID'?: string
}

export function useAnyHeaders(): AnyHeaders {
  const locale = useLocale()
  const auth = useAuth()

  return React.useMemo(() => {
    return {
      get 'Accept-Language'() {
        return locale
      },
      get Authorization() {
        const state = refreshTokenStorage.getValue()

        if (state != null) {
          return Api.getAuthorization(state.authObject)
        }
      },
      get 'Profile-ID'() {
        return refreshTokenStorage.getValue()?.profileId
      },
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, auth.state, auth.profile?.id, auth.user?.id])
}
