import * as ProSolidSvgIcons from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import * as Api from 'src/api'
import { EnvelopeIcon } from 'src/assets/icons/customIcons/Envelope'
import { queryToString } from 'src/helpers/fns'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { useUserStateMutateOnMount } from 'src/hooks/userState'
import Warning from 'src/imgs/classroom_icon.svg'
import { Button } from 'src/tailwind/components/Button'
import * as Table from 'src/tailwind/components/Table'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import Pagination from 'src/views/components/Pagination'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import TextInput from 'src/views/components/forms/formik/TextInput'
import Layout from './Layout'

interface FormikValues {
  readonly query: string
}
const initialValues: FormikValues = { query: '' }

export default function AdministratorUsersAllPage(): JSX.Element | null {
  const t = useTranslatable()
  useUserStateMutateOnMount()

  return (
    <>
      <Helmet title={t('system:users')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const [searchParams] = useSearchParams()
  const searchQuery = searchParams.get('query')
  const page = searchParams.get('page')
  const headers = useAuthenticatedHeaders()
  const perPage = searchParams.get('perPage')

  const fetching = useApi({
    endpoint: Api.getAdministratorUsers,
    params: React.useMemo(
      () => ({
        headers,
        query: {
          filters: {
            search: searchQuery! ?? null,
          },
          page: page ?? '1',
          perPage: perPage ?? '10',
        },
      }),
      [headers, page, searchQuery, perPage]
    ),
  })

  const users = fetching.data
  const locale = useLocale()
  const t = useTranslatable()

  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <Layout>
      <Form
        initialValues={initialValues}
        onSubmit={(values: FormikValues) => {
          navigate(
            `${location.pathname}${queryToString({
              searchQuery,
              query: values.query.length > 0 ? values.query : null,
            })}`
          )
        }}
        classNames={{
          form: 'my-3',
        }}
      >
        <fieldset disabled={fetching.isValidating} className="mb-2 flex flex-wrap justify-between md:!flex-nowrap">
          <FormError />
          <div className="w-full">
            {/* <TextInput type="text" name="query" placeholder={t('common:enter_your_keyword')} searchField /> */}
            <TextInput type="text" name="query" placeholder={t('statement:search_for_statement_number')} searchField />
          </div>
          <Button variant="red" className="ml-0 w-[120px] md:ml-4" type="submit">
            {t('common:search')}
          </Button>
        </fieldset>
      </Form>
      {(() => {
        if (users?.data != null && users.data.length === 0) {
          return <NoContent header={t('error:records_not_found')} image={Warning} marginTop="5" />
        }
        if (users?.meta == null || params == null) return null
        return (
          <div className="w-full py-3">
            <Pagination pagination={users.meta.pagination} />
            <Table.Table className="w-full">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>ID</Table.Th>
                  <Table.Th>
                    {t('person:firstname')}/{t('person:lastname')}
                  </Table.Th>
                  <Table.Th>{t('person:personal_number')}</Table.Th>
                  <Table.Th />
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {users.data.map((user) => (
                  <Table.Tr key={user.id}>
                    <Table.Td className="rounded-l-lg">{user.id}</Table.Td>
                    <Table.Td className="align-middle">
                      <div className="flex items-center">
                        {user.photoUrl != null ? (
                          <img src={user.photoUrl} alt="messageUserAvatar" width="30px" />
                        ) : (
                          <div dangerouslySetInnerHTML={{ __html: user.avatar }} />
                        )}
                        <span className="ml-2">{user.fullName}</span>
                      </div>
                    </Table.Td>
                    <Table.Td className="rounded-l-lg">{user.personalNumber}</Table.Td>
                    <Table.Td>
                      <div className="flex items-center">
                        {Boolean(user.manage?.canEmulate) && (
                          <FontAwesomeIcon icon={ProSolidSvgIcons.faUser} className="mr-3 cursor-pointer" />
                        )}
                        <Link
                          to={`/${locale}/messages/compose/${user.uid}`}
                          className="flex rounded-[18px] p-[10px] text-xs"
                        >
                          <span className="w-[18px]">
                            <EnvelopeIcon />
                          </span>
                        </Link>
                      </div>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table.Table>
            <Pagination pagination={users.meta.pagination} />
          </div>
        )
      })()}
    </Layout>
  )
}
