export function ArrowNext(): JSX.Element {
  return (
    <svg
      id="arrow-next"
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#707070"
      strokeWidth="1"
      opacity="1"
    >
      <path d="M1.27588 8.84082L5.38688 4.84082L1.27588 0.84082" />
    </svg>
  )
}
