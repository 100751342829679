/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from 'react'
import { observableValue, type ObservableValue } from './observable'

export type DeletedStorageValue = null | undefined

export type StorageValue<T> = ObservableValue<T | DeletedStorageValue>

export function storageValue<T>(
  key: string,
  serialize: (arg0: T) => string,
  deserialize: (arg0: string) => T,
  isEqual?: (update: T, current: T) => boolean
): StorageValue<T> {
  const observable: ObservableValue<T | DeletedStorageValue> = observableValue(
    __deserialize(localStorage.getItem(key)),
    __isEqual
  )

  observable.eventEmitter.addListener('update', (deserializedValue) => {
    const serializedValue = __serialize(deserializedValue)

    if (serializedValue == null) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, serializedValue)
    }
  })

  function __deserialize(serializedValue: string | null): DeletedStorageValue | T {
    if (serializedValue == null) return null

    try {
      return deserialize(serializedValue)
    } catch (err) {
      console.error(err)

      return null
    }
  }
  function __serialize(deserializedValue: T | DeletedStorageValue): null | string {
    if (deserializedValue == null) return null

    try {
      return serialize(deserializedValue)
    } catch (err) {
      console.error(err)
      return null
    }
  }

  function __isEqual(a: T | DeletedStorageValue, b: T | DeletedStorageValue): boolean {
    if (a === b) return true
    if (a == null && b != null) return false
    if (a == null && b == null) return true
    if (a != null && b == null) return false

    if (isEqual == null) return false

    return isEqual(a!, b!)
  }

  window.addEventListener('storage', (e) => {
    if (e.key === key) {
      observable.setValue(__deserialize(e.newValue))
    }
  })

  return observable
}

export function useStorageValue<T>(storageValueObject: StorageValue<T>): T | DeletedStorageValue {
  const [state, setState] = useState<T | DeletedStorageValue>(storageValueObject.getValue())

  useEffect(() => {
    storageValueObject.eventEmitter.addListener('update', setState)

    return () => {
      storageValueObject.eventEmitter.removeListener('update', setState)
    }
  }, [storageValueObject])

  return state
}
