import classnames from 'classnames'
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import type * as Api from 'src/api'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Section } from 'src/tailwind/components/Section'

interface Props {
  readonly children?: React.ReactNode
  readonly filter?: React.ReactNode
}

interface Page {
  readonly link: string
  readonly title: Api.Translatable | [ka: string, en: string]
  readonly end?: boolean
}

interface Options {
  readonly Icon: (props: { [key: string]: unknown }) => React.ReactElement
  readonly title: Api.Translatable | [ka: string, en: string]
  readonly pages: Page[]
}

export default function SubPageLayoutFactory(options: Options) {
  return function SubPageLayout({ children, filter }: Props): React.ReactElement | null {
    const t = useTranslatable()
    const locale = useLocale()

    const breadcrumbsItems = [{ page: `${t(options.title)}`, path: `/${locale}/student/resume` }]

    return (
      <Section icon={<options.Icon />} title={t(options.title)} breadcrubms={breadcrumbsItems}>
        {filter}
        <div className="flex flex-col items-start">
          <ul className="mb-4 flex w-full items-center overflow-x-scroll dark:text-white">
            {options.pages.map((page, index) => {
              return (
                <li
                  className="text-center"
                  style={{ width: `${100 / options.pages.length}%`, minWidth: '172px' }}
                  key={index}
                >
                  <NavLink
                    className={({ isActive }) =>
                      classnames('w-full', {
                        disabled: isActive,
                        active: isActive,
                      })
                    }
                    style={{ lineBreak: 'auto' }}
                    to={`/${locale}/${page.link}`}
                    end={page.end ?? true}
                  >
                    {({ isActive }) => (
                      <div className="flex h-[60px] w-full flex-col justify-between">
                        <div className="relative h-[30px]">
                          <span
                            className={`absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 font-semibold text-primaryTextColor ${
                              isActive ? 'opacity-100' : 'opacity-50'
                            }`}
                          >
                            {t(page.title)}
                          </span>
                        </div>
                        <div className="h-[2px] w-full" style={{ backgroundColor: isActive ? '#B80433' : '#E6E6E6' }} />
                      </div>
                    )}
                  </NavLink>
                </li>
              )
            })}
          </ul>
          {children}
        </div>
      </Section>
    )
  }
}
