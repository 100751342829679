import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import type * as Api from 'src/api'
import { useApi, type APIResponse } from 'src/helpers/hooks'
import { useTranslatable } from 'src/hooks/locale/utils'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import { LineChart, PieChart } from 'src/views/components/highCharts'
import Loader from '../../../../components/Loader'
import Layout from './Layout'

export default function AdministratorStatisticDashboardPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('statistics:statistics')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

async function getDailyUsers(): Promise<LineChartState> {
  await new Promise((resolve) => setTimeout(resolve, 1000))

  return {
    title: { ka: 'მომხმარებლები დღიურად', en: 'Users Per Day' },
    yAxis: [
      {
        y: 16328,
      },
      {
        y: 16778,
      },
      {
        y: 16660,
      },
      {
        y: 15885,
      },
      {
        y: 15139,
      },
      {
        y: 14290,
      },
      {
        y: 13800,
      },
      {
        y: 14189,
      },
    ],
    categories: [
      { ka: '10 იანვარი', en: '10 January' },
      { ka: '11 იანვარი', en: '11 January' },
      { ka: '12 იანვარი', en: '12 January' },
      { ka: '13 იანვარი', en: '13 January' },
      { ka: '14 იანვარი', en: '14 January' },
      { ka: '15 იანვარი', en: '15 January' },
      { ka: '16 იანვარი', en: '16 January' },
      { ka: '17 იანვარი', en: '17 January' },
    ],
  }
}

async function getChoices(): Promise<PieChartState> {
  await new Promise((resolve) => setTimeout(resolve, 1000))

  return {
    title: { ka: 'არჩვები მიმდინარე სემესტრში', en: 'Choices in Current Semester' },
    total: 721,
    items: [
      {
        category: { ka: 'მოსამზადებელი', en: 'Preparational' },
        count: 1498,
      },
      {
        category: { ka: 'ბაკალავრი', en: 'Bachelor' },
        count: 60702,
      },
      {
        category: { ka: 'მაგისტრანტი', en: 'Master' },
        count: 8932,
      },
    ],
  }
}

async function getAllUsers(): Promise<PieChartState> {
  await new Promise((resolve) => setTimeout(resolve, 1000))

  return {
    title: { ka: 'მომხმარებლები ონლაინ', en: 'All users' },
    total: 446,
    items: [
      {
        category: { ka: 'DESKTOP', en: 'DESKTOP' },
        count: 242,
      },
      {
        category: { ka: 'MOBILE', en: 'MOBILE' },
        count: 203,
      },
      {
        category: { ka: 'TABLET', en: 'TABLET' },
        count: 1,
      },
    ],
  }
}

async function getActiveStudents(): Promise<PieChartState> {
  await new Promise((resolve) => setTimeout(resolve, 1000))

  return {
    title: { ka: 'აქტიური სტუდენტები', en: 'Active Students' },
    total: 1673,
    items: [
      {
        category: { ka: 'მოსამზადებელი', en: 'Preparational' },
        count: 312,
      },
      {
        category: { ka: 'ბაკალავრი', en: 'Bachelor' },
        count: 13452,
      },
      {
        category: { ka: 'მაგისტრანტი', en: 'Master' },
        count: 209,
      },
    ],
  }
}

const params = {}

export interface Item {
  category: Api.Translatable
  count: number
}

export interface PieChartState {
  readonly title: Api.Translatable
  readonly total: number
  readonly items: readonly Item[]
}
export interface LineChartState {
  readonly title: Api.Translatable
  readonly yAxis: ReadonlyArray<{ y: number }>
  readonly categories: readonly Api.Translatable[]
}

function PageContent(): JSX.Element | null {
  const dailyUsers: APIResponse<LineChartState | null, Error> = useApi({
    endpoint: getDailyUsers,
    params,
    suspense: false,
  })

  const choices: APIResponse<PieChartState | null, Error> = useApi({
    endpoint: getChoices,
    params,
    suspense: false,
  })

  const allUsers: APIResponse<PieChartState | null, Error> = useApi({
    endpoint: getAllUsers,
    params,
    suspense: false,
  })

  const activeStudents: APIResponse<PieChartState | null, Error> = useApi({
    endpoint: getActiveStudents,
    params,
    suspense: false,
  })

  return (
    <Layout>
      {dailyUsers.data == null || choices.data == null || allUsers.data == null || activeStudents.data == null ? (
        <Loader className="flex" style={{ margin: 'auto' }} />
      ) : (
        <div className="flex-auto p-5 px-2 pt-0 text-primaryTextColor">
          <React.Suspense fallback={<Loader className="m-auto flex" />}>
            <div className="flex flex-wrap">
              <div className="py-3">
                <PieChart apiResponse={allUsers} />
              </div>
              <div className="py-3">
                <LineChart apiResponse={dailyUsers} />
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="py-3">
                <PieChart apiResponse={choices} />
              </div>
              <div className="py-3">
                <PieChart apiResponse={activeStudents} />
              </div>
            </div>
          </React.Suspense>
        </div>
      )}
    </Layout>
  )
}
