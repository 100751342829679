import { postAuthTokenRefresh } from 'src/api/sdk-l1'
import type * as Types from 'src/api/types'
import { inFlightReUse } from 'src/helpers/fns'
import { getCurrentLocale } from '../locale/localeStorage'
import * as refreshTokenLockStorage from './refreshTokenLockStorage'
import { refreshTokenStorage } from './refreshTokenStorage'

export * from './authObservableValue'

export async function refreshAuthObject(refreshToken: string): Promise<Types.AuthObject> {
  return await inFlightReUse('refreshAuthObject', async () => {
    try {
      console.info('refreshAuthObject', refreshToken)

      const refreshTokenStorageValue1 = refreshTokenStorage.getValue()
      console.info('refreshTokenStorageValue1', refreshTokenStorageValue1)

      if (refreshTokenStorageValue1 != null && refreshTokenStorageValue1.authObject.refreshToken !== refreshToken) {
        console.info('refreshTokenLockStorage.unlock()')
        refreshTokenLockStorage.unlock()

        console.info('refreshTokenStorageValue1.authObject', refreshTokenStorageValue1.authObject)
        return refreshTokenStorageValue1.authObject
      }

      console.info('await refreshTokenLockStorage.ensureLock()')
      await refreshTokenLockStorage.ensureLock()

      const refreshTokenStorageValue2 = refreshTokenStorage.getValue()
      console.info('refreshTokenStorageValue2', refreshTokenStorageValue2)

      if (
        refreshTokenStorageValue2 != null &&
        refreshTokenStorageValue2.authObject.refreshToken !== refreshToken &&
        refreshTokenStorageValue2.authObject.refreshToken !== refreshTokenStorageValue1?.authObject.refreshToken
      ) {
        console.info('refreshTokenLockStorage.unlock()')
        refreshTokenLockStorage.unlock()

        console.info('refreshTokenStorageValue2.authObject', refreshTokenStorageValue2.authObject)
        return refreshTokenStorageValue2.authObject
      }

      console.info(`return await inFlightReUse('postAuthTokenRefresh', async () => {`)

      const authObject = await postAuthTokenRefresh({
        body: {
          clientId: '1',
          grantType: 'internal_refresh_token',
          refreshToken,
        },
        headers: {
          'Accept-Language': getCurrentLocale(),
        },
      })
      console.info(`postAuthTokenRefresh`, authObject)

      const refreshTokenStorageValue = refreshTokenStorage.getValue()

      console.info(`refreshTokenStorageValue`, refreshTokenStorageValue)

      if (refreshTokenStorageValue?.profileId != null) {
        console.info(`refreshTokenStorage.setValue`, {
          profileId: refreshTokenStorageValue.profileId,
          authObject,
        })

        refreshTokenStorage.setValue({
          profileId: refreshTokenStorageValue.profileId,
          authObject,
        })
      } else {
        console.info('TODO-2')
        // TODO
      }

      console.info('refreshTokenLockStorage.unlock()')
      refreshTokenLockStorage.unlock()

      return authObject
    } catch (err) {
      console.error('err', err)
      console.info('refreshTokenLockStorage.unlock()')
      refreshTokenLockStorage.unlock()
      throw err
    }
  })
}
